<template>
	<div id="width-document-containter">
		<div class="relative">
			<div class="flex flex-wrap justify-center pt-6 pl-4">
				<div class="mb-6">
					<ThreeButton 
						:options="['by Vendor', 'by Project']"
						@click="changeActiveButton"
						:value="activeButton"
						class="w-full sm:w-80" />
				</div>
			</div>
			<div v-if="activeButton === 'by Project'" class="flex justify-end flex-auto px-4" :class="`${width > 768? 'absolute top-6 right-6': ''}`">
				<div class="w-full sm:w-40">
					<AutocompleteSolutip
						placeholder="Project ID/Name"
						iconLeft="icon-search text-xl text-gray-light"
						@updateInput="inputSearch($event)"
						@updateAutoComplete="updateAutoComplete($event)"
						@focus="focusInput"
						:options="projectNameIdSearch"
						v-model="search"
						:isLoading="isLoadingSearch"
						textField="projectName">
					>
					</AutocompleteSolutip>
				</div>
			</div>
		</div>
		<DocumentVendor :vendor="vendor" v-if="activeButton === 'by Vendor'"/>
		<div v-if="activeButton === 'by Project'">
			<DocumentProject :vendor="vendor" :projectId="projectId" :isLoadingDocumentProject="isLoadingDocumentProject" @closeModal="closeModal"/>
		</div>


	</div>
</template>
<script>
import ThreeButton from '@/core/components/custom/ThreeButton.vue'
import DocumentVendor from '@/core/components/vendors/DocumentVendor.vue'
import DocumentProject from '@/core/components/vendors/DocumentProject.vue'
import AutocompleteSolutip from '@/core/components/custom/Autocomplete.vue'


export default {
	name: "Document",
	props: {
		vendor: Object,
		projectId: String,
	},
	data() {
		return {
			activeButton: 'by Vendor',
			search: '',
			project: null,
			timeout: null,
			width: '',
			isLoadingDocumentProject: false,
			isLoadingSearch: false,
		}

	},
	computed: {
		projectNameIdSearch() {
			return this.$store.state.vendor.projectNameIdSearch
		},
		vendorDocumentsDetailProject() {
			return this.$store.state.vendor.vendorDocumentsDetailProject
		},
		menuVendorDetailsDocumentByVendor() {
			return this.$store.getters['dashboard/menuVendorDetailsDocumentByVendor']
		},
		menuVendorDetailsDocumentByProject() {
			return this.$store.getters['dashboard/menuVendorDetailsDocumentByProject']
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
	},
	methods: {
		changeActiveButton(e) {
			this.activeButton = e
		},
		async inputSearch(e) {
			this.isLoadingSearch = true
			await this.$store.dispatch('vendor/getProjectNameIDSearch', { vendorId: this.vendor.vendorId, search: e })
			this.isLoadingSearch = false
		},
		async focusInput() {
			this.isLoadingSearch = true
			await this.$store.dispatch('vendor/getProjectNameIDSearch', { vendorId: this.vendor.vendorId, search: this.search })
			this.isLoadingSearch = false
		},
		updateAutoComplete(e) {
			this.project = e || ''
			this.$store.dispatch('vendor/getVendorDocumentsDetailProject', { vendorId: this.vendor.vendorId, projectId: e?.projectId || this.project })
		},
		closeModal() {
			this.$emit('closeModal')
		},
	},
	async mounted() {
		this.width = document.getElementById('width-document-containter')?.offsetWidth
		window.addEventListener(
			'resize',
			() => {
				this.width = document.getElementById('width-document-containter')?.offsetWidth
			}
		)
		if (this.projectId) {
			this.isLoadingDocumentProject = true
			const res = await this.$store.dispatch('vendor/getVendorDocumentsDetailProject', { vendorId: this.vendor.vendorId, projectId: this.projectId })
			if (res?.status === 200) {
				this.isLoadingDocumentProject = false
			}
		} else {
			this.isLoadingDocumentProject = true
			const res = await this.$store.dispatch('vendor/getVendorDocumentsDetailProject', { vendorId: this.vendor.vendorId, projectId: '' })
			if (res?.status === 200) {
				this.isLoadingDocumentProject = false
			}
		}
	},
	destroyed() {
		window.removeEventListener('resize', () => {
				this.width = document.getElementById('width-document-containter').offsetWidth
			}
		)
	},
	components:{
    ThreeButton,
    DocumentVendor,
    DocumentProject,
    AutocompleteSolutip,
}
}
</script>