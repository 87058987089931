<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex justify-between my-6">
        <div>
          <span v-if="!showEditBan" class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"
            @click="clickBackToVendorDetail"></span>
          <span v-else class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"
            @click="clickToggleBackToVendorDetail(true)"></span>
          <ModalConfirm 
            v-if="showEditBan"
            title="Apakah anda yakin?" 
            message="Semua perubahan akan hilang" 
            textButton="Discard" 
            :isOpen="isOpenDiscardChanges" 
            @changeIsOpen="clickToggleBackToVendorDetail($event)" 
            @click="clickBackToVendorDetail"
            colorButton="error">
          </ModalConfirm>
        </div>
        <div>
            <h4 class="text-lg font-medium text-gray-darkest">{{ showEditBan? 'Edit BAN' : 'Detail BAN' }} - {{ banDetails?.negotiationStatus }}</h4>
        </div>
        <div></div>
      </div>
      <div v-if="showEditBan" class="mb-6">
        <div class="w-full mb-1">
          <label class="text-sm text-tertiary">{{negotiationDetails?.isLocalPartner? 'Local Partner' : 'Vendor'}} #{{negotiationDetails?.vendorCandidateSequence}}</label>
          <label v-if="negotiationDetails?.isNewVendor" class="text-xs text-success bg-success-lightest rounded py-1 px-2 ml-3.5">New Vendor</label>
        </div>
        <h5 class="text-base text-gray-darkest">{{banDetails.vendorName}}</h5>
      </div>
      <div>
        <ValidationObserver ref="formDealBan">
          <div class="flex items-center mb-6">
            <span class="mr-4 icon-list"></span>
            <h5 class="text-lg font-medium text-gray-darkest">Detail BAN</h5>
          </div>
          <div class="grid gap-x-6 gap-y-3.5 mb-3.5 border-b border-gray-lightest pb-3.5"
            :class="{
              'grid-cols-1': projectDetailContainerWidth < 768,
              'grid-cols-2': projectDetailContainerWidth >= 768
            }">
            <InputDisabled label="Project ID" :value="banDetails.projectId"></InputDisabled>
            <InputDisabled label="Request No." :value="banDetails.requestNo"></InputDisabled>
            <InputDisabled label="Vendor Name" :value="banDetails.vendorName"></InputDisabled>
            <InputDisabled label="Project Name" :value="banDetails.projectName"></InputDisabled>
            <InputDisabled label="Request By" :value="banDetails.requestByName?.toLowerCase()" class="capitalize"></InputDisabled>
            <InputDisabled label="Created By" :value="banDetails.createdBy?.toLowerCase()" class="capitalize"></InputDisabled>
            <InputDisabled v-if="workflowGsit || workflowGsitUkkp" label="PIC GSIT" :value="banDetails.picGsitName?.toLowerCase()" class="capitalize"></InputDisabled>
            <InputDisabled v-if="workflowUkkp || workflowGsitUkkp" label="PIC UKKP" :value="banDetails.picUkkpName?.toLowerCase()" class="capitalize"></InputDisabled>
            <InputDisabled label="Date Created" :value="banDetails.createdDate"></InputDisabled>
          </div>
          <div class="mb-3">
            <div class="mb-1">
              <p class="text-sm font-medium text-gray">Project Type</p>
            </div>
            <div class="flex flex-wrap mt-3">
              <div class="flex items-center mb-3">
                <span v-if="banDetails.projectType?.includes('Hardware')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Hardware</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="banDetails.projectType?.includes('Software')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Software</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="banDetails.projectType?.includes('Service')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Service</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="banDetails.projectType?.includes('Maintenance')"  class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Maintenance</p>
              </div>
              <div class="flex items-center mb-3">
                <span v-if="banDetails.projectType?.includes('Link-Communication')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
                <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
                <p class="ml-2 mr-6 font-medium text-gray">Link-Communication</p>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <NegotiationDealTable :items="banDetails"/>
          </div>
          <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
            :class="{
              'grid-cols-1': projectDetailContainerWidth < 768,
              'grid-cols-2': projectDetailContainerWidth >= 768
            }">
            <InputDisabled label="Negotiator 1" :value="banDetails.firstNegotiatorName?.toLowerCase()" class="capitalize"></InputDisabled>
            <InputDisabled label="Negotiator 2" :value="banDetails.secondNegotiatorName?.toLowerCase() ?? '-'" class="capitalize"></InputDisabled>
            <AutocompleteNewSolutip
              label="ITP Verificator (S5-S4)"
              placeholder="(Search)"
              :options="approverNegotiation"
              @updateInput="inputSearchItpVerificator($event)"
              @updateAutoComplete="changeItpVerificator($event)"
              @blur="blurItpVerificator"
              @focus="focusItpVerificator"
              v-model="searchItpVerificator"
              textField="name"
              textField2="jobTitle"
              iconLeft="icon-search text-xl text-gray-light"
              mandatory
              :isLoading="isLoadingVerificator"
              :error="error['ITP Verificator']"
            >
              <template v-if="error['ITP Verificator']" #message>
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </template>
            </AutocompleteNewSolutip>
            <AutocompleteNewSolutip
              label="Approval ITP (S3-S1)"
              placeholder="(Search)"
              :options="approverNegotiation"
              @updateInput="inputSearchApprovalItp($event)"
              @updateAutoComplete="changeApprovalItp($event)"
              @blur="blurApprovalItp"
              @focus="focusApprovalItp"
              v-model="searchApprovalItp"
              textField="name"
              textField2="jobTitle"
              iconLeft="icon-search text-xl text-gray-light"
              mandatory
              :isLoading="isLoadingVerificator"
              :error="error['Approval ITP']"
            >
              <template v-if="error['Approval ITP']" #message>
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </template>
            </AutocompleteNewSolutip>
            <AutocompleteNewSolutip
              v-if="workflowGsit || workflowGsitUkkp"
              label="Representative GSIT (S3-S2)"
              placeholder="(Search)"
              :options="approverNegotiation"
              @updateInput="inputSearchRepresentatifGsit($event)"
              @updateAutoComplete="changeRepresentatifGsit($event)"
              @blur="blurRepresentatifGsit"
              @focus="focusRepresentatifGsit"
              v-model="searchRepresentatifGsit"
              textField="name"
              textField2="jobTitle"
              :isLoading="isLoadingVerificator"
              iconLeft="icon-search text-xl text-gray-light"
              :error="error['Representative GSIT']"
              :mandatory="workflowGsit || workflowGsitUkkp"
            >
              <template v-if="error['Representative GSIT']" #message>
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </template>
            </AutocompleteNewSolutip>
            <AutocompleteNewSolutip
              v-if="workflowGsit || workflowGsitUkkp"
              label="Approval GSIT (S1)"
              placeholder="(Search)"
              :options="approverNegotiation"
              @updateInput="inputSearchApprovalGsit($event)"
              @updateAutoComplete="changeApprovalGsit($event)"
              @blur="blurApprovalGsit"
              @focus="focusApprovalGsit"
              v-model="searchApprovalGsit"
              textField="name"
              textField2="jobTitle"
              iconLeft="icon-search text-xl text-gray-light"
              :mandatory="workflowGsit || workflowGsitUkkp"
              :isLoading="isLoadingVerificator"
              :error="error['Approval GSIT']"
            >
              <template v-if="error['Approval GSIT']" #message>
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </template>
            </AutocompleteNewSolutip>
            <AutocompleteNewSolutip
              v-if="workflowUkkp || workflowGsitUkkp"
              label="Representative UKKP (S3-S2)"
              placeholder="(Search)"
              :options="approverNegotiation"
              @updateInput="inputSearchRepresentatifUkkp($event)"
              @updateAutoComplete="changeRepresentatifUkkp($event)"
              @blur="blurRepresentatifUkkp"
              @focus="focusRepresentatifUkkp"
              v-model="searchRepresentatifUkkp"
              textField="name"
              textField2="jobTitle"
              :isLoading="isLoadingVerificator"
              iconLeft="icon-search text-xl text-gray-light"
              :error="error['Representative UKKP']"
              :mandatory="workflowUkkp || workflowGsitUkkp"
            >
              <template v-if="error['Representative UKKP']" #message>
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </template>
            </AutocompleteNewSolutip>
            <AutocompleteNewSolutip
              v-if="workflowUkkp || workflowGsitUkkp"
              label="Approval UKKP (S1)"
              placeholder="(Search)"
              :options="approverNegotiation"
              @updateInput="inputSearchApprovalUkkp($event)"
              @updateAutoComplete="changeApprovalUkkp($event)"
              @blur="blurApprovalUkkp"
              @focus="focusApprovalUkkp"
              v-model="searchApprovalUkkp"
              textField="name"
              textField2="jobTitle"
              :mandatory="workflowUkkp || workflowGsitUkkp"
              :isLoading="isLoadingVerificator"
              iconLeft="icon-search text-xl text-gray-light"
              :error="error['Approval UKKP']"
            >
              <template v-if="error['Approval UKKP']" #message>
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </template>
            </AutocompleteNewSolutip>
          </div>
          <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
            :class="{
              'grid-cols-1': projectDetailContainerWidth < 768,
              'grid-cols-2': projectDetailContainerWidth >= 768
            }">
            <div class="col-span-2">
              <ValidationProvider name="Term Of Payment"
                rules="required"
                v-slot="{ errors }">
                <div class="flex items-center">
                  <label class="block mb-1 text-sm text-gray" :class="(errors?.[0] || errorMaxLength['Term of Payment']) ? 'text-error' : 'text-gray'">
                    Term of Payment <span class="text-error">*</span>
                  </label>
                </div>
                <div class="">
                  <Tiptap @char="(e) => characterCount(e, 'Term of Payment')" v-model="banDetails.termOfPayment" :error="!!errors[0] || !!errorMaxLength['Term of Payment']">
                    <template #message>
                      <p>{{ errors[0] || errorMaxLength['Term of Payment'] }}</p>
                    </template>
                  </Tiptap>
                </div>
              </ValidationProvider>
            </div>
            <div :class="{
              'col-span-2': projectDetailContainerWidth < 768,
              'col-span-1': projectDetailContainerWidth >= 768}"
              >
              <SelectGista
                label="Waiver BG"
                :options="waiverBgOptions"
                :value="waiverBgText"
                @change="changeWaiverBg"
                :error="error['Waiver BG']"
              >
                <template #selected>
                  {{ waiverBgText || 'Select' }}
                </template>
              </SelectGista>
              <div v-if="error['Waiver BG']">
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </div>
            </div>
            <div class="col-span-2">
              <ValidationProvider name="Negotiation Note"
                rules="required"
                v-slot="{ errors }">
                <div class="flex items-center">
                  <label class="block mb-1 text-sm text-gray" :class="(errors?.[0] || errorMaxLength['Negotiation Note/ BCA Request']) ? 'text-error' : 'text-gray'">
                    Negotiation Note/ BCA Request <span class="text-error">*</span>
                  </label>
                </div>
                <div class="">
                  <Tiptap @char="(e) => characterCount(e, 'Negotiation Note/ BCA Request')" v-model="banDetails.negotiationNote" :error="!!errors[0] || !!errorMaxLength['Negotiation Note/ BCA Request']">
                    <template #message>
                      <p>{{ errors[0]  || errorMaxLength['Negotiation Note/ BCA Request']}}</p>
                    </template>
                  </Tiptap>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-span-2">
              <div class="flex items-center">
                <label class="block mb-1 text-sm text-gray" :class="errorMaxLength['Note Internal BCA']? 'text-error' : 'text-gray'">
                  Note Internal BCA
                </label>
              </div>
              <div class="">
                <Tiptap @char="(e) => characterCount(e, 'Note Internal BCA')" v-model="banDetails.internalNote" :error="!!errorMaxLength['Note Internal BCA']">
                  <template #message>
                    {{ errorMaxLength['Note Internal BCA'] }}
                  </template>
                </Tiptap>
              </div>
            </div>
            <div v-if="banDetails?.fileQuotationFileName" class="col-span-2">
              <div class="mb-1">
                <p class="mb-1 text-sm text-gray">File Quotation</p>
              </div>
              <div class="flex items-center justify-between w-full border rounded-lg border-primary h-14 min-h-file">
                <div class="px-4 py-2">
                  <p class="text-base font-medium text-gray">{{ banDetails?.fileQuotationFileName }}</p>
                </div>
                <div v-if="banDetails?.fileQuotationFileName" class="flex items-center px-4">
                  <p class="text-xl cursor-pointer icon-eye text-primary hover:text-primary-darkest" @click="openFile(banDetails?.fileQuotationFileName, banDetails?.fileQuotationDocumentNo)"></p>
                </div>
              </div>
            </div>
          </div>
          <div class="border border-gray-light rounded-lg p-3.5">
            <div class="flex items-center justify-between mb-6">
              <div class="flex items-center">
                <span class="mr-4 icon-list"></span>
                <h5 class="text-lg font-medium text-gray-darkest">Summary Negotiation</h5>
              </div>
              <div @click="clickCollapseSummary">
                <p :class="collapseSummary? 'rotate-180': 'rotate-0'"
                  class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
              </div>
            </div>
            <div v-if="!collapseSummary">
              <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
                :class="{
                  'grid-cols-1': projectDetailContainerWidth < 768,
                  'grid-cols-2': projectDetailContainerWidth >= 768
                }">
                <div>
                  <SelectGista
                    label="Initial Price"
                    :options="optionInitialPrice"
                    @change="changeInitialPrice"
                    :value="summaryWithText"
                  >
                    <template #selected>
                      {{ summaryWithText }}
                    </template>
                  </SelectGista>
                </div>
                <div>
                  <InputDisabled label="Final Price" size="medium" :value="banDetails.negotiationStatus" />
                </div>
              </div>
              <SummaryNegotiationTable/>
            </div>
          </div>
          <div class="pb-6">
            <div class="flex flex-wrap items-center justify-between w-full gap-3 mt-3 mb-6">
              <div class="flex items-center pt-3 pr-4">
                <p class="text-2xl font-bold icon-list text-gray"></p>
                <h4 class="pl-3 text-lg font-medium">Negotiation details</h4>
              </div>
              <div class="">
                <div class="flex items-center cursor-pointer" @click="clickToggleMaximizeTable">
                  <span class="mr-3 text-lg icon-maximize text-gray hover:text-gray-dark"></span>
                  <p class="text-sm font-semibold text-gray hover:text-gray-dark">Maximize table</p>
                </div>
                <MaximizeTable v-if="isOpenMaximizeTable" :isOpen="isOpenMaximizeTable" @clickToggle="clickToggleMaximizeTable"/>
              </div>
            </div>
            <NegotiationTableDetails v-if="menuRfpNegotiationOfferList?.canRead" />
          </div>
          <div class="">
            <div class="flex justify-between" :class="projectDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
              <SaveAsDraftBan v-if="menuRfpNegotiationBanSubmit?.canCreate" @click="clickSaveAsDraft" @clickToggle="clickChangeIsOpenSaveAsDraft($event)" :isOpen="isOpenSaveAsDraft" />
              <div class="gap-6" :class="projectDetailContainerWidth > 640 ? 'grid grid-cols-2' : 'flex flex-col'">
                <ButtonGista v-if="menuRfpNegotiationBanSubmit?.canCreate" type="secondary" color="primary" @click="clickPreviewBan">Preview BAN</ButtonGista>
                <SubmitBAN v-if="menuRfpNegotiationBanSubmit?.canCreate" @click="clickSubmitBAN"  :isOpenSubmitBAN="isOpenSubmitBAN" @clickToggleSubmit="clickChangeIsOpenSubmitBAN($event)"></SubmitBAN>
              </div>
            </div>
          </div>
        </ValidationObserver>
      </div>

    </div>
    <BanLoading v-else/>
    <ModalError v-if="errObj" :errObj="errObj" @clearErrObj="() => errObj = {}" @click="errClick" />
  </div>
</template>

<script>
import Tiptap from '@/core/components/custom/Tiptap.vue'
import NegotiationTableDetails from '@/core/components/rfp/negotiation/NegotiationTableDetails.vue';
import NegotiationDealTable from '@/core/components/rfp/negotiation/NegotiationDealTable.vue';
import MaximizeTable from '@/core/components/rfp/negotiation/MaximizeTable.vue';
import SummaryNegotiationTable from '@/core/components/rfp/negotiation/SummaryNegotiationTable.vue'
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import SubmitBAN from '@/core/components/modal/negotiation/SubmitBan.vue'
import { MESSAGE_EMPTY_FIELD, MESSAGE_POPUP_VALIDATION_SUBMIT } from '@/core/constant/errorMessage.js'
import { WF_RFP_GSIT, WF_RFP_GSIT_UKKP, WF_RFP_UKKP } from '@/core/constant/negotiation.js'
import SaveAsDraftBan from '@/core/components/modal/negotiation/SaveAsDraftBan.vue';
import ModalConfirm from '@/core/components/modal/Confirm.vue';
import { formatDate3 } from '@/core/components/helpers/format.js'
import BanLoading from '@/core/components/rfp/loading/BanLoading.vue';
import { MESSAGE_SUCCESS_SUBMIT_BAN } from '@/core/constant/successMessage'
import ModalError from '@/core/components/modal/negotiation/BanDealError.vue';

export default {
  name: 'DealBan',
  data() {
    return {
      searchItpVerificator: '',
      searchApprovalItp: '',
      searchRepresentatifGsit: '',
      searchApprovalGsit: '',
      searchRepresentatifUkkp: '',
      searchApprovalUkkp: '',
      collapseSummary: false,
      summaryWithText: '',
      waiverBgTextState: '',
      isOpenSubmitBAN: false,
      isOpenSaveAsDraft: false,
      isOpenDiscardChanges: false,
      isOpenMaximizeTable: false,
      isLoadingVerificator: false,
      isLoading: false,
      waiverBgOptions: [
        { value: 'TANPA_WAIVER', text: 'No Waiver'}, 
        { value: 'WAIVER_PERFOMANCE_BOND', text: 'Performance Bond' }, 
        { value: 'WAIVER_ADVANCE_PAYMENT_BOND', text: 'Advance Payment Bond' }, 
        { value: 'WAIVER_ADVANCE_PAYMENT_BOND_AND_PERFORMANCE_BOND', text: 'Advance Payment Bond and Performance Bond'}],
      MESSAGE_EMPTY_FIELD,
      error: {
        "ITP Verificator": false,
        "Approval ITP": false,
        "Approval GSIT": false,
        "Approval UKKP": false,
        "Representative GSIT": false,
        "Representative UKKP": false,
        "Waiver BG": false,
      },
      FINAL_DEAL: 'FINAL_DEAL',
      errorMaxLength: {
        'Negotiation Note': '',
        'Term of Payment': '',
        'Note Internal BCA': ''
      },
      errObj: {}
    }
  },
  computed: {
    menuRfpNegotiationBanSubmit() {
			return this.$store.getters['dashboard/menuRfpNegotiationBanSubmit']
		},
    menuRfpNegotiationOfferList() {
			return this.$store.getters['dashboard/menuRfpNegotiationOfferList']
    },
    menuRfpNegotiationBanPreview() {
			return this.$store.getters['dashboard/menuRfpNegotiationBanPreview']
    },
    banDetails: {
      get() {
        return this.$store.state.negotiation.banDetails
      },
      set(value) {
        this.$store.commit('negotiation/SET_BAN_DETAILS', value)
      }   
    },
    optionInitialPrice() {
      return this.banDetails?.negotiationHistories?.filter((e,i) => i !== this.banDetails?.negotiationHistories.length - 1).map(e => ({ text: e.negotiationStatusText, value: e.rfpNegotiationId })) || []
    },
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    negotiationDetails() {
			return this.$store.state.negotiation.negotiationDetails
		},
    approverNegotiation() {
      return this.$store.state.negotiation.approverNegotiation.filter(e => !this.filterForApproverNegotiation.includes(e.userName))
    },
    filterForApproverNegotiation() {
      let people = []
      if (this.searchItpVerificator) {
        people.push(this.banDetails.itpVerificatorId)
      }
      if (this.searchApprovalItp) {
        people.push(this.banDetails.directApproverItpId)
      }
      if (this.searchRepresentatifGsit) {
        people.push(this.banDetails.representGsitId)
      }
      if (this.searchApprovalGsit) {
        people.push(this.banDetails.directApproverGsitId)
      }
      if (this.searchRepresentatifUkkp) {
        people.push(this.banDetails.representUkkpId)
      }
      if (this.searchApprovalUkkp) {
        people.push(this.banDetails.directApproverUkkpId)
      }
      return people
    },
    workflowGsit() {
      return this.banDetails.workflow === WF_RFP_GSIT
    },
    workflowGsitUkkp() {
      return this.banDetails.workflow === WF_RFP_GSIT_UKKP
    },
    workflowUkkp() {
      return this.banDetails.workflow === WF_RFP_UKKP
    },
    waiverBgText: {
      get() {
        return this.waiverBgOptions.find(e => e.value === this.banDetails.waiverBg)?.text
      },
      set(val) {
        this.waiverBgTextState = val
      }
    },
    negotiationList() {
      return this.$store.state.negotiation.negotiationList.vendors
    },
    disabledApprover() {
      return this.negotiationList?.some(e => e.negotiationStatus === this.FINAL_DEAL)
    },
    showEditBan() {
			return this.$store.state.negotiation.showEditBan
		},
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    projectLogRfp() {
      return this.$store.state.projectLog.projectLogRfp
    },
  },
  methods: {
    checkErrorLength(){
      return Object.values(this.errorMaxLength).every(e => !e)
    },
    messageErrorLength(){
      const filterError = Object.keys(this.errorMaxLength).filter(e => this.errorMaxLength[e])
      return filterError.join(', ')
    },
    characterCount(e, name) {
      if(e > 4500) this.errorMaxLength[name] = `Kolom ${name} Maksimal 4500 Karakter`
      else this.errorMaxLength[name] = ''
    },
    formatDate3,
    clickBackToVendorDetail() {
      setTimeout(() => {
        this.$store.commit('negotiation/SET_SHOW_DEAL_BAN', false)
        this.$store.commit('negotiation/SET_SHOW_EDIT_BAN', false)
      }, 200)
    },
    clickChangeIsOpenSubmitBAN(val) {
      this.isOpenSubmitBAN = val
    },
    clickToggleBackToVendorDetail(val) {
      this.isOpenDiscardChanges = val
    },
    clickChangeIsOpenSaveAsDraft(val) {
      this.isOpenSaveAsDraft = val
    },
    clickToggleMaximizeTable() {
			this.isOpenMaximizeTable = !this.isOpenMaximizeTable
		},
    async inputSearchItpVerificator(e) {
      this.isLoadingVerificator = true
      this.searchItpVerificator = e
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: e, project: 'RFP', param: 'ITP_VERIFICATOR' })
      this.isLoadingVerificator = false
    },
    changeItpVerificator(e) {
      this.banDetails.itpVerificatorId = e.userName
      this.banDetails.itpVerificatorName = e.name
      this.searchItpVerificator = e.name
      this.error['ITP Verificator'] = false
    },
    blurItpVerificator() {
      if(this.searchItpVerificator !== '') this.searchItpVerificator = this.banDetails.itpVerificatorName
      else {
        this.banDetails.itpVerificatorName = ''
        this.banDetails.itpVerificatorId = ''
      }
    },
    async focusItpVerificator() {
      this.isLoadingVerificator = true
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: this.searchItpVerificator, project: 'RFP', param: 'ITP_VERIFICATOR' })
      this.isLoadingVerificator = false
    },
    async inputSearchApprovalItp(e) {
      this.isLoadingVerificator = true
      this.searchApprovalItp = e
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: e, project: 'RFP', param: 'SPV_ITP_APPROVAL' })
      this.isLoadingVerificator = false
    },
    changeApprovalItp(e) {
      this.banDetails.directApproverItpId = e.userName
      this.banDetails.directApproverItpName = e.name
      this.searchApprovalItp = e.name
      this.error['Approval ITP'] = false
    },
    blurApprovalItp() {
      if(this.searchApprovalItp !== '') this.searchApprovalItp = this.banDetails.directApproverItpName
      else {
        this.banDetails.directApproverItpName = ''
        this.banDetails.directApproverItpId = ''
      }
    },
    async focusApprovalItp() {
      this.isLoadingVerificator = true
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: this.searchApprovalItp, project: 'RFP', param: 'SPV_ITP_APPROVAL' })
      this.isLoadingVerificator = false
    },
    async inputSearchRepresentatifGsit(e) {
      this.isLoadingVerificator = true
      this.searchRepresentatifGsit = e
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: e, project: 'RFP', param: 'REPRESENTATIVE_GSIT' })
      this.isLoadingVerificator = false
    },
    changeRepresentatifGsit(e) {
      this.banDetails.representGsitId = e.userName
      this.banDetails.representGsitName = e.name
      this.searchRepresentatifGsit = e.name
      this.error['Representative GSIT'] = false
    },
    blurRepresentatifGsit() {
      if(this.searchRepresentatifGsit !== '') this.searchRepresentatifGsit = this.banDetails.representGsitName
      else {
        this.banDetails.representGsitName = ''
        this.banDetails.representGsitId = ''
      }
    },
    async focusRepresentatifGsit() {
      this.isLoadingVerificator = true
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: this.searchRepresentatifGsit, project: 'RFP', param: 'REPRESENTATIVE_GSIT' })
      this.isLoadingVerificator = false
    },
    async inputSearchApprovalGsit(e) {
      this.isLoadingVerificator = true
      this.searchApprovalGsit = e
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: e, project: 'RFP', param: 'SPV_GSIT_APPROVAL' })
      this.isLoadingVerificator = false
    },
    changeApprovalGsit(e) {
      this.banDetails.directApproverGsitId = e.userName
      this.banDetails.directApproverGsitName = e.name
      this.searchApprovalGsit = e.name
      this.error['Approval GSIT'] = false
    },
    blurApprovalGsit() {
      if(this.searchApprovalGsit !== '') this.searchApprovalGsit = this.banDetails.directApproverGsitName
      else {
        this.banDetails.directApproverGsitName = ''
        this.banDetails.directApproverGsitId = ''
      }
    },
    async focusApprovalGsit() {
      this.isLoadingVerificator = true
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: this.searchApprovalGsit, project: 'RFP', param: 'SPV_GSIT_APPROVAL' })
      this.isLoadingVerificator = false

    },
    async inputSearchRepresentatifUkkp(e) {
      this.isLoadingVerificator = true
      this.searchRepresentatifUkkp = e
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: e, project: 'RFP', param: 'REPRESENTATIVE_UKKP' })
      this.isLoadingVerificator = false
    },
    changeRepresentatifUkkp(e) {
      this.banDetails.representUkkpId = e.userName
      this.banDetails.representUkkpName = e.name
      this.searchRepresentatifUkkp = e.name
      this.error['Representative UKKP'] = false
    },
    blurRepresentatifUkkp() {
      if(this.searchRepresentatifUkkp !== '') this.searchRepresentatifUkkp = this.banDetails.representUkkpName
      else {
        this.banDetails.representUkkpName = ''
        this.banDetails.representUkkpId = ''
      }
    },
    async focusRepresentatifUkkp() {
      this.isLoadingVerificator = true
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: this.searchRepresentatifUkkp, project: 'RFP', param: 'REPRESENTATIVE_UKKP' })
      this.isLoadingVerificator = false
    },
    async inputSearchApprovalUkkp(e) {
      this.isLoadingVerificator = true
      this.searchApprovalUkkp = e
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: e, project: 'RFP', param: 'SPV_UKKP_APPROVAL' })
      this.isLoadingVerificator = false
    },
    changeApprovalUkkp(e) {
      this.banDetails.directApproverUkkpId = e.userName
      this.banDetails.directApproverUkkpName = e.name
      this.searchApprovalUkkp = e.name
      this.error['Approval UKKP'] = false
    },
    blurApprovalUkkp() {
      if(this.searchApprovalUkkp !== '') this.searchApprovalUkkp = this.banDetails.directApproverUkkpName
      else {
        this.banDetails.directApproverUkkpName = ''
        this.banDetails.directApproverUkkpId = ''
      }
    },
    async focusApprovalUkkp() {
      this.isLoadingVerificator = true
      await this.$store.dispatch('negotiation/getUserApproverNegotiation', { name: this.searchApprovalUkkp, project: 'RFP', param: 'SPV_UKKP_APPROVAL' })
      this.isLoadingVerificator = false
    },
    changeWaiverBg(e) {
      this.waiverBgText = e.text
      this.banDetails.waiverBg = e.value
      this.error['Waiver BG'] = false
    },
    changeInitialPrice(e) {
      const negotiationId = e.value
      this.banDetails.summaryWith = e.value
      this.summaryWithText = e.text
      this.$store.dispatch('negotiation/getSummaryWith', { negotiationId, vendorCandidateId: this.negotiationDetails.vendorCandidateId })
    },
    clickCollapseSummary() {
      this.collapseSummary = !this.collapseSummary
    },
    formatDate(val) {
      if (!val) {
        return ''
      }
      const dateSplit = val.split('/')
      const valDay = dateSplit[0]
      const valMonth = dateSplit[1]
      const valYear = dateSplit[2]
      const currentDate = new Date(valYear, valMonth - 1, valDay)
      const date = currentDate.getDate().toString()
      const month = currentDate.toLocaleString('id-ID', { month: 'long'} )
      const year = currentDate.getFullYear().toString()
      return `${date.padStart(2, 0)} ${month} ${year}`
    },
    async clickSaveAsDraft() {
      try {
        this.modal.modalLoading = true
        const checkErrorLength = this.checkErrorLength()
        const MESSAGE_ERROR_VALIDATE = this.messageErrorLength()
        if (!checkErrorLength) {
          this.modal.modalLoading = false
          this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE }
          this.clickChangeIsOpenSaveAsDraft(false)
          return
        }
        const payload = {
          negotiationId: this.banDetails.negotiationId,
          summaryWith: this.banDetails.summaryWith,
          projectId: this.banDetails.projectId,
          projectName: this.banDetails.projectName,
          requestNo: this.banDetails.requestNo,
          vendorName: this.banDetails.vendorName,
          requestById: this.banDetails.requestById,
          picGsitId: this.banDetails.picGsitId,
          picUkkpId: this.banDetails.picUkkpId,
          projectType: this.banDetails.projectType,
          firstNegotiatorId: this.banDetails.firstNegotiatorId,
          secondNegotiatorId: this.banDetails.secondNegotiatorId,
          negotiationParticipant: this.banDetails.negotiationParticipant?.map(e => e.userDomain),
          termOfPayment: this.banDetails.termOfPayment,
          waiverBg: this.banDetails.waiverBg,
          negotiationNote: this.banDetails.negotiationNote,
          internalNote: this.banDetails.internalNote,
          itpVerificatorId: this.banDetails.itpVerificatorId,
          representGsitId: this.banDetails.representGsitId,
          representUkkpId: this.banDetails.representUkkpId,
          directApproverItpId: this.banDetails.directApproverItpId,
          directApproverGsitId: this.banDetails.directApproverGsitId,
          directApproverUkkpId: this.banDetails.directApproverUkkpId,
        }
        const response = await this.$store.dispatch('negotiation/postBanSaveAsDraft', payload)
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
          this.clickChangeIsOpenSaveAsDraft(false)
          setTimeout(() => {
            this.$store.commit('negotiation/SET_SHOW_DEAL_BAN', false)
            this.$store.commit('negotiation/SET_SHOW_EDIT_BAN', false)
          }, 200)
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
        this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
      }
    },
    checkErrorValidate() {
      if (!this.banDetails.itpVerificatorId) {
        this.error['ITP Verificator'] = true
      }
      if (!this.banDetails.directApproverItpId) {
        this.error['Approval ITP'] = true
      }
      if(this.workflowGsit || this.workflowGsitUkkp) {
        if (!this.banDetails.directApproverGsitId) {
          this.error['Approval GSIT'] = true
        }
        if (!this.banDetails.representGsitId) {
          this.error['Representative GSIT'] = true
        }
      }
      if(this.workflowUkkp || this.workflowGsitUkkp){
        if (!this.banDetails.directApproverUkkpId) {
          this.error['Approval UKKP'] = true
        }
        if (!this.banDetails.representUkkpId) {
          this.error['Representative UKKP'] = true
        }
      }
      if (!this.banDetails.waiverBg) {
        this.error['Waiver BG'] = true
      }
      return Object.values(this.error).every(e => !e)
    },
    messageErrorValidate() {
      const errorVeeValidate = this.$refs.formDealBan.errors
      const filterError = Object.keys(errorVeeValidate).filter(e => errorVeeValidate[e].length > 0)
      const filterErrorCustom = Object.keys(this.error).filter(e => this.error[e] === true)
      return [ ...filterErrorCustom, ...filterError ].join(', ')
    },
    async clickSubmitBAN() {
      try {
        this.modal.modalLoading = true
        const successValidate = await this.$refs.formDealBan.validate()
        const checkErrorValidate = this.checkErrorValidate()
        const MESSAGE_ERROR_VALIDATE = this.messageErrorValidate()
        const checkErrorLength = this.checkErrorLength()
        const MESSAGE_ERROR_LENGTH = this.messageErrorLength()
        if (!successValidate || !checkErrorValidate || !checkErrorLength) {
          this.modal.modalLoading = false
          const message = MESSAGE_ERROR_VALIDATE && MESSAGE_ERROR_LENGTH ? `, ${MESSAGE_ERROR_LENGTH}` : MESSAGE_ERROR_LENGTH
          this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE + message }
          this.clickChangeIsOpenSubmitBAN(false)
          return
        }
        const payload = {
          negotiationId: this.banDetails.negotiationId,
          summaryWith: this.banDetails.summaryWith,
          projectId: this.banDetails.projectId,
          projectName: this.banDetails.projectName,
          requestNo: this.banDetails.requestNo,
          vendorName: this.banDetails.vendorName,
          requestById: this.banDetails.requestById,
          picGsitId: this.banDetails.picGsitId,
          picUkkpId: this.banDetails.picUkkpId,
          projectType: this.banDetails.projectType,
          firstNegotiatorId: this.banDetails.firstNegotiatorId,
          secondNegotiatorId: this.banDetails.secondNegotiatorId,
          negotiationParticipant: this.banDetails.negotiationParticipant?.map(e => e.userDomain),
          termOfPayment: this.banDetails.termOfPayment,
          waiverBg: this.banDetails.waiverBg,
          negotiationNote: this.banDetails.negotiationNote,
          internalNote: this.banDetails.internalNote,
          itpVerificatorId: this.banDetails.itpVerificatorId,
          representGsitId: this.banDetails.representGsitId,
          representUkkpId: this.banDetails.representUkkpId,
          directApproverItpId: this.banDetails.directApproverItpId,
          directApproverGsitId: this.banDetails.directApproverGsitId,
          directApproverUkkpId: this.banDetails.directApproverUkkpId,
        }
        const response = await this.$store.dispatch('negotiation/postBanSubmit', payload)
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
          this.$store.commit('projectLog/SET_RFP_LOG_ID', this.projectLogRfp?.rfpLogs?.at(-1)?.rfpLogId)
          this.$emit("refetch")
          this.$store.commit('negotiation/SET_SHOW_DEAL_BAN', false)
          this.$store.commit('negotiation/SET_SHOW_EDIT_BAN', false)
          this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_SUBMIT_BAN }
          this.clickChangeIsOpenSubmitBAN(false)
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
        if(error.message.includes('draft')) this.errObj = { value: true, traceid: error.traceid, title: error.title, message: error.message }
        else this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
      }
    },
    async clickPreviewBan() {
      try {
        this.modal.modalLoading = true
        const checkErrorLength = this.checkErrorLength()
        const MESSAGE_ERROR_VALIDATE = this.messageErrorLength()
        if (!checkErrorLength) {
          this.modal.modalLoading = false
          this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE }
          return
        }
        const payload = {
          negotiationId: this.banDetails.negotiationId,
          summaryWith: this.banDetails.summaryWith,
          projectId: this.banDetails.projectId,
          projectName: this.banDetails.projectName,
          requestNo: this.banDetails.requestNo,
          vendorName: this.banDetails.vendorName,
          requestById: this.banDetails.requestById,
          picGsitId: this.banDetails.picGsitId,
          picGsitName: this.banDetails.picGsitName,
          picUkkpId: this.banDetails.picUkkpId,
          picUkkpName: this.banDetails.picUkkpName,
          offerNo: this.banDetails.offerNo,
          offerDate: this.banDetails.offerDate,
          projectType: this.banDetails.projectType,
          meetingDate: this.banDetails.meetingDate,
          timeStart: this.banDetails.timeStart,
          timeEnd: this.banDetails.timeEnd,
          location: this.banDetails.location,
          viaOnline: this.banDetails.viaOnline,
          firstNegotiatorId: this.banDetails.firstNegotiatorId,
          secondNegotiatorId: this.banDetails.secondNegotiatorId,
          itpVerificatorId: this.banDetails.itpVerificatorId,
          representGsitId: this.banDetails.representGsitId,
          representUkkpId: this.banDetails.representUkkpId,
          directApproverItpId: this.banDetails.directApproverItpId,
          directApproverGsitId: this.banDetails.directApproverGsitId,
          directApproverUkkpId: this.banDetails.directApproverUkkpId,
          termOfPayment: this.banDetails.termOfPayment,
          waiverBg: this.banDetails.waiverBg,
          negotiationNote: this.banDetails.negotiationNote,
          internalNote: this.banDetails.internalNote,
        }
        const response = await this.$store.dispatch('negotiation/postBanPreview', payload)
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
        this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
      }
    },
    openFile(filename, documentNo) {
      this.$store.dispatch('file/viewAttachment', { filename, documentNo })
    },
    errClick() {
      this.clickSaveAsDraft()
    }
  },
  async mounted() {
    this.isLoading = true
    const r1 = this.$store.dispatch('negotiation/getBanDetails', { negotiationId: this.negotiationDetails.offers[this.negotiationDetails.offers.length - 1].negotiationId })
    const r2 = this.$store.dispatch('negotiation/getNegotiationOfferItems', { vendorCandidateId: this.negotiationDetails.vendorCandidateId})
		const r3 = this.$store.dispatch('negotiation/getNegotiation', { rfpId: this.$route.params.projectId })
    const [res1, res2, res3] = await Promise.all([r1, r2, r3])
    if ([res1?.status, res2?.status, res3?.status].every(e => e === 200)) {
      this.isLoading = false
      if (!this.banDetails.summaryWith) {
        this.$store.dispatch('negotiation/getSummaryWith', { negotiationId: this.negotiationDetails.offers[0].negotiationId, vendorCandidateId: this.negotiationDetails.vendorCandidateId })
        this.summaryWithText = this.optionInitialPrice.length > 0? this.optionInitialPrice[0].text : ''
        this.banDetails.summaryWith = this.optionInitialPrice.length > 0? this.optionInitialPrice[0].value : ''
      } else {
        this.$store.dispatch('negotiation/getSummaryWith', { negotiationId: this.banDetails.summaryWith, vendorCandidateId: this.negotiationDetails.vendorCandidateId })
        this.summaryWithText = this.optionInitialPrice.length > 0? this.optionInitialPrice[this.optionInitialPrice?.map(e => e.value).indexOf(this.banDetails.summaryWith)].text : ''
      }
      if (!this.banDetails.waiverBg) {
        this.changeWaiverBg(this.waiverBgOptions[0])
      }
      this.searchItpVerificator = this.banDetails.itpVerificatorName
      this.searchApprovalItp = this.banDetails.directApproverItpName
      this.searchApprovalGsit = this.banDetails.directApproverGsitName
      this.searchRepresentatifGsit = this.banDetails.representGsitName
      this.searchRepresentatifUkkp = this.banDetails.representUkkpName
      this.searchApprovalUkkp = this.banDetails.directApproverUkkpName
    }
  },
  components: {
    Tiptap,
    NegotiationTableDetails,
    NegotiationDealTable,
    MaximizeTable,
    SummaryNegotiationTable,
    AutocompleteNewSolutip,
    SubmitBAN,
    SaveAsDraftBan,
    InputDisabled,
    ModalConfirm,
    BanLoading,
    ModalError
}
}
</script>

<style scoped>
.min-width-table {
  min-width: 56rem;
}
</style>