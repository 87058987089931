<template>
  <div class="w-full p-6 border-b border-gray-light">
    <div class="flex justify-between mb-4 cursor-pointer" @click="toggleCollapse">
      <div class="flex">
        <span class="mr-6 text-2xl icon-x text-gray-dark"></span>
        <h4 class="text-base font-semibold text-gray-dark">ELIMINATED ({{vendorDetailProjectEliminated.length}})</h4>
      </div>
      <div >
				<p :class="collapseEliminated? 'rotate-180' : 'rotate-0'"
					class="text-2xl transition ease-in transform icon-chevron-down text-gray"></p>
			</div>
    </div>
    <div v-show="!collapseEliminated" class="overflow-auto">
      <div v-if="vendorDetailProjectEliminated.length === 0" class="flex justify-center py-6">
				<p class="font-medium text-gray">No Data</p>
			</div>
      <Card v-for="(n,i) in vendorDetailProjectEliminated" :key="i" class="flex flex-col items-center justify-between w-full mb-6 border md:width-custom md:flex-row border-gray-lightest">
        <div class="flex flex-col w-full md:w-3/4">
          <div class="flex flex-col flex-wrap mb-3 md:flex-row md:gap-0 gap-1.5">
            <div class="">
              <label class="text-sm">Project ID</label>
              <h5 class="text-primary-darkest">{{n.projectId}}</h5>
            </div>
            <div class="line-vertical"></div>
            <div class="">
              <label class="text-sm">No. RFP</label>
              <h5>{{n.noRequest}}</h5>
            </div>
            <div class="line-vertical"></div>
            <div class="">
              <label class="text-sm">Project Name</label>
              <h5>{{n.projectName}}</h5>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="flex items-center mb-3">
              <span class="icon-user text-3xl mr-3.5"></span>
              <div class="ml-2.5">
                <label class="text-xs">Technical Coordinator</label>
                <h5>{{n.technicalCoordinatorName}}</h5>
              </div>
            </div>
            <div class="line-vertical"></div>
            <div class="flex items-center mb-3">
              <span class="icon-calendar text-3xl mr-3.5"></span>
              <div class="">
                <label class="text-xs">Date Created</label>
                <h5>{{n.createdAt}}</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="w-full md:w-1/4 md:mt-0 mt-1.5  flex justify-end">
          <ButtonGista :isDark="false"
            color="primary"
            type="secondary"
            customClass="w-full md:w-max"
            @click="seeDetailProject(n)">
            See details
          </ButtonGista>
        </div>
      </Card>
    </div>
  </div>
</template>
<script>
import Card from '@/core/components/custom/Card.vue'
import { PATH_ROOT, PROJECT_DETAIL, QUOTATION_DETAIL } from '@/core/constant/routeName'

export default {
	name: "Eliminated",
	data() {
		return {
			collapseEliminated: false
		}

	},
  computed: {
    
    vendorDetailProjectEliminated() {
      return this.$store.state.vendor.vendorDetailProjectEliminated
    }
  },
  methods: {
		toggleCollapse() {
			this.collapseEliminated = !this.collapseEliminated
		},
    seeDetailProject(project) {
      this.$emit('closeModal')
      setTimeout(() => {
        if (project.projectRequestType === 'RFP') {
          this.$router.push(`/${PATH_ROOT}/${PROJECT_DETAIL}/${project.projectRequestId}`).catch(() => ({}))
        } else {
          this.$router.push(`/${PATH_ROOT}/${QUOTATION_DETAIL}/${project.projectRequestId}`).catch(() => ({}))
        }
      }, 200)
    }

	},
	components: {
		Card
	}
}
</script>
<style scoped>
.width-custom {
	min-width: 50rem;
}
</style>