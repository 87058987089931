<template>
	<div class="w-full">
		<div class="w-full px-4 pb-4" v-if="detailPage">
			<div class="">
				<ButtonGista type="secondary"
					color="primary"
					@click="toggle"
					customClass="w-full">
					Change Approver</ButtonGista>
			</div>
		</div>
		<GistaModal :value="isOpen"
			customClass="max-w-2xl bg-white"
			@input="toggle">
			<ValidationObserver ref="updateApprover">
				<div class="w-full p-5">
					<div class="flex justify-center w-full mb-4">
						<h5 class="text-lg font-semibold text-gray">Change Approver</h5>
					</div>
					<div class="w-full mb-4">
						<ValidationProvider name="newApprover"
							rules="required"
							v-slot="{ errors }">
							<AutocompleteNewSolutip
								label="New Approver"
								placeholder="(Search)"
								:options="approverUkkp"
								@updateInput="inputSearchApprover($event)"
								@updateAutoComplete="changeApprover($event)"
								@blur="blurApprover"
								@focus="focusApprover"
								v-model="newApproverNameLocalState"
								:isLoading="isLoading"
								:error="!!errors[0]"
								textField="name"
								textField2="jobTitle"
								iconLeft="icon-search text-xl text-gray-light"
								:mandatory="true"
							>
								<template #message>
									<p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
								</template>
							</AutocompleteNewSolutip>
						</ValidationProvider>

					</div>
					<div class="w-full mb-4">
						<ValidationProvider name="notes"
							rules="required|max:500"
							v-slot="{ errors }">
							<TextareaGista label="Notes"
								placeholder="Leave a comment here"
								v-model="form.notes"
								:error="!!errors[0]"
								:mandatory="true">
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</TextareaGista>
						</ValidationProvider>
					</div>
					<div class="flex justify-end">
						<ButtonGista type="primary"
							color="success"
							@click="clickChangeApprover"
							customClass="w-full sm:w-56">
							Change Approver
						</ButtonGista>

					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import { QUOTATION_DETAIL, PATH_ROOT } from '@/core/constant/routeName'
// import { UKKP_01_AWAITING_APPROVAL } from '@/core/constant/statusProject'
import { MESSAGE_SUCCESS_CHANGE_APPROVER } from '@/core/constant/successMessage'

export default {
	name: "UpdateApprove",
	data() {
		return {
			isOpen: false,
			roleApproverUkkp: 'SOLUTIP_SPV_KP',
			form: {
				newApproverName: '',
				newApproverId: '',
				notes: '',
			},
			newApproverNameLocalState: '',
			timeout: null,
			isLoading: false,
		}

	},
	computed: {
		approverUkkp() {
			return this.$store.state.rfp.approverUkkp;
		},
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		quotationInformation() {
			return this.$store.state.quotationDetail.quotationInformation;
		},
		menuDetailsActionUpdateApprover() {
			return this.$store.getters['dashboard/menuDetailsActionUpdateApprover']
		},
		rfpLogApproval() {
			return this.$store.state.projectDetail.rfpLogApproval
		},
		detailPage() {
      return this.$route.path.includes('detail')
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
	},
	methods: {
		toggle() {
			this.isOpen = !this.isOpen
			this.form = {
				newApproverName: '',
				newApproverId: '',
				notes: '',
			}
			this.newApproverNameLocalState = ''
		},
		async dispatchGetApproverRfp() {
			if (this.rfpLogApproval?.reviewerGsitStatus?.toLowerCase() === 'on review') {
				await this.$store.dispatch('rfp/getApproverUkkp', { role: '', type: 'REVIEWER_GSIT', name: this.newApproverNameLocalState, project: 'RFP', id: +this.proposalInformation.rfpId })
			} else if (this.rfpLogApproval?.reviewerKpStatus?.toLowerCase() === 'on review') {
				await this.$store.dispatch('rfp/getApproverUkkp', { role: '', type: 'REVIEWER_UKKP', name: this.newApproverNameLocalState, project: 'RFP', id: +this.proposalInformation.rfpId })
			} else if (this.rfpLogApproval?.directApproverGsitStatus?.toLowerCase() === 'on review') {
				await this.$store.dispatch('rfp/getApproverUkkp', { role: '', type: 'APPROVER_GSIT', name: this.newApproverNameLocalState, project: 'RFP', id: +this.proposalInformation.rfpId })
			} else if (this.rfpLogApproval?.directApproverKpStatus?.toLowerCase() === 'on review') {
				await this.$store.dispatch('rfp/getApproverUkkp', { role: '', type: 'APPROVER_UKKP', name: this.newApproverNameLocalState, project: 'RFP', id: +this.proposalInformation.rfpId })
			} else if (this.rfpLogApproval?.firstApproverDlogStatus?.toLowerCase() === 'on review') {
				await this.$store.dispatch('rfp/getApproverUkkp', { role: '', type: 'APPROVER_DLOG_1', name: this.newApproverNameLocalState, project: 'RFP', id: +this.proposalInformation.rfpId })
			} else if (this.rfpLogApproval?.secondApproverDlogStatus?.toLowerCase() === 'on review') {
				await this.$store.dispatch('rfp/getApproverUkkp', { role: '', type: 'APPROVER_DLOG_2', name: this.newApproverNameLocalState, project: 'RFP', id: +this.proposalInformation.rfpId })
			}
		},
		async inputSearchApprover(event) {
			this.isLoading = true
			if (!this.$route.path.includes(QUOTATION_DETAIL)) {
				this.dispatchGetApproverRfp()
			} else {
				await this.$store.dispatch('rfp/getApproverUkkp', { role: this.menuDetailsActionUpdateApprover.applicationRoleName, name: event, project: 'RFQ', id: +this.quotationInformation.rfqId })
			}
			this.isLoading = false
		},
		async focusApprover() {
			this.isLoading = true
			if (!this.$route.path.includes(QUOTATION_DETAIL)) {
				this.dispatchGetApproverRfp()
			} else {
				await this.$store.dispatch('rfp/getApproverUkkp', { role: this.menuDetailsActionUpdateApprover.applicationRoleName, name: this.newApproverNameLocalState, project: 'RFQ', id: +this.quotationInformation.rfqId })
			}
			this.isLoading = false
		},
		changeApprover(event) {
			this.form.newApproverId = event.userName
			this.form.newApproverName = event.name
			this.newApproverNameLocalState = event.name
		},
		blurApprover() {
			if (!this.newApproverNameLocalState) {
				this.form.newApproverName = ''
				this.form.newApproverId = ''
			}
			this.newApproverNameLocalState = this.form.newApproverName
		},
		async clickChangeApprover() {
			try {
				this.modal.modalLoading = true
				const successValidate = await this.$refs.updateApprover.validate()
				if (!successValidate) {
					this.modal.modalLoading = false
					return
				}
				const quotationPath = this.$route.path.includes(QUOTATION_DETAIL)
				let data = {
					newApprover: this.form.newApproverId,
					note: this.form.notes,
				}
				if (quotationPath) {
					data = { ...data, rfqId: this.$route.params.quotationId }
				} else {
					data = { ...data, rfpId: this.$route.params.projectId}
				}
				const response = await this.$store.dispatch(`${quotationPath? 'quotationDetail/putChangeApprover' : 'projectDetail/putChangeApprover'}`, data)
				if (response?.status < 300 && response?.status >= 200) {
					this.$store.commit('projectLog/SET_PROJECT_ID', quotationPath? this.quotationInformation.projectId : this.proposalInformation.projectId)
					this.modal.modalLoading = false
					this.isOpen = !this.isOpen
					this.form =  {
						newApproverName: '',
						newApproverId: '',
						notes: '',
					}
					this.newApproverNameLocalState = ''
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_CHANGE_APPROVER }
					const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
					this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
					this.$emit('refetchProjectList')
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		}

	},
	components: {
		AutocompleteNewSolutip
	}

}
</script>