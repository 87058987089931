<template>
	<div>
		<div  v-if="negotiationShow && !isLoading" class="w-full">
			<div class="flex justify-center mt-6" v-if="!showDealBan && !showFPTN && !showReviseOffer && !showDetailBan && !showDetailOffer && !showDetailFPTN">
				<ThreeButton :options="optionsTab"
					@click="changeActiveButton"
					:value="activeButton"
					:class="{'w-min min-w-custom': quotationDetailContainerWidth > 640}" />
			</div>
			<VendorDetails v-if="showNegotiationTab && !showReviseOffer && !showDealBan && !showFPTN && !showDetailFPTN && !showDetailBan && !showDetailOffer"/>
			<Ban @setShowNegotiationTab="setShowNegotiationTab" v-if="!showNegotiationTab && !showDetailFPTN && !showDetailBan && !showDetailOffer"/>
			<ReviseOffer @refetch="refetchProjectLog" v-if="menuRfqNegotiationOfferRevision && showReviseOffer && showNegotiationTab"/>
			<Deal @refetch="refetchProjectLog" v-if="showDealBan && showNegotiationTab"></Deal>
			<FPTN @refetch="refetchProjectLog" v-if="showFPTN && showNegotiationTab"></FPTN>
			<DetailBan v-if="showDetailBan" />
			<DetailBanOfferRevision v-if="showDetailOffer"></DetailBanOfferRevision>
			<DetailFPTN v-if="showDetailFPTN"></DetailFPTN>

		</div>
		<div v-if="!negotiationShow && !isLoading"
			class="flex items-center justify-center height-custom">
			<div class="flex">
				<p class="mr-3 text-2xl icon-alert-circle text-gray"></p>
				<p class="text-base font-medium text-gray">Proses Negosiasi belum di mulai</p>
			</div>
		</div>
		<NegotiationDetailLoading v-if="isLoading"/>
	</div>
</template>

<script>
import Ban from '@/core/components/rfq/negotiation/Ban.vue';
import ThreeButton from '@/core/components/custom/ThreeButton.vue';
import VendorDetails from '@/core/components/rfq/negotiation/VendorDetails.vue';
import ReviseOffer from '@/core/components/rfq//negotiation/ReviseOffer.vue';
import Deal from '@/core/components/rfq//negotiation/Deal.vue';
import FPTN from '@/core/components/rfq//negotiation/FPTN.vue';
import DetailFPTN from '@/core/components/rfq/negotiation/DetailFPTN.vue';
import DetailBan from '@/core/components/rfq/negotiation/DetailBan.vue';
import DetailBanOfferRevision from '@/core/components/rfq/negotiation/DetailBanOfferRevision.vue';
import NegotiationDetailLoading from '@/core/components/rfq/loading/NegotiationDetailLoading.vue';
import { STAFF_ITP_02_AWAITING_QUOTATION, COMPLETED, CANCELED } from '@/core/constant/statusProject'
import { QUOTATIONNEGOTIATION, PATH_ROOT, QUOTATION_DETAILS } from '@/core/constant/routeName'

export default {
	name: "NegotiationQuotation",
	data() {
		return {
			activeButton: 'Negotiation',
			isLoading: true,
			optionsTab: ['Negotiation', 'BAN'],

		}
	},
	computed: {
		vendorDetail: {
      get() {
        return this.$store.state.negotiationRfq.negotiationShow
      },
      set(value) {
        this.$store.commit('negotiationRfq/SET_NEGOTIATION_SHOW', value)
      }
    },
		loading: {
			get() {
				return this.$store.state.modal.loading
			},
			set(value) {
				this.$store.commit('modal/SET_LOADING', value)
			}
		},
		showNegotiationTab() {
			return this.activeButton === 'Negotiation'
		},
		showReviseOffer() {
			return this.$store.state.negotiationRfq.showReviseOffer
		},
		showDealBan() {
			return this.$store.state.negotiationRfq.showDealBan
		},
		showFPTN() {
			return this.$store.state.negotiationRfq.showFPTN
		},
		showDetailFPTN() {
			return this.$store.state.negotiationRfq.showDetailFPTN
		},
		showDetailBan() {
			return this.$store.state.negotiationRfq.showDetailBan
		},
		showDetailOffer(){
			return this.$store.state.negotiationRfq.showDetailOffer
		},
		projectLogRfq() {
			return this.$store.state.projectLog.projectLogRfq
		},
		negotiationShow() {
			const statusProject = [STAFF_ITP_02_AWAITING_QUOTATION, COMPLETED, CANCELED]
			return this.projectLogRfq?.rfqLogs.some(e => statusProject.includes(e.statusProject))
		},
		menuRfqNegotiationOfferRevision() {
			return this.$store.getters['dashboard/menuRfqNegotiationOfferRevision']?.canRead
		},
		quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
		projectCanceledByLogs() {
			return this.projectLogRfq.rfqLogs.some(e => e.statusProject === CANCELED)
		},
		projectCanceled() {
			return this.sppProcess || this.projectCanceledByLogs
		},
		sppProcess() {
			return this.$store.state.sppRfq.sppProcess
		},
		negotiationShowTab() {
			const statusProject = [ STAFF_ITP_02_AWAITING_QUOTATION ]
			const statusProjectNegotiation = this.projectLogRfq?.rfqLogs.some(e => statusProject.includes(e.statusProject))
			return !this.projectCanceled || statusProjectNegotiation
		},
		activePage: {
			get() {
				return this.$store.state.quotationDetail.activePage
			},
			set(value) {
				this.$store.commit('quotationDetail/SET_ACTIVE_PAGE', value)
			}
		},
	},
	watch: {
		$route() {
			this.changeQueryTab()
			this.changeQueryBan()
		}
	},
	methods: {
		setShowNegotiationTab() {
			this.changeActiveButton('Negotiation')
		},
		changeActiveButton(e) {
			this.activeButton = e
      this.$router.push({ name: QUOTATIONNEGOTIATION, query: { tab: e }}).catch(() => ({}))
		},
		refetchProjectLog() {
			this.$store.dispatch('projectLog/refetchProjectLogByRfqId', this.$route.params.quotationId)
		},
		changeQueryTab() {
			if (this.$route.query.tab === 'BAN') {
				this.activeButton = this.optionsTab[1]
			} else {
				this.activeButton = this.optionsTab[0]
			}
		},
		changeQueryBan() {
			if (this.$route.query.negotiation && this.$route.query.detail) {
				this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_OFFER', true)
			} else if (this.$route.query.negotiation && !this.$route.query.detail) {
				this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_BAN', true)
			} else if (this.$route.query.fptn) {
        this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_FPTN', true)
			} else {
				this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_BAN', false)
				this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_OFFER', false)
        this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_FPTN', false)
			}
		},
		setActivePage(paramQuotationId) {
			if (!this.negotiationShowTab) {
				this.activePage = QUOTATION_DETAILS
				this.$router.push({ path: `/${PATH_ROOT}/${QUOTATION_DETAILS}/${paramQuotationId}` }).catch(() => ({}))
			}
		},
	},
	async	mounted() {
		this.isLoading = true
		this.loading.loadingProjectLog = true
		const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.quotationId, projectRequestType: 'rfq' })
    if (res?.data.code !== 200) return
		const res1 = await this.$store.dispatch('projectLog/getProjectLogByRfqId', { rfqId: this.$route.params.quotationId })
		this.setActivePage(this.$route.params.quotationId)
		if (res1?.status === 200) {
				this.isLoading = false
				this.loading.loadingProjectLog = false
			}
		this.changeQueryTab()
		this.changeQueryBan()
	},
	destroyed() {
		this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_OFFER', false)
		this.$store.commit('negotiationRfq/SET_SHOW_REVISE_OFFER', false)
		this.$store.commit('negotiationRfq/SET_SHOW_DEAL_BAN', false)
		this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_BAN', false)
		this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_FPTN', false)
	},
	components: {
    Ban,
    ThreeButton,
    VendorDetails,
    ReviseOffer,
    Deal,
    FPTN,
    DetailFPTN,
    DetailBan,
    DetailBanOfferRevision,
		NegotiationDetailLoading,
	}
}
</script>


<style scoped>
.height-custom {
	min-height: 30rem;
}
.min-w-custom {
	min-width: 20rem;
}
</style>