<template>
	<div class="w-full p-6 border-b border-gray-light">
		<div class="flex justify-between cursor-pointer"
			@click="changeCollapse">
			<div class="flex">
				<span class="mr-6 text-2xl icon-list text-gray-dark"></span>
				<div>
					<h4 class="text-base font-semibold text-gray-dark">PROJECT REQUIREMENT <span class="text-error">*</span></h4>
					<p class="mt-1 text-xs text-error" v-if="errorProjectRequirement?.value">{{errorProjectRequirement.message}}</p>
				</div>
			</div>
			<div>
				<p :class="collapse.projectRequirement ? 'rotate-180' : 'rotate-0'"
					class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
			</div>
		</div>
		<div v-show="collapse.projectRequirement"
			class="mt-6">
			<div class="flex justify-between w-full">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.persyaratanProdukDanJasa.value) ? 'text-error' : 'text-gray'">
							Persyaratan Produk dan Jasa
						</label>
					</div>
					<div class="">
						<Tiptap @char="(e) => characterCount(e, 'persyaratanProdukDanJasa', 'Persyaratan Produk dan Jasa')" v-model="projectRequirement.persyaratanProdukDanJasa" :error="errorProjectRequirement.value || errorProjectRequirement1.persyaratanProdukDanJasa.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.persyaratanProdukDanJasa.message }}</p>
							</template>
						</Tiptap>
					</div>
				</div>
			</div>
			<div class="w-full mt-1 mb-6">
				<div class="flex items-center justify-between w-full border rounded-lg"
					:class="`${errorProjectRequirement2.persyaratanProdukDanJasaFile?.value ? 'border-error' : 'border-primary'}`">
					<input type="file"
						id="upload-file1"
						ref="file1"
						hidden
						@change="chooseFile($event, 'file1', 'persyaratanProdukDanJasa')" />
					<div class="flex justify-between" style="width: calc(100% - 7rem);">
						<span class="px-4 text-sm truncate text-gray" :title="projectRequirement.rfpAttachments.find(e => e.fileRef === 'persyaratanProdukDanJasa' && !e.isDeleted)?.fileName">{{ projectRequirement.rfpAttachments.find(e => e.fileRef === 'persyaratanProdukDanJasa' && !e.isDeleted)?.fileName || 'No File Choosen' }}</span>
						<span v-if="projectRequirement.rfpAttachments.find(e => e.fileRef === 'persyaratanProdukDanJasa' && !e.isDeleted)?.fileName" class="pr-2 cursor-pointer icon-trash-2 text-error hover:text-error-dark" @click="removeFile('persyaratanProdukDanJasa')"></span>
					</div>
					<label v-if="!loadingfile1" for="upload-file1"
						class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
						Pilih File
					</label>
					<label v-else
						class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
						<div class="w-6 h-6 ease-linear border-4 border-t-4 rounded-full loader border-primary"></div>
					</label>
				</div>
				<div>
					<p class="mt-1 text-xs text-tertiary"
						:class="{ 'text-error': errorProjectRequirement2.persyaratanProdukDanJasaFile?.value }">
						Supported file: .pdf, max file size: 10 MB
					</p>
				</div>
			</div>
			<div class="flex justify-between w-full">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.persyaratanTeknis.value) ? 'text-error' : 'text-gray'">
							Persyaratan Teknis
						</label>
					</div>
					<div class="">
						<Tiptap @char="(e) => characterCount(e, 'persyaratanTeknis', 'Persyaratan Teknis')" v-model="projectRequirement.persyaratanTeknis" :error="errorProjectRequirement.value || errorProjectRequirement1.persyaratanTeknis.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.persyaratanTeknis.message }}</p>
							</template>
						</Tiptap>
					</div>
				</div>
			</div>
			<div class="w-full mt-1 mb-6">
				<div class="flex items-center justify-between w-full border rounded-lg"
					:class="`${errorProjectRequirement2.persyaratanTeknisFile?.value ? 'border-error' : 'border-primary'}`">
					<input type="file"
						id="upload-file2"
						ref="file2"
						hidden
						@change="chooseFile($event, 'file2', 'persyaratanTeknis')" />
					<div class="flex justify-between" style="width: calc(100% - 7rem);">
						<span class="px-4 text-sm truncate text-gray" :title="projectRequirement.rfpAttachments.find(e => e.fileRef === 'persyaratanTeknis' && !e.isDeleted)?.fileName">{{ projectRequirement.rfpAttachments.find(e => e.fileRef === 'persyaratanTeknis' && !e.isDeleted)?.fileName || 'No File Choosen' }}</span>
						<span v-if="projectRequirement.rfpAttachments.find(e => e.fileRef === 'persyaratanTeknis' && !e.isDeleted)?.fileName" class="pr-2 cursor-pointer icon-trash-2 text-error hover:text-error-dark" @click="removeFile('persyaratanTeknis')"></span>
					</div>
					<label v-if="!loadingfile2" for="upload-file2"
						class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
						Pilih File
					</label>
					<label v-else
						class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
						<div class="w-6 h-6 ease-linear border-4 border-t-4 rounded-full loader border-primary"></div>
					</label>
				</div>
				<div>
					<p class="mt-1 text-xs text-tertiary"
						:class="{ 'text-error': errorProjectRequirement2.persyaratanTeknisFile?.value }">
						Supported file: .pdf, max file size: 10 MB
					</p>
				</div>

			</div>
			<div class="flex justify-between w-full">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.arsitekturDanStandar.value) ? 'text-error' : 'text-gray'">
							Arsitektur dan Standar
						</label>
					</div>
					<div class="">
						<Tiptap @char="(e) => characterCount(e, 'arsitekturDanStandar', 'Arsitektur dan Standar')" v-model="projectRequirement.arsitekturDanStandar" :error="errorProjectRequirement.value || errorProjectRequirement1.arsitekturDanStandar.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.arsitekturDanStandar.message }}</p>
							</template>
						</Tiptap>
					</div>
				</div>
			</div>
			<div class="w-full mt-1 mb-6">
				<div class="flex items-center justify-between w-full border rounded-lg"
					:class="`${errorProjectRequirement2.arsitekturDanStandarFile?.value ? 'border-error' : 'border-primary'}`">
					<input type="file"
						id="upload-file3"
						ref="file3"
						hidden
						@change="chooseFile($event, 'file3', 'arsitekturDanStandar')" />
					<div class="flex justify-between" style="width: calc(100% - 7rem);">
						<span class="px-4 text-sm truncate text-gray" :title="projectRequirement.rfpAttachments.find(e => e.fileRef === 'arsitekturDanStandar' && !e.isDeleted)?.fileName">{{ projectRequirement.rfpAttachments.find(e => e.fileRef === 'arsitekturDanStandar' && !e.isDeleted)?.fileName || 'No File Choosen' }}</span>
						<span v-if="projectRequirement.rfpAttachments.find(e => e.fileRef === 'arsitekturDanStandar' && !e.isDeleted)?.fileName" class="pr-2 cursor-pointer icon-trash-2 text-error hover:text-error-dark" @click="removeFile('arsitekturDanStandar')"></span>
					</div>
					<label v-if="!loadingfile3" for="upload-file3"
						class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
						Pilih File
					</label>
					<label v-else
						class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
						<div class="w-6 h-6 ease-linear border-4 border-t-4 rounded-full loader border-primary"></div>
					</label>
				</div>
				<div>
					<p class="mt-1 text-xs text-tertiary"
						:class="{ 'text-error': errorProjectRequirement2.arsitekturDanStandarFile?.value }">
						Supported file: .pdf, max file size: 10 MB
					</p>
				</div>

			</div>
			<div class="flex justify-between w-full mb-6">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.implementasi.value) ? 'text-error' : 'text-gray'">
							Implementasi
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'implementasi', 'Implementasi')" v-model="projectRequirement.implementasi" :error="errorProjectRequirement.value || errorProjectRequirement1.implementasi.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.implementasi.message }}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
			<div class="flex justify-between w-full mb-6">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.pengembanganDanInterface.value) ? 'text-error' : 'text-gray'">
							Pengembangan Dan Interface
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'pengembanganDanInterface', 'Pengembangan Dan Interface')" v-model="projectRequirement.pengembanganDanInterface" :error="errorProjectRequirement.value || errorProjectRequirement1.pengembanganDanInterface.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.pengembanganDanInterface.message }}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
			<div class="flex justify-between w-full mb-6">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.projectStatusReporting.value) ? 'text-error' : 'text-gray'">
							Project Status Reporting
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'projectStatusReporting', 'Project Status Reporting')" v-model="projectRequirement.projectStatusReporting" :error="errorProjectRequirement.value || errorProjectRequirement1.projectStatusReporting.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.projectStatusReporting.message }}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
			<div class="flex justify-between w-full mb-6">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.sumberDayaYangTersedia.value) ? 'text-error' : 'text-gray'">
							Sumber Daya yang Tersedia
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'sumberDayaYangTersedia', 'Sumber Daya yang Tersedia')" v-model="projectRequirement.sumberDayaYangTersedia" :error="errorProjectRequirement.value || errorProjectRequirement1.sumberDayaYangTersedia.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.sumberDayaYangTersedia.message }}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
			<div class="flex justify-between w-full mb-6">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.serviceLevel.value) ? 'text-error' : 'text-gray'">
							Service Level
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'serviceLevel', 'Service Level')" v-model="projectRequirement.serviceLevel" :error="errorProjectRequirement.value || errorProjectRequirement1.serviceLevel.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.serviceLevel.message }}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
			<div class="flex justify-between w-full mb-6">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.pengamanTi.value) ? 'text-error' : 'text-gray'">
							Pengamanan TI
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'pengamanTi', 'Pengamanan TI')" v-model="projectRequirement.pengamanTi" :error="errorProjectRequirement.value || errorProjectRequirement1.pengamanTi.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.pengamanTi.message }}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
			<div class="flex justify-between w-full mb-6">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.dokumentasi.value) ? 'text-error' : 'text-gray'">
							Dokumentasi
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'dokumentasi', 'Dokumentasi')" v-model="projectRequirement.dokumentasi" :error="errorProjectRequirement.value || errorProjectRequirement1.dokumentasi.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.dokumentasi.message }}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
			<div class="flex justify-between w-full mb-6">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.pelatihan.value) ? 'text-error' : 'text-gray'">
							Pelatihan
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'pelatihan', 'Pelatihan')" v-model="projectRequirement.pelatihan" :error="errorProjectRequirement.value || errorProjectRequirement1.pelatihan.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.pelatihan.message }}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
			<div class="flex justify-between w-full mb-6">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.dukunganPemeliharaan.value) ? 'text-error' : 'text-gray'">
							Dukungan Pemeliharaan
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'dukunganPemeliharaan', 'Dukungan Pemeliharaan')" v-model="projectRequirement.dukunganPemeliharaan" :error="errorProjectRequirement.value || errorProjectRequirement1.dukunganPemeliharaan.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.dukunganPemeliharaan.message }}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
			<div class="flex justify-between w-full mb-6">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.acceptanceTest.value) ? 'text-error' : 'text-gray'">
							Acceptance Test
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'acceptanceTest', 'Acceptance Test')" v-model="projectRequirement.acceptanceTest" :error="errorProjectRequirement.value || errorProjectRequirement1.acceptanceTest.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.acceptanceTest.message }}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
			<div class="flex justify-between w-full mb-6">
				<div class="flex-wrap w-full">
					<div class="flex items-center">
						<label class="block mb-1 text-sm text-gray" :class="( errorProjectRequirement.value || errorProjectRequirement1.referensi.value) ? 'text-error' : 'text-gray'">
							Referensi
						</label>
					</div>
					<div class="">
						<TiptapNonTable @char="(e) => characterCount(e, 'referensi', 'Referensi')" v-model="projectRequirement.referensi" :error="errorProjectRequirement.value || errorProjectRequirement1.referensi.value">
							<template #message>
								<p>{{ errorProjectRequirement.message || errorProjectRequirement1.referensi.message }}</p>
							</template>
						</TiptapNonTable>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Tiptap from '@/core/components/custom/Tiptap.vue'
import TiptapNonTable from '@/core/components/custom/TiptapNonTable.vue'

export default {
	name: 'ProjectRequirement',
	props: ['loadingfile1', 'loadingfile2', 'loadingfile3'],
	computed: {
		collapse() {
			return this.$store.state.rfp.collapse
		},
		projectRequirement: {
			get() {
				return this.$store.state.rfp.projectRequirement
			},
			set(value) {
				this.$store.commit('rfp/SET_PROJECT_REQUIREMENT', value)
			},
		},
		errorProjectRequirement: {
			get() {
				return this.$store.state.errorRfp.errorProjectRequirement
			},
			set(value) {
				this.$store.commit('errorRfp/SET_ERROR_PROJECT_REQUIREMENT', value)
			}
		},
		errorProjectRequirement1: {
			get() {
				return this.$store.state.errorRfp.errorProjectRequirement1
			},
			set(value) {
				this.$store.commit('errorRfp/SET_ERROR_PROJECT_REQUIREMENT1', value)
			}
		},
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
		errorProjectRequirement2: {
			get() {
				return this.$store.state.errorRfp.errorProjectRequirement2
			},
			set(value) {
				this.$store.commit('errorRfp/SET_ERROR_PROJECT_REQUIREMENT2', value)
			}
		},
	},
	watch: {
		projectRequirement: {
			handler() {
				this.errorProjectRequirement.counter++
				if (this.errorProjectRequirement.counter === 1) {
					this.errorProjectRequirement = { value: false, message: '', counter: 1 }
				}
			},
			deep: true
		},
		
	},
	methods: {
		async chooseFile(e, key, fileRef) {
			const file = e.target.files[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
			if(checkFileValidation) {
				this.errorProjectRequirement2[fileRef+'File'] = { value: true, message: '' }
				setTimeout(() => {
					this.errorProjectRequirement2[fileRef+'File'] = { value: false, message: '' }
				}, 5000)
			} else {
				try {
					this.$emit('changeLoadingFile', { name: `loading${key}`, value: true })
					//hash file checksum md5
					const encrypted = await this.$store.dispatch('file/hashMD5',file)
					const formData = new FormData()
					formData.append('file', file)
					formData.append('fileRef', fileRef)
					//checksum encrypted md5
					formData.append('checksum', encrypted)
					const response = await this.$store.dispatch('file/uploadFile', formData)
					if (response?.status < 300 && response?.status >= 200) {
						const data = response.data.result
						const fileAfterUpload = {
							fileName: data.fileName,
							filePath: data.filePath,
							documentNo: data.documentNo,
							fileRef: fileRef,
							isDeleted: false,
							rfpAttachmentId: 0
						}
						this.setFileToState(fileAfterUpload, fileRef)
						this.$emit('changeLoadingFile', { name: `loading${key}`, value: false })
					} else {
						this.$store.dispatch('modal/throwError', { response })
					}
				} catch (error) {
					this.$emit('changeLoadingFile', { name: `loading${key}`, value: false })
					this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
				}
			}
			//reset the ref file input so we can choose file again
			this.$refs[key].value = ''
		},
		setFileToState(fileAfterUpload, fileRef) {
			const fileExist = this.projectRequirement.rfpAttachments?.find(e => e.fileRef === fileRef && !e.isDeleted)
			if (fileExist && fileExist?.rfpAttachmentId > 0) {
				this.projectRequirement.rfpAttachments = this.projectRequirement.rfpAttachments.map(e => (e.rfpAttachmentId === fileExist.rfpAttachmentId? ({ ...e, isDeleted: true }) : e) )
				this.projectRequirement.rfpAttachments = [...this.projectRequirement.rfpAttachments, fileAfterUpload]
			} else if (fileExist && fileExist?.rfpAttachmentId === 0) {
				this.projectRequirement.rfpAttachments = this.projectRequirement.rfpAttachments.filter(e => fileExist.filePath !== e.filePath)
				this.projectRequirement.rfpAttachments = [...this.projectRequirement.rfpAttachments, fileAfterUpload]
			} else {
				this.projectRequirement.rfpAttachments = [...this.projectRequirement.rfpAttachments, fileAfterUpload]
			}
		},
		inputUpdate(value, key) {
			this.projectRequirement[key] = value
		},
		changeCollapse() {
			this.$emit('collapse')
		},
		async removeFile(key) {
			try {
				this.modal.modalLoading = true
				const file = this.projectRequirement.rfpAttachments.find(e => e.fileRef === key && !e.isDeleted)
				if (file.rfpAttachmentId > 0) {
					this.projectRequirement.rfpAttachments = this.projectRequirement.rfpAttachments.map(e => (e.rfpAttachmentId === file.rfpAttachmentId? ({ ...e, isDeleted: true }) : e) )
				} else {
					const response = await this.$store.dispatch('file/deleteFile', file.documentNo || file.fileName)
					if (response?.status < 300 && response?.status >= 200) {
						this.projectRequirement.rfpAttachments = this.projectRequirement.rfpAttachments.filter(e => e.filePath !== file.filePath)
					} else {
						this.$store.dispatch('modal/throwError', { response })
					}
				}
				this.modal.modalLoading = false
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		characterCount(e, key, name) {
      this.$store.dispatch('errorRfp/checkErrorProjectRequirementLength', { key, name, totalChar: e, maxLength: 4500 })
    },
	},
	components: {
		Tiptap,
		TiptapNonTable,
	}
}
</script>

<style scoped>
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
