<template>
	<div class="w-full p-6 border-b border-gray-light">
		<div class="flex justify-between cursor-pointer"
			@click="changeCollapse">
			<div class="flex flex-col gap-4 sm:flex-row sm:gap-0">
				<div class="flex">
					<span class="mr-6 text-2xl icon-users text-gray-dark"></span>
					<h4 class="mr-6 text-base font-semibold text-gray-dark">VENDOR CANDIDATE <span v-if="!add" class="text-error">*</span></h4>
				</div>
				<h4 v-show="collapse.vendorCandidate" class="text-base font-semibold text-error">Minimal 1 Vendor</h4>
			</div>
			<div>
				<p :class="collapse.vendorCandidate ? 'rotate-180' : 'rotate-0'"
					class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
			</div>
		</div>
		<div v-show="collapse.vendorCandidate"
			class="mt-6">
			<div class="grid w-full grid-cols-1 gap-6 mb-6 lg:grid-cols-2">
				<Card v-for="(n, i) in vendorCandidate"
					:key="i"
					class="border border-gray-lightest">
					<div class="flex items-center mb-2.5">
						<span v-if="(!add && i !== 0) || (add && i+1 > totalVendorCandidate)"
							class="mr-6 text-2xl cursor-pointer icon-trash-2 text-error hover:text-error-dark"
							@click="removeVendor(i)"></span>
						<h5 class="truncate text-gray-dark">Vendor #{{ i + 1 }}</h5>
						<h4 v-if="!n.isActive" class="ml-3 text-base font-semibold text-error">Eliminated</h4>
					</div>
					<div class="flex flex-wrap md:flex-nowrap mb-1.5">
						<div class="flex flex-col w-full md:flex-row">
							<div class="w-full mb-2 mr-6 md:w-1/2">
								<AutocompleteSolutip placeholder="(Type & Search)"
									iconLeft="icon-search text-xl text-gray-light"
									@updateInput="inputSearch($event, i)"
									@updateAutoComplete="updateAutoComplete($event, i)"
									@focus="focusVendorName(i)"
									@blur="blurVendorName(i)"
									:options="vendorList"
									v-model="n.vendorName"
									label="Vendor Name"
									:mandatory="mandatoryVendor(i)"
									:isLoading="n.isLoading"
									:error="n?.error?.vendorName?.value || n?.error?.vendorNameDuplicate?.value"
									:disabled="add && i+1 <= totalVendorCandidate"
									textField="vendorName">
									<template #message>
										<p>{{ n?.error?.vendorNameDuplicate?.message || n?.error?.vendorName?.message }}</p>
									</template>
								</AutocompleteSolutip>
							</div>
							<div class="w-full md:w-1/2">
								<ValidationProvider name="vendorEmail"
									:vid="`vendorEmail${i}`"
									:rules="`email`"
									v-slot="{ errors }">
									<InputSolutip type="text"
										label="Email"
										:mandatory="mandatoryVendor(i)"
										size="large"
										v-model="n.vendorEmail"
										@input="inputEmail($event, i)"
										:error="!!errors[0] || n.error.vendorEmail?.value"
										:disabled="add && i+1 <= totalVendorCandidate"
										>
										<template #message>
											<p>{{ errors[0] || n.error.vendorEmail?.message}}</p>
										</template>
									</InputSolutip>
								</ValidationProvider>
							</div>
						</div>
					</div>
					<div class="w-full">
						<ValidationProvider name="Reason"
							:vid="`Reason${i}`"
							:rules="`max:300`"
							v-slot="{ errors }">
							<TextareaGista label="Reason"
								:mandatory="mandatoryVendor(i)"
								placeholder="Leave a comment here"
								v-model="n.reason"
								@input="inputReason($event, i)"
								:disabled="add && i+1 <= totalVendorCandidate"
								:error="!!errors[0] || n.error.reason?.value">
								<template #message>
									<p>{{ errors[0] || n.error.reason?.message }}</p>
								</template>
							</TextareaGista>
						</ValidationProvider>

					</div>
					<div v-for="(local, j) in n.localPartners" :key="j" class="pt-6 mt-6 border-t border-gray-lightest">
						<div class="flex items-center mb-2.5">
							<span v-if="!add || (add && j+1 > totalLocalPartner?.[i])" class="mr-6 text-2xl cursor-pointer icon-trash-2 text-error hover:text-error-dark" @click="removeLocalPartner(i, j)"></span>
							<h5 class="truncate text-gray-dark">Local Partner #{{ j + 1 }}</h5>
							<h4 v-if="!local.isActive" class="ml-3 text-base font-semibold text-error">Eliminated</h4>
						</div>
						<div class="flex flex-wrap md:flex-nowrap mb-1.5">
							<div class="flex flex-col w-full md:flex-row">
								<div class="w-full mb-2 mr-6 md:w-1/2">
									<AutocompleteSolutip placeholder="(Type & Search)"
										iconLeft="icon-search text-xl text-gray-light"
										@updateInput="inputSearchLocalPartner($event, i, j)"
										@updateAutoComplete="updateLocalPartner($event, i, j)"
										@focus="focusLocalPartnerVendorName(i, j)"
										@blur="blurLocalPartner(i, j)"
										:options="vendorList"
										v-model="local.vendorName"
										label="Vendor Name"
										:mandatory="mandatoryLocalPartner(i, j)"
										:error="local.error.vendorName?.value || local.error.vendorNameDuplicate?.value"
										:isLoading="local.isLoading"
										:disabled="add && j+1 <= totalLocalPartner?.[i]"
										textField="vendorName">
										<template #message>
											<p>{{ local.error.vendorName?.message || local.error.vendorNameDuplicate?.message }}</p>
										</template>
									</AutocompleteSolutip>

								</div>
								<div class="w-full md:w-1/2">
									<ValidationProvider name="Local Partner Vendor Email"
										:vid="`Local Partner Vendor Email${i}${j}`"
										:rules="`email`"
										v-slot="{ errors }">
										<InputSolutip type="text"
											label="Email"
											size="large"
											v-model="local.vendorEmail"
											:mandatory="mandatoryLocalPartner(i, j)"
											@input="inputLocalPartnerEmail($event, i, j)"
											:error="!!errors[0] ||local.error.vendorEmail?.value"
											:disabled="add && j+1 <= totalLocalPartner?.[i]"
											>
											<template #message>
												<p>{{ errors[0] ||local.error.vendorEmail?.message }}</p>
											</template>
										</InputSolutip>
									</ValidationProvider>

								</div>
							</div>
						</div>
						<div class="w-full">
							<ValidationProvider name="Local Partner Reason"
								:vid="`Local Partner Reason${i}${j}`"
								:rules="`max:300`"
								v-slot="{ errors }">
								<TextareaGista label="Reason"
									placeholder="Leave a comment here"
									@input="inputLocalPartnerReason($event, i, j)"
									v-model="local.reason"
									:mandatory="mandatoryLocalPartner(i, j)"
									:disabled="add && j+1 <= totalLocalPartner?.[i]"
									:error="!!errors[0] || local.error.reason?.value">
									<template #message>
										<p>{{ errors[0] || local.error.reason?.message }}</p>
									</template>
								</TextareaGista>
							</ValidationProvider>

						</div>
					</div>
					<div v-if="(!add && n.localPartners?.length < 2) || (add && n.localPartners?.length < 2 && n.isActive === true)" class="flex justify-center pt-6 mt-6 border-t border-gray-lightest">
						<div class="flex items-center cursor-pointer" @click="addLocalPartner(i)">
							<span class="mr-3 icon-plus-square text-tertiary"></span>
							<p class="font-medium underline text-tertiary">Add Local Partner</p>
						</div>
					</div>
				</Card>

			</div>
			<div class="flex justify-center w-full">
				<ButtonGista type="primary"
					color="tertiary"
					@click="addVendorCandidate">
					Add Vendor Candidate
				</ButtonGista>
			</div>
		</div>
	</div>
</template>

<script>
import Card from '@/core/components/custom/Card.vue'
import AutocompleteSolutip from '@/core/components/custom/Autocomplete.vue'
import InputSolutip from '@/core/components/custom/Input.vue'
import { MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'

export default {
	name: 'VendorCandidate',
	props: ['add', 'totalLocalPartner', 'totalVendorCandidate'],
	data() {
		return {
		}
	},
	computed: {
		collapse() {
			return this.$store.state.rfp.collapse
		},
		vendorList: {
			get() {
				return this.$store.state.rfp.vendorList
			},
			set(value) {
				this.$store.commit('rfp/SET_VENDOR_LIST', value)
			}
		},
		vendorListAll() {
			return this.$store.state.vendor.vendorListAll
		},
		vendor() {
			return this.$store.state.rfi.vendor
		},
		vendorCandidate: {
			get() {
				return this.$store.state.rfp.vendorCandidate
			},
			set(value) {
				this.$store.commit('rfp/SET_VENDOR_CANDIDATE', value)
			}
		}
	},
	methods: {
		mandatoryVendor(i) {
			const add = this.add
			return !add || (add && i+1 > this.totalVendorCandidate)
		},
		mandatoryLocalPartner(i, j) {
			const add = this.add
			return !add || (add && j+1 > this.totalLocalPartner?.[i])
		},
		addVendorCandidate() {
			this.vendorCandidate = [...this.vendorCandidate, {
				rfpVendorCandidateId: null,
				vendorId: 0,
				vendorName: '',
				vendorEmail: '',
				isNewVendor: true,
				reason: '',
				isActive: true,
				isApprove: true,
				isPerluPoc: true,
				isDeleted: false,
				disqualifiedReason: '',
				disabled: false,
				isLoading: false,
				error: {
					vendorName: { value: false, message: '' },
					vendorEmail: { value: false, message: '' },
					reason: { value: false, message: '' },
					vendorNameDuplicate: { value: false, message: '' },
				},
				localPartners: [],
			}]
		},
		addLocalPartner(i) {
			const localPartnerNew = {
				rfpVendorCandidateId: null,
				vendorId: 0,
				isNewVendor: true,
				vendorName: '',
				vendorEmail: '',
				isActive: true,
				reason: '',
				isLoading: false,
				error: {
					vendorName: { value: false, message: '' },
					vendorEmail: { value: false, message: '' },
					reason: { value: false, message: '' },
					vendorNameDuplicate: { value: false, message: '' },
				},
			}
			this.vendorCandidate[i].localPartners = [
				...this.vendorCandidate[i].localPartners, 
				localPartnerNew
			]
		},
		removeVendor(index) {
			this.vendorCandidate = this.vendorCandidate.filter((e, i) => i !== index)
			this.$store.dispatch('errorRfp/checkErrorVendorCandidateVendorName')

		},
		removeLocalPartner(i, j) {
			this.vendorCandidate[i].localPartners = this.vendorCandidate[i].localPartners.filter((_,index) => index !== j)
			this.$store.dispatch('errorRfp/checkErrorVendorCandidateVendorName')
		},
		updateAutoComplete(e, index) {
			if (e) {
				this.vendorCandidate = this.vendorCandidate.map((el, i) => {
					if (index === i) {
						return {
							rfpVendorCandidateId: el.rfpVendorCandidateId,
							vendorId: e.vendorId,
							vendorName: e.vendorName,
							vendorEmail: e.vendorEmail,
							isNewVendor: e.isNewVendor,
							reason: el.reason,
							isActive: el.isActive,
							isApprove: el.isApprove,
							isPerluPoc: el.isPerluPoc,
							isDeleted: el.isDeleted,
							disqualifiedReason: el.disqualifiedReason,
							disabled: false,
							isLoading: false,
							error: el.error,
							localPartners: el.localPartners,
						}
					}
					else return el
				})
			}
			this.vendorCandidate[index].error.vendorEmail = { value: false, message: '' }
			this.vendorCandidate[index].error.vendorName = { value: false, message: '' }
			this.$store.dispatch('errorRfp/checkErrorVendorCandidateVendorName')

		},
		updateLocalPartner(e, indexI, indexJ) {
			if (e) {
				this.vendorCandidate = this.vendorCandidate.map((el, i) => {
					return {
						...el,
						localPartners: el.localPartners.map((ele, j) => {
							if (indexJ === j && indexI === i) {
								return {
									rfpVendorCandidateId: ele.rfpVendorCandidateId,
									vendorId: e.vendorId,
									isNewVendor: e.isNewVendor,
									vendorName: e.vendorName,
									vendorEmail: e.vendorEmail,
									isActive: ele.isActive,
									reason: ele.reason,
									isLoading: ele.isLoading,
									error: ele.error,
								}
							} else return ele
						})
					}
				})
			}
			this.vendorCandidate[indexI].localPartners[indexJ].error.vendorEmail = { value: false, message: '' }
			this.vendorCandidate[indexI].localPartners[indexJ].error.vendorName = { value: false, message: '' }
			this.$store.dispatch('errorRfp/checkErrorVendorCandidateVendorName')
		},
		async inputSearch(e, i) {
			this.vendorCandidate[i].vendorId = ''
			this.vendorCandidate[i].isNewVendor = true
			this.vendorCandidate[i].isLoading = true
			await this.$store.dispatch('rfp/getVendor', { vendorName: e })
			this.vendorCandidate[i].isLoading = false
		},
		async inputSearchLocalPartner(e, i, j) {
			this.vendorCandidate[i].localPartners[j].vendorId = ''
			this.vendorCandidate[i].localPartners[j].isNewVendor = true
			this.vendorCandidate[i].localPartners[j].isLoading = true
			await this.$store.dispatch('rfp/getVendor', { vendorName: e })
			this.vendorCandidate[i].localPartners[j].isLoading = false
		},
		async focusVendorName(i) {
			this.vendorCandidate[i].isLoading = true
			await this.$store.dispatch('rfp/getVendor', { vendorName: this.vendorCandidate[i].vendorName })
			this.vendorCandidate[i].isLoading = false
		},
		async focusLocalPartnerVendorName(i, j) {
			this.vendorCandidate[i].localPartners[j].isLoading = true
			await this.$store.dispatch('rfp/getVendor', { vendorName: this.vendorCandidate[i].localPartners[j].vendorName })
			this.vendorCandidate[i].localPartners[j].isLoading = false
		},
		blurVendorName(i) {
			this.vendorCandidate[i].vendorName = this.vendorCandidate[i].vendorName.trim()
			const checkFilled = this.checkFilled(i)
			const vendor = this.vendorCandidate[i]
			if (!vendor.vendorName && checkFilled) {
				vendor.error.vendorName = { value: true, message: MESSAGE_EMPTY_FIELD }
			} else if(!checkFilled) {
				this.setEmptyError(i)
			} else vendor.error.vendorName = { value: false, message: '' }
			this.$store.dispatch('errorRfp/checkErrorVendorCandidateVendorName')
		},
		blurLocalPartner(i, j) {
			const checkFilled = this.checkFilled(i)
			this.vendorCandidate[i].localPartners[j].vendorName = this.vendorCandidate[i].localPartners[j].vendorName.trim()
			const vendor = this.vendorCandidate[i].localPartners[j]
			if (!vendor.vendorName && checkFilled) {
				vendor.error.vendorName = { value: true, message: MESSAGE_EMPTY_FIELD }
			} else if(!checkFilled) {
				this.setEmptyError(i)
			} else vendor.error.vendorName = { value: false, message: '' }
			this.$store.dispatch('errorRfp/checkErrorVendorCandidateVendorName')
		},
		inputReason(e, i) {
			const checkFilled = this.checkFilled(i)
			if (!e && checkFilled) {
				this.vendorCandidate[i].error.reason = { value: true, message: MESSAGE_EMPTY_FIELD }
			} else if(!checkFilled) {
				this.setEmptyError(i)
			} else {
				this.vendorCandidate[i].error.reason = { value: false, message: '' }
			}
		},
		inputEmail(e, i) {
			const checkFilled = this.checkFilled(i)
			if (!e && checkFilled) {
				this.vendorCandidate[i].error.vendorEmail = { value: true, message: MESSAGE_EMPTY_FIELD }
			} else if(!checkFilled) {
				this.setEmptyError(i)
			} else {
				this.vendorCandidate[i].error.vendorEmail = { value: false, message: '' }
			}
		},
		checkFilled(i) {
			const vendor = this.vendorCandidate[i]
			const filledVendor = Boolean(vendor.vendorName || vendor.vendorEmail || vendor.reason)
			const filledLocalPartner = vendor.localPartners?.some(el => (el.vendorName || el.vendorEmail || el.reason))
			return filledLocalPartner || filledVendor
		},
		setEmptyError(i) {
			const vendor = this.vendorCandidate[i]
			if(vendor.localPartners?.length > 0) {
				vendor.localPartners.forEach( el => {
					el.error.vendorEmail = { value: false, message: '' }
					el.error.vendorName = { value: false, message: '' }
					el.error.reason = { value: false, message: '' }
				})
			}

			vendor.error.vendorEmail = { value: false, message: '' }
			vendor.error.vendorName = { value: false, message: '' }
			vendor.error.reason = { value: false, message: '' }
		},
		inputLocalPartnerEmail(e, i, j) {
			const checkFilled = this.checkFilled(i, j)
			if (!e && checkFilled) {
				this.vendorCandidate[i].localPartners[j].error.vendorEmail = { value: true, message: MESSAGE_EMPTY_FIELD }
			} else if(!checkFilled) {
				this.setEmptyError(i)
			} else this.vendorCandidate[i].localPartners[j].error.vendorEmail = { value: false, message: '' }
		},
		inputLocalPartnerReason(e, i, j) {
			const checkFilled = this.checkFilled(i, j)
			if (!e && checkFilled) {
				this.vendorCandidate[i].localPartners[j].error.reason = { value: true, message: MESSAGE_EMPTY_FIELD }
			} else if(!checkFilled) {
				this.setEmptyError(i)
			} else this.vendorCandidate[i].localPartners[j].error.reason = { value: false, message: '' }
		},
		changeCollapse() {
			this.$emit("collapse")
		}
	},
	components: {
		Card,
		AutocompleteSolutip,
		InputSolutip
	}
}
</script>
