<template>
	<div class="w-full">
		<div class="">
			<div class="flex justify-center">
				<h3 class="font-semibold text-xl p-6">Vendor details</h3>
			</div>
			<div class="flex justify-center w-full border-b border-gray-lightest">
					<div class="flex overflow-x-auto">
						<h4 class="px-6 py-3 text-center text-base cursor-not-allowed"
							:class="{ 'border-b-2 border-primary text-gray': activePage === 'evaluation', 'text-gray-light': activePage !== 'evaluation' }"
							>Evaluation</h4>
						<h4 class="px-6 py-3 text-center text-base cursor-pointer"
							:class="{ 'border-b-2 border-primary text-gray': activePage === 'documents', 'text-gray-light': activePage !== 'documents' }"
							@click="changeActivePage('documents')">Documents</h4>
						<h4 class="px-6 py-3 text-center text-base cursor-pointer"
							:class="{ 'border-b-2 border-primary text-gray': activePage === 'projects', 'text-gray-light': activePage !== 'projects' }"
							@click="changeActivePage('projects')">Projects ({{totalProjects}})</h4>
					</div>
				</div>
			<Document v-if="activePage === 'documents'" :vendor="vendor" :projectId="projectId" @closeModal="closeModal" />
			<div v-if="activePage === 'projects'">
				<Projects :vendor="vendor" @closeModal="closeModal" />
				<Eliminated @closeModal="closeModal" />
			</div>
		</div>
	</div>
</template>
<script>
import Document from '@/core/components/vendors/Document.vue';
import Projects from './Projects.vue';
import Eliminated from './Eliminated.vue';

export default {
	name: "VendorDetail",
	props: {
		vendor: Object,
		projectId: String,
	},
	data() {
		return {
			activePage: 'documents',
		}

	},
	computed: {
		totalProjects() {
			const totalProjectSelected = this.$store.state.vendor.vendorDetailProjectSelected.length
			const totalProjectEliminated = this.$store.state.vendor.vendorDetailProjectEliminated.length
			return totalProjectSelected + totalProjectEliminated
		},
		menuVendorDetailsProjects() {
			return this.$store.getters['dashboard/menuVendorDetailsProjects']
		}
	},
	methods: {
		changeActivePage(e) {
			this.activePage = e
		},
		closeModal() {
			this.$emit('closeModal')
		},
	},
	mounted() {
		this.$store.dispatch('vendor/getVendorDetailProjectEliminated', { vendorId: this.vendor.vendorId })

	},
	components: {
    Document,
    Projects,
    Eliminated,
    // Evaluation
}
}
</script>