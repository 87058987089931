<template>
  <div class="w-full mt-6">
    <div v-if="!isLoading">
      <table-approver-detail></table-approver-detail>
      <proposal-information-detail @collapse="changeCollapse($event, 'proposalInformation')"/>
      <project-information-detail @collapse="changeCollapse($event, 'projectInformation')"/>
      <project-requirement-detail @collapse="changeCollapse($event, 'projectRequirement')" />
      <vendor-candidate-detail @collapse="changeCollapse($event, 'vendorCandidate')" />
      <scoring-information-detail @collapse="changeCollapse($event, 'scoringTeknis')"/>
    </div>
    <DetailLoading v-else/>
  </div>
</template>
<script>
import ProposalInformationDetail from '@/core/components/rfp/detail/ProposalInformation.vue'
import ProjectInformationDetail from '@/core/components/rfp/detail/ProjectInformation.vue'
import ProjectRequirementDetail from '@/core/components/rfp/detail/ProjectRequirement.vue'
import VendorCandidateDetail from '@/core/components/rfp/detail/VendorCandidate.vue'
import ScoringInformationDetail from '@/core/components/rfp/detail/ScoringInformation.vue'
import DetailLoading from '@/core/components/rfp/loading/DetailLoading.vue'
import TableApproverDetail from '@/core/components/rfp/TableApproverDetail.vue'
export default {
	name: 'Detail',
  data() {
    return {
      isLoading: true,
    }
  },
  
  computed: {
    collapse: {
      get() {
        return this.$store.state.projectDetail.collapse
      },
      set(value) {
        this.$store.commit('projectDetail/SET_COLLAPSE', value)
      }
    },  
    loading: {
			get() {
				return this.$store.state.modal.loading
			},
			set(value) {
				this.$store.commit('modal/SET_LOADING', value)
			}
		},  
    canAccess() {
			return this.$store.state.projectLog.canAccess
    },
    projectLogRfp() {
			return this.$store.state.projectLog.projectLogRfp
    },
    someInProgress() {
      return this.projectLogRfp?.rfpLogs.some(e => e.isInProgress || e.additionalLogs?.some(el => el.isInProgress))
    }
	},
  methods: {
    changeCollapse(e, key) {
      this.collapse[key] = !this.collapse[key]
    },
  },
  async mounted() {
    this.isLoading = true
    this.loading.loadingProjectLog = true
    const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: this.$route.params.projectId, projectRequestType: 'rfp' })
    if (res?.data.code !== 200) return
    const r1 = this.$store.dispatch('projectDetail/getProposalInformation', this.$route.params.projectId)
    const r2 = this.$store.dispatch('projectDetail/getProjectInformation', this.$route.params.projectId)
    const r3 = this.$store.dispatch('projectDetail/getProjectRequirement', this.$route.params.projectId)
    const r4 = this.$store.dispatch('projectDetail/getProjectVendorCandidate', this.$route.params.projectId)
    const r5 = this.$store.dispatch('projectDetail/getProjectScoringInformation', this.$route.params.projectId)
    const r6 = this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
    const r7 = this.$store.dispatch('projectLog/getProjectLogByRfpId', { rfpId: this.$route.params.projectId })
    const r8 = this.$store.dispatch('projectDetail/getLogApproval', { rfpId: this.$route.params.projectId })
    const [res1, res2, res3, res4, res5, res6, res7, res8] = await Promise.all([ r1, r2, r3, r4, r5, r6, r7, r8])
    await this.$store.dispatch('spp/getSpp', { rfpId: this.$route.params.projectId })
    if (res1?.status === 200 && res2?.status === 200 && res3?.status === 200 && res4?.status === 200 && res5?.status === 200 && res6?.status === 200 && res8?.status === 200) {
      this.isLoading = false
    }
    if (res7?.status === 200) {
      this.loading.loadingProjectLog = false
      if (this.someInProgress) {
        this.$store.dispatch('projectLog/refetchProjectLogByRfpId', this.$route.params.projectId)
      }
    }
  },
  components: {
    ProposalInformationDetail,
    ProjectInformationDetail,
    ProjectRequirementDetail,
    VendorCandidateDetail,
    ScoringInformationDetail,
    DetailLoading,
    TableApproverDetail
  },
}
</script>