<template>
  <div class="flex flex-col">
    <p
      class="flex items-center text-sm text-gray truncate font-medium"
      :class="{ 'text-error': error, 'mb-1': label }"
    >
      {{ label }} <span v-if="mandatory" class="text-error">*</span>
    </p>
    <div class="relative text-sm h-full">
      <div
        class="flex items-center justify-between px-3 py-2 w-full text-lg rounded-md border cursor-pointer"
        :class="[
          {
            'bg-gray-lightest text-gray-light cursor-not-allowed': disabled,
            'text-gray-lightest': disabled,
            'bg-white border-primary focus:shadow-secondary-round focus:border-secondary':
              !disabled && !noBorder,
            'border-error': error,
            'hover:shadow-primary-sm': !noBorder && !disabled,
            'text-small h-11': size == 'small',
            'text-base h-12': size == 'medium',
            'text-base h-14': size == 'large',
          },
          customClass,
        ]"
        @click="
          disabled
            ? (isOptionsExpanded = false)
            : (isOptionsExpanded = !isOptionsExpanded)
        "
      >
        <div
          class="absolute left-0 inset-y-0 pl-3 flex items-center pointer-events-none"
          v-if="iconLeft"
        >
          <span :class="iconLeft"></span>
        </div>
        <span
          class="text-base"
          :class="{
            'pl-6': iconLeft,
            'cursor-not-allowed text-gray-light': disabled,
            'text-gray-light':
              (typeof value === 'string' && value === '') ||
              (typeof value === 'object' && value && value.value === '') ||
              value === undefined,
            'text-gray': value !== '' && !disabled,
          }"
        >
          <slot name="selected" :slot-props="selected">{{
            value
              ? typeof value === "object"
                ? value.text
                : value
              : selected
              ? typeof selected === "object"
                ? selected.text
                : selected
              : placeholder
          }}</slot>
        </span>
        <div class="flex items-center">
          <div
            v-if="value && clearable"
            class="mr-2 icon-x z-10"
            @click.stop="clear"
          ></div>
          <svg
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            class="h-4 w-4 transform transition-transform duration-200 ease-in-out"
            :class="!readOnly && isOptionsExpanded ? 'rotate-180' : 'rotate-0'"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </div>
      </div>
      <transition
        enter-active-class="transform transition duration-500 ease-custom"
        enter-class="-translate-y-1/2 scale-y-0 opacity-0"
        enter-to-class="translate-y-0 scale-y-100 opacity-100"
        leave-active-class="transform transition duration-300 ease-custom"
        leave-class="translate-y-0 scale-y-100 opacity-100"
        leave-to-class="-translate-y-1/2 scale-y-0 opacity-0"
      >
        <ul
          v-show="!readOnly && isOptionsExpanded"
          class="absolute left-0 right-0 mb-4 rounded-md overflow-auto max-h-52 bg-white z-50 shadow-primary-sm"
        >
          <li
            v-for="(v, i) in options"
            :key="i"
            class="px-3 py-2 transition-colors duration-100 text-gray hover:bg-secondary hover:text-white"
            :value="typeof v === 'object' && v ? v.value : v"
            :label="label"
            :class="isActive(v) ? 'bg-secondary-light text-white' : 'text-gray'"
            @click="setOption(v)"
          >
            <slot name="option" :slot-props="v">{{
              typeof v === "object" && v ? v.text : v
            }}</slot>
          </li>
        </ul>
      </transition>
      <div class="text-xs text-gray mt-1" v-if="hint && !dense">
        {{ hint }}
      </div>
      <div
        v-if="this.$slots['message'] != undefined"
        class="text-xs"
        :class="[{ 'text-error': error }]"
      >
        <slot name="message"></slot>
      </div>
    </div>
  </div>
</template>

<script>
/*
  <select-gista
    v-validate="'required'"
    data-vv-as="Jenis"
    :options="arrJenis"
    v-model="form.jenis"
    label="Jenis*"
    :error="errors.has('jenis')"
  >
    <error-message
      class="relative"
      :class="{ 'text-error': errors.has('jenis') }"
      >{{ errors.first("jenis") }}</error-message
    >
  </select-gista>
*/

export default {
  props: {
    size: {
      type: String,
      default: "medium",
    },
    disabled: Boolean,
    noBorder: {
      type: Boolean,
      default: false,
    },
    error: {
      // error flag
      type: Boolean,
      default: false,
    },
    dense: {
      // if true then theres no space for hint
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    label: String,
    iconLeft: String,
    hint: String,
    placeholder: {
      type: String,
      default: "Select",
    },
    options: Array, // option items
    value: {
      // for v-model
      type: [Object, String],
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: () => "",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
  },
  name: "SelectSolutip",
  data() {
    return {
      isOptionsExpanded: false,
      selected: "",
    };
  },
  watch: {
    value: {
      handler(v) {
        this.selected = v;
      },
      immediate: true,
    },
    isOptionsExpanded() {
      this.$emit('isOptionsExpanded', this.isOptionsExpanded)
    }
  },
  methods: {
    setOption(v) {
      this.isOptionsExpanded = false;
      // this.value = typeof v === "object" ? v.value : v;
      // this.selected = typeof v === "object" ? v.text : v;
      this.selected = v;
      this.$emit("change", v);
      this.$emit("input", v);
    },

    isActive(option) {
      if (option === this.value) return true;
      return false;
    },
    clear() {
      this.$emit("change", typeof this.selected === "object" ? null : "");
      this.$emit("input", typeof this.selected === "object" ? null : "");
    },
    toggleOptions() {
      this.disabled
        ? (this.isOptionsExpanded = false)
        : (this.isOptionsExpanded = !this.isOptionsExpanded);
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target)) {
        this.isOptionsExpanded = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.handleClickOutside);
  }
};
</script>

<style>
.ease-custom {
  transition-timing-function: cubic-bezier(0.61, -0.53, 0.43, 1.43);
}
</style>
