<template>
  <div class="flex">
    <radio-gista :name="id" :modelValue="computedModelValue === '1'? '1': ''" nativeValue="1" @change="changeInput" class="mr-2.5">1</radio-gista>
    <radio-gista :name="id" :modelValue="computedModelValue === '2'? '2': ''" nativeValue="2"  @change="changeInput" class="mr-2.5">2</radio-gista>
    <radio-gista :name="id" :modelValue="computedModelValue === '3'? '3': ''" nativeValue="3"  @change="changeInput" >3</radio-gista>
  </div>
</template>
<script>
export default {
  name: "RadioButtonEscrow",
  props: ['modelValue', 'id'],
  data() {
    return {
      valueSelected: ''
    }
  },
  computed: {
    computedModelValue: {
      get() {
        return this.modelValue
      },
      set(val) {
        this.$emit('change', val)
      }
    }
  },
  methods: {
    changeInput(e) {
      this.computedModelValue = e
      this.valueSelected = e
      this.$emit('change', this.valueSelected)
    }
  }
}
</script>