<template>
	<div class="w-full mt-6">
		<div class="flex items-center mb-6">
			<span class="icon-list text-base text-gray mr-6"></span>
			<h4 class="text-base text-gray font-semibold">QUOTATION INFORMATION</h4>
		</div>
		<div class="grid gap-3 grid-cols-1 md:grid-cols-2 mb-6  w-full">
			<div class="border border-gray-lightest p-2.5 rounded-xl items-start w-full">
				<label class="text-sm text-gray font-medium">New Project?</label>
				<h5 class="text-gray-dark font-semibold">{{ quotationInformation.isNewProject ? 'Yes' : 'No' }}</h5>
			</div>
			<div class="border border-gray-lightest p-2.5 rounded-xl items-start w-full">
				<label class="text-sm text-gray font-medium">Project ID</label>
				<h5 class="text-gray-dark font-semibold break-words">{{ quotationInformation.projectId }}</h5>
			</div>
			<div v-if=" (quotationInformation.filterLocalPartnerVendorId === null || quotationInformation.filterLocalPartnerVendorId === '') && quotationInformation.vendorName != ''"
			class="border border-gray-lightest p-2.5 rounded-xl items-start w-full">
				<label class="text-sm text-gray font-medium">Vendor Name</label>
				<h5 class="text-gray-dark font-semibold break-words">{{ quotationInformation.vendorName }}</h5>
			</div>
			<div v-if=" (quotationInformation.filterLocalPartnerVendorId != '' && quotationInformation.filterLocalPartnerVendorId !== null) && !quotationInformation.isNewProject"
			 class="border border-gray-lightest p-2.5 rounded-xl items-start w-full"
			>
				<label class="text-sm text-gray font-medium">Local Partner</label>
				<h5 class="text-gray-dark font-semibold break-words">{{ quotationInformation.vendorName  }}</h5>
			</div>
			<div class="border border-gray-lightest p-2.5 rounded-xl items-start w-full">
				<label class="text-sm text-gray font-medium">Vendor Email</label>
				<h5 class="text-gray-dark font-semibold break-words">{{ quotationInformation.vendorEmail }}</h5>
			</div>

		</div>

		<div class="border border-gray-lightest p-2.5 rounded-xl items-start mb-4 w-full">
			<label class="text-sm text-gray font-medium">Project Name</label>
			<h5 class="text-gray-dark font-semibold break-words">{{ quotationInformation.projectName }}</h5>
		</div>

		<div class="grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-6  w-full">

			<div class="border border-gray-lightest p-2.5 rounded-xl items-start w-full">
				<label class="text-sm text-gray font-medium">No RFQ</label>
				<h5 class="text-gray-dark font-semibold break-words">{{ quotationInformation.noRfq }}</h5>
			</div>
			<div class="border border-gray-lightest p-2.5 rounded-xl items-start w-full">
				<label class="text-sm text-gray font-medium">Year</label>
				<h5 class="text-gray-dark font-semibold break-words">{{ quotationInformation.tahun }}</h5>
			</div>
			<div class="border border-gray-lightest p-2.5 rounded-xl items-start w-full">
				<label class="text-sm text-gray font-medium">Budget Spent (IDR)</label>
				<h5 class="text-gray-dark font-semibold break-words">{{ quotationInformation.budgetSpent }}</h5>
			</div>
			<div class="border border-gray-lightest p-2.5 rounded-xl items-start w-full">
				<label class="text-sm text-gray font-medium">Kode WBS Level 1</label>
				<h5 class="text-gray-dark font-semibold break-words">{{ quotationInformation.kodeWbsLev1 }}</h5>
			</div>

		</div>
		<div class="flex border border-gray-lightest p-2.5 rounded-xl mb-4 w-full" :class="quotationDetailContainerWidth < 640? 'flex-col' : 'flex-row'">
			<div class="w-1/2 mr-3">
				<label class="text-xs text-gray font-medium truncate">Technical Coordinator</label>
				<h5 class="text-gray-dark text-base truncate">{{ quotationInformation.technicalCoordinatorName }}</h5>
			</div>
			<div class="w-1/2 border-0 border-gray-lightest " :class="{ 'border-l pl-2': quotationDetailContainerWidth > 640 }">
				<label class="text-xs text-gray font-medium truncate">Email</label>
				<h5 class="text-gray-dark text-base truncate">{{ quotationInformation.technicalCoordinatorEmail }}</h5>
			</div>

		</div>
		<div class="border border-gray-lightest p-2.5 rounded-xl items-start mb-4 w-full">
			<label class="text-sm text-gray font-medium">Request Title</label>
			<h5 class="text-gray-dark break-words">{{ quotationInformation.judulRfq }}</h5>
		</div>
		<div class="border border-gray-lightest p-2.5 rounded-xl items-start mb-4 w-full">
			<label class="text-sm text-gray font-medium">Notes</label>
			<h5 class="text-gray-dark break-words">{{ quotationInformation.notes }}</h5>
		</div>
	</div>
</template>

<script>
export default {
	name: "QuotationInformation",
	computed: {
		quotationInformation() {
			return this.$store.state.quotationDetail.quotationInformation
		},
		userProfile() {
			return this.$store.state.dashboard.userProfile
		},
		quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
	},
	methods: {}
}
</script>