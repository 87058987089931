<template>
	<div class="">
		<ButtonGista v-if="menuQualificationActionRevision?.canUpdate && roleUser" @click="toggleOpen"
			color="error"
			:disabled="proposalInformation?.isCanceled"
			customClass="w-full">
			Reject
		</ButtonGista>
		<GistaModal 
      :value="isOpen"
			width="2/3"
			scrollable
			@input="toggleClose"
			customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl"
      >
			<ValidationObserver v-slot="{ handleSubmit }">
				<div v-if="roleUser && menuQualificationActionRevision?.canUpdate" class="p-6">
					<div class="flex justify-center">
						<h4 class="mb-6 text-3xl font-semibold">Reject Proposal</h4>
					</div>
					<div class="grid w-full grid-cols-1 gap-6 mb-4 lg:grid-cols-2">
						<InputGista type="text"
							disabled
							label="Technical Coordinator"
							:value="qualificationsRevisionTech?.technicalCoordinatorName" />
						<InputGista type="text"
							label="Email"
							disabled
							:value="qualificationsRevisionTech?.technicalCoordinatorEmail" />
						<InputGista type="text"
							label="Telepon"
							disabled
							:value="qualificationsRevisionTech?.technicalCoordinatorOfficePhone" />
						<InputGista type="text"
							label="Ext"
							disabled
							:value="qualificationsRevisionTech?.ext? String(qualificationsRevisionTech?.ext) : ''" />
						<InputGista type="text"
							label="Vendor Name"
							disabled
							:value="qualification.vendorName" />
						<InputGista type="text"
							label="Vendor Email"
							disabled
							:value="qualification.vendorEmail" />
					</div>
					<div class="w-full mb-4">
						<ValidationProvider name="Message"
							rules="required|max:300"
							v-slot="{ errors }">
							<TextareaGista label="Message"
								v-model="formUser.revisionMessage"
								:mandatory="true"
								:error="!!errors[0]">
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</TextareaGista>
						</ValidationProvider>
					</div>
	
					<div class="flex justify-end">
	
						<ButtonGista @click="handleSubmit(clickRejectProposal)"
							color="error"
							customClass="w-full sm:w-60">
							Reject Proposal</ButtonGista>
	
					</div>
				</div>
			</ValidationObserver>
			
		</GistaModal>
	</div>
</template>
<script>
//proposal revision => proposal rejection
import { LOG, OPR } from '@/core/constant/applicationRoleName.js'
import { MESSAGE_SUCCESS_REJECT_PROPOSAL } from '@/core/constant/successMessage'

export default {
	name: 'ProposalRevision',
	props: ['qualification', 'index'],
	data() {
		return {
			isOpen: false,
			formUser: {
				revisionMessage: '',
				vendorCandidateId: ''
			}
		}
	},
	computed: {
		menu() {
			return this.$store.state.dashboard.menu
		},
		roleUser() {
			const roleName = this.menu[0]?.applicationRoleName
			return roleName?.includes(OPR) && !roleName?.includes(LOG)
		},
		userProfile() {
			return this.$store.state.dashboard.userProfile
		},
		proposalInformation() {
			return this.$store.state.projectDetail.proposalInformation
		},
		qualificationsRevision() {
			return this.$store.state.qualification.qualificationsRevision
		},
		qualificationsRevisionTech() {
			return this.$store.state.qualification.qualificationsRevisionTech
		},
		menuQualificationActionRevision() {
			return this.$store.getters['dashboard/menuQualificationActionRevision']
		},
		menuQualificationActionAskRevision() {
			return this.$store.getters['dashboard/menuQualificationActionAskRevision']
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
	},
	methods: {
		toggleOpen() {
			this.isOpen = !this.isOpen
		},
		toggleClose() {
			this.isOpen = !this.isOpen
			this.formUser = {
				revisionMessage: '',
				vendorCandidateId: this.formUser.vendorCandidateId
			}
		},
		async clickRejectProposal() {
			try {
				this.modal.modalLoading = true
				const formUser = {
					revisionMessage: this.formUser.revisionMessage,
					vendorCandidateId: this.qualification?.vendorCandidateId
				}
				const response = await this.$store.dispatch('qualification/putQualificationsProposalRevisionTech', formUser)
				if (response?.status < 300 && response?.status >= 200) {
					this.isOpen = false
					this.modal.modalLoading = false
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_REJECT_PROPOSAL }
					this.formUser = {
						revisionMessage: '',
						vendorCandidateId: this.formUser.vendorCandidateId
					}
					this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
					this.$store.dispatch('projectLog/refetchProjectLogByRfpId', this.$route.params.projectId)
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		}

	},

	mounted() {
		if (this.roleUser) {
			this.$store.dispatch('qualification/getQualificationsProposalRevisionTech', this.qualification?.vendorCandidateId)
		} else {
			this.$store.dispatch('qualification/getQualificationsProposalRevision', this.qualification?.vendorCandidateId)
		}
		this.formUser.vendorCandidateId = this.qualification?.vendorCandidateId

	}
}

</script>