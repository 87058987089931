<template>
	<div class="">
		<div class="w-full overflow-auto max-h-custom" >
			<div class="w-full">
				<table class="relative w-full h-full border-collapse" aria-describedby="negotiationTableUpload">
					<thead class="sticky top-0 z-20 h-full py-1 bg-white">
						<tr class="truncate">
							<th class="p-0" scope="col">
								<div class="h-10 border-b-2 border-primary"></div>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 px-4 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">No</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 px-4 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">ID Product</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 px-4 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Type</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 px-4 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Product/Service</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 px-4 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Sub Product</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 px-4 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Description</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 px-4 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Location</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 px-4 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Qty</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 px-4 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">UoM</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 px-4 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Period</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center h-10 px-4 text-sm font-medium text-left border-b-2 border-primary text-gray-darkest">Currency</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 px-4 text-sm font-medium text-center border-b-2 border-primary text-gray-darkest">Monthly Cost</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 px-4 text-sm font-medium text-center border-b-2 border-primary text-gray-darkest">Unit Price</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 px-4 text-sm font-medium text-center border-b-2 border-primary text-gray-darkest">Total Unit Price</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 px-4 text-sm font-medium text-center border-b-2 border-primary text-gray-darkest">Disc %</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 px-4 text-sm font-medium text-center border-b-2 border-primary text-gray-darkest">Disc Price</h5>
							</th>
							<th class="p-0" scope="col">
								<h5 class="flex items-center justify-center h-10 px-4 text-sm font-medium text-center border-b-2 border-primary text-gray-darkest">Final Total Price</h5>
							</th>
							<th class="sticky top-0 right-0 z-20 p-0 bg-white" scope="col">
								<h5 class="flex items-center justify-center h-10 px-4 text-sm font-medium text-center border-b-2 border-l-2 border-primary text-gray-darkest">Message</h5>
							</th>
						</tr>
					</thead>
					<tbody>
						<template v-if="negotiationOfferTable?.items?.length > 0">
							<tr class="sticky z-10 h-10 py-1 cursor-pointer top-10 bg-primary-light" 
								@click="toggleCollapse">
								<td class="w-max py-2 px-2.5 cursor-pointer" colspan="1">
									<span v-if="!collapse"
										class="mr-3 text-base font-bold cursor-pointer icon-plus text-gray"></span>
									<span v-else
										class="mr-3 text-base font-bold cursor-pointer icon-minus text-gray"></span>
								</td>
								<td class="relative" colspan="17">
									<div class="flex items-center h-10">
										<h5 class="mr-4 text-sm text-gray-darkest">{{ negotiationOfferTable.negotiationStatus }}</h5>
										<button-extra-small-solutip color="error" @click.stop="clickDelete">Delete</button-extra-small-solutip>
										<button-extra-small-solutip v-if="!enableSaveOffer" class="ml-2" color="tertiary" @click.stop="clickDownloadExcel">Export</button-extra-small-solutip>
									</div>
								</td>
								<td class="sticky p-0 right-0 z-20 top-10">
									<div class="h-10 border-l-2 border-primary"></div>
								</td>
							</tr>
							<template v-for="item, i in negotiationOfferTable.items">
								<tr v-show="collapse" class="h-full truncate" v-if="item" :key="i" :class="i % 2 === 0? 'bg-white' : 'bg-primary-lightest bg-opacity-25'">
									<td class="flex items-center p-0">
									</td>
									<td class="align-top p-0">
										<h5 class="p-4 text-sm text-gray-darkest">{{ item.no ?? '-' }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 v-if="item.productId?.length > maxLength" class="p-4 text-sm text-gray-darkest">{{ item.productId.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.productId)">Read More</span></h5>
										<h5 v-else class="p-4 text-sm text-gray-darkest">{{ item.productId }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 v-if="item.jenis?.length > maxLength" class="p-4 text-sm text-gray-darkest">{{ item.jenis.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.jenis)">Read More</span></h5>
										<h5 v-else class="p-4 text-sm text-gray-darkest">{{ item.jenis }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 v-if="item.productLayanan?.length > maxLength" class="p-4 text-sm text-gray-darkest">{{ item.productLayanan.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.productLayanan)">Read More</span></h5>
										<h5 v-else class="p-4 text-sm text-gray-darkest">{{ item.productLayanan }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 v-if="item.subProduct?.length > maxLength" class="p-4 text-sm text-gray-darkest">{{ item.subProduct.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.subProduct)">Read More</span></h5>
										<h5 v-else class="p-4 text-sm text-gray-darkest">{{ item.subProduct }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 v-if="item.description?.length > maxLength" class="p-4 text-sm text-gray-darkest">{{ item.description.slice(0,20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.description)">Read More</span></h5>
										<h5 v-else class="p-4 text-sm text-gray-darkest">{{ item.description }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 v-if="item.location?.length > maxLength" class="p-4 text-sm text-gray-darkest">{{ item.location.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.location)">Read More</span></h5>
										<h5 v-else class="p-4 text-sm text-gray-darkest">{{ item.location }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 class="p-4 text-sm text-gray-darkest">{{ formatCurrency(item.qty, item.currency) }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 v-if="item.uom?.length > maxLength" class="p-4 text-sm text-gray-darkest">{{ item.uom.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.uom)">Read More</span></h5>
										<h5 v-else class="p-4 text-sm text-gray-darkest">{{ item.uom }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 v-if="item.period?.length > maxLength" class="p-4 text-sm text-gray-darkest">{{ item.period.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(item.period)">Read More</span></h5>
										<h5 v-else class="p-4 text-sm text-gray-darkest">{{ item.period }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 class="p-4 text-sm text-gray-darkest">{{ item.currency }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 class="p-4 text-sm text-right text-gray-darkest">{{ formatCurrency(item.biayaPerBulan, item.currency) }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 class="p-4 text-sm text-right text-gray-darkest">{{ formatCurrency(item.unitPrice, item.currency) }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 class="p-4 text-sm text-right text-gray-darkest">{{ formatCurrency(item.totalUnitPrice, item.currency) }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 class="p-4 text-sm text-right text-gray-darkest">{{ formatCurrency(item.discount, item.currency) }}%</h5>
									</td>
									<td class="align-top p-0">
										<h5 class="p-4 text-sm text-right text-gray-darkest">{{ formatCurrency(item.discountPrice, item.currency) }}</h5>
									</td>
									<td class="align-top p-0">
										<h5 class="p-4 text-sm text-right text-gray-darkest">{{ formatCurrency(item.finalTotalPrice, item.currency) }}</h5>
									</td>
									<td class="align-top p-0 sticky right-0 bg-white h-full">
										<div class="text-sm text-right w-full h-full border-l-2 border-primary flex justify-center items-center" :class="i % 2 === 0? 'bg-white' : 'bg-primary-lightest bg-opacity-25'">
											<div class="w-fit py-1.5 px-2.5 rounded-lg" :class="item.status !== 'OK' ? 'bg-error-lightest' : 'bg-success-lightest'">
												<h5 class="text-sm font-medium text-center" :class="item.status !== 'OK' ? 'text-error' : 'text-success-dark'">
													{{ item.status !== 'OK' ? 'ERROR' : 'OK' }}
												</h5>
											</div>
										</div>
									</td>
								</tr>
							</template>
							<template v-if="costType !== RATE_COST" >
								<tr v-show="collapse" class="sticky bottom-0 bg-gradient-to-t from-primary-lightest to-white">
									<td class="p-0"><div class="h-10 border-b-2 border-primary"></div></td>
									<td class="p-0">
										<div class="flex items-center h-10 border-b-2 border-primary">
											<span class="text-base icon-corner-down-right "></span>
										</div>
									</td>
									<td class="relative p-0" colspan="16">
										<div class="flex items-center w-full h-10 border-b-2 border-primary">
											<p v-if="costType !== MIX_COST" class="mr-12 text-sm font-medium text-gray-darkest">Total Price: <span class="text-sm font-semibold text-primary">{{ formatCurrency(negotiationOfferTable.totalPrice, totalCurrency()) }}</span></p>
											<div v-else class="flex items-center mr-12">
												<p class="mr-2 text-sm font-medium text-gray-darkest">Total Price: <span class="text-error">*</span></p>
												<InputExtraSmall 
													@keypress="validationInputTotalPrice" 
													@input="inputInputTotalPrice" 
													@handleInput="handleInputTotalPrice"
													@blur="blurInputTotalPrice" 
													@onEnter="blurInputTotalPrice" 
													@focus="focusInputTotalPrice"
													v-model="inputTotalPriceComputed" 
													:error="errorTotalPriceComputed"></InputExtraSmall>
											</div>
											<p v-if="!vatIncludedChecker(vat)" class="mr-12 text-sm font-medium text-gray-darkest">VAT ({{ negotiationOfferTable.ppn }}%): <span class="text-sm font-semibold text-primary">{{ formatCurrency(negotiationOfferTable.ppnPrice, totalCurrency()) }}</span></p>
											<p v-else class="mr-12 text-sm font-medium text-gray-darkest">{{ vat }}</p>
											<p class="mr-12 text-sm font-medium text-gray-darkest">Total Price + VAT: <span class="text-sm font-semibold text-primary">{{ formatCurrency(negotiationOfferTable.finalTotal, totalCurrency()) }}</span></p>
										</div>
									</td>
									<td class="sticky right-0 p-0 z-20">
										<div class="h-10 border-b-2 border-l-2 border-primary"></div>
									</td>
								</tr>
							</template>
						</template>
					</tbody>
				</table>
				<ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="modalText"/>

			</div>
		</div>
		<div v-if="negotiationOfferTable?.items?.length === 0" class="flex items-center justify-center w-full py-6">
			<div class="flex flex-col items-center mr-4">
				<input
					type="file"
					ref="file-offer"
					id="upload-file-offer"
					hidden
					@change="chooseFile($event)"
				/>
				<label for="upload-file-offer" 
					class="flex items-center p-3 text-sm font-semibold text-white transition rounded-lg cursor-pointer bg-primary hover:bg-primary-dark active:bg-primary-darkest shadow-primary-sm hover:shadow-primary-lg active:shadow-primary-sm">
					<span class="mr-3 text-2xl icon-file-plus"></span>
					Choose File From Computer
				</label>
				<div>
					<p class="mt-1 text-xs text-tertiary"
						:class="{ 'text-error': errorValidation }">
						Supported file: .xlsx, max file size: 10 MB
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import { vatIncludedChecker } from '@/core/components/helpers'
import InputExtraSmall from '@/core/components/custom/InputExtraSmall.vue'
import ButtonExtraSmallSolutip from '@/core/components/custom/ButtonExtraSmall.vue'
import ModalReadMore from '@/core/components/modal/ReadMore.vue'
import { formatThousandSeparate } from '@/core/components/helpers/formatRupiah'


export default {
	name: 'NegotiationTableUpload',
	props: ['costType', 'file', 'vat', 'errorTotalPrice', 'inputTotalPrice', 'enableSaveOffer'],
	data() {
		return {
			collapse: true,
			modalText: '',
			readMoreShow: false,
			MIX_COST: 'MIX_COST',
			RATE_COST: 'RATE_COST',
			errorValidation: false,
			timeout: null,
			maxLength: 34
		}
	},
	computed: {
		negotiationDetails() {
			return this.$store.state.negotiation.negotiationDetails
		},
		negotiationOfferTable() {
			return this.$store.state.negotiation.negotiationOfferTable
		},
        vendorDetail() {
            return this.$store.state.negotiation.vendorDetail
        },
		inputTotalPriceComputed: {
			get() {
				return this.inputTotalPrice
			},
			set(val) {
				this.$emit('input', val)
			}
		},
		errorTotalPriceComputed: {
			get() {
				return this.errorTotalPrice
			},
			set(val) {
				this.$emit('changeErrorTotalPrice', val)
			}
		},
		modal: {
				get() {
						return this.$store.state.modal.modal
				},
				set(value) {
						this.$store.commit('modal/SET_MODAL', value)
				}
		},
	},
	methods: {
		formatCurrency,
        vatIncludedChecker,
		toggleCollapse() {
			this.collapse = !this.collapse
		},
		closeModal() {
			this.readMoreShow = false
		},
		readMore(text) {
			this.readMoreShow = true;
			this.modalText = text
		},
		validationInputTotalPrice(e) {
			let keyCode = e.keyCode
			if ((keyCode > 47 && keyCode < 58) || keyCode === 44) {
				this.errorTotalPriceComputed = false
				return
			}
			e.preventDefault();
		},
		focusInputTotalPrice() {
			this.$emit('deleteTotalPriceState')
		},
		inputInputTotalPrice(e) {
			this.$emit('input', formatThousandSeparate(e.toString().split(',').join('')))
		},
		handleInputTotalPrice(e) {
			const el = e.target;
			const formatted = formatThousandSeparate(el.value.split(',').join(''))
			const count = formatted?.split(',').length
			const count2 = el.value?.split(',').length
			let sel = count > count2 ? el.selectionStart + 1 : count < count2 ? el.selectionStart - 1 : el.selectionStart
      this.$nextTick(() => {
        el.setSelectionRange(sel, sel);
      });
		},
		blurInputTotalPrice() {
			this.uploadFile(this.file)
		},
		clickDelete() {
			const negotiationOfferTableReset = {
				items: [],
				finalTotal: null,
				ppn: null,
				ppnPrice: null,
				totalPrice: null,
			}
			this.$store.commit('negotiation/SET_NEGOTIATION_OFFER_TABLE', negotiationOfferTableReset)
			this.$emit('deleteFile')
		},
		async uploadFile(file) {
			this.$emit('uploadFile', { file, totalPrice: this.inputTotalPriceComputed.split(',').join('') })
		},
		resetFileRef() {
			//reset input file ref
			if (this.$refs['file-offer']) {
				this.$refs['file-offer'].value = ''
			}
		},
		async chooseFile(e) {
			const file = e.target.files[0]
            const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] })
			if(checkFileValidation){
				this.errorValidation = true
				setTimeout(() => {
					this.errorValidation = false
				}, 5000)
			} else await this.uploadFile(file)
			this.resetFileRef()
		},
		totalCurrency() {
			return this.negotiationOfferTable?.items?.[0]?.currency
		},
		async clickDownloadExcel() {
			await this.$store.dispatch('negotiation/getExportNegotiation')
		},
	},
	components: {
		ButtonExtraSmallSolutip,
		InputExtraSmall,
		ModalReadMore
	}

}
</script>

<style scoped>
.max-h-custom {
	max-height: 34rem;
}
.min-w-64 {
	min-width: 16rem;
}
.border-primary-custom {
	position: relative;
}
.border-primary-custom ::before {
	content: '';
	border: 1px solid rgb(48, 213, 201);
	height: 48px;
	position: absolute;
	left: 0px;
	top: -4px;
}
.w-fit {
	width: fit-content
}
</style>