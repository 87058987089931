<template>
  <div>
    <div class="flex justify-between my-6">
      <div>
        <span class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"
			    @click="clickBackToVendorDetail"></span>
      </div>
      <div>
					<h4 v-if="!showEditFptn" class="text-lg font-medium text-gray-darkest">FORM PERSETUJUAN TANPA NEGOSIASI (FPTN)</h4>
					<h4 v-if="showEditFptn" class="text-lg font-medium text-gray-darkest">Edit FPTN</h4>
      </div>
      <div></div>
    </div>
    <div>
      <div class="flex justify-between mb-6" :class="quotationDetailContainerWidth < 640 ? 'flex-col gap-3' : ''" v-if="showEditFptn">
        <div class="flex items-center">
          <h5 class="text-base text-gray-darkest">{{ negotiationFPTN.vendorName }}</h5>
        </div>
        <div>
          <button-gista
            @click="clickViewFPTN"
            type="secondary"
            color="success"
            size="small"
            v-if="menuRfqNegotiationFptnReport"
            :class="quotationDetailContainerWidth < 640 ? 'w-full' : ''"
            >
            View FPTN
            <template #icon-left>
              <span class="mr-4 text-lg icon-eye text-success"></span>
            </template>
          </button-gista>
        </div>
      </div>
      <ValidationObserver ref="formDealBan">
        <div class="flex items-center mb-6">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">Detail BAN</h5>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 mb-6 border-b border-gray-lightest pb-4"
          :class="{
            'grid-cols-1': quotationDetailContainerWidth < 768,
            'grid-cols-2': quotationDetailContainerWidth >= 768
        }">
          <InputDisabled 
            label="Project ID" 
            :value="negotiationFPTN.projectId"
          />
          <InputDisabled 
            label="Request No." 
            :value="negotiationFPTN.requestNo"
          />
          <InputDisabled 
            label="Vendor Name" 
            :value="negotiationFPTN.vendorName"
          />
          <InputDisabled 
            label="Project Name" 
            :value="negotiationFPTN.projectName"
          />
          <div>
            <div class="mb-1">
              <p class="text-sm" :class="error['Procurement Type']? 'text-error' : 'text-gray'">Procurement Type <span class="text-sm text-error">*</span></p>
            </div>
            <div>
              <gista-checkbox @change="changeProcurementType($event, 'REPEAT_ORDER')" :modelValue="negotiationFPTN.procurementType?.includes('REPEAT_ORDER')" class="text-gray">Repeat Order</gista-checkbox>
              <gista-checkbox @change="changeProcurementType($event, 'RENEWAL')" :modelValue="negotiationFPTN.procurementType?.includes('RENEWAL')" class="text-gray">Renewal</gista-checkbox>
              <gista-checkbox @change="changeProcurementType($event, 'CHANGE_REQUEST')" :modelValue="negotiationFPTN.procurementType?.includes('CHANGE_REQUEST')" class="text-gray">Change Request</gista-checkbox>
              <gista-checkbox @change="changeProcurementType($event, 'ADDITIONAL')" :modelValue="negotiationFPTN.procurementType?.includes('ADDITIONAL')" class="text-gray">Additional</gista-checkbox>
              <gista-checkbox @change="changeProcurementType($event, 'ADVANCED_PROJECT')" :modelValue="negotiationFPTN.procurementType?.includes('ADVANCED_PROJECT')" class="text-gray">Advanced Project</gista-checkbox>
              <gista-checkbox @change="changeProcurementType($event, 'DISCONTINUED_PRODUCT')" :modelValue="negotiationFPTN.procurementType?.includes('DISCONTINUED_PRODUCT')" class="text-gray">Discontinued Product</gista-checkbox>
            </div>
            <div v-if="error['Procurement Type']">
              <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
            </div>
          </div>
          <div>
            <div class="mb-1">
              <p class="text-sm" :class="error['Project Type']? 'text-error' : 'text-gray'">Project Type <span class="text-sm text-error">*</span></p>
            </div>
            <div>
              <gista-checkbox @change="changeProjectType($event, 'HARDWARE')" :modelValue="negotiationFPTN.projectType?.includes('HARDWARE')" class="mr-4 text-sm font-medium text-gray">Hardware</gista-checkbox>
              <gista-checkbox @change="changeProjectType($event, 'SOFTWARE')" :modelValue="negotiationFPTN.projectType?.includes('SOFTWARE')"  class="mr-4 text-sm font-medium text-gray">Software</gista-checkbox>
              <gista-checkbox @change="changeProjectType($event, 'SERVICE')" :modelValue="negotiationFPTN.projectType?.includes('SERVICE')"  class="mr-4 text-sm font-medium text-gray">Service</gista-checkbox>
              <gista-checkbox @change="changeProjectType($event, 'MAINTENANCE')" :modelValue="negotiationFPTN.projectType?.includes('MAINTENANCE')"  class="mr-4 text-sm font-medium text-gray">Maintenance</gista-checkbox>
              <gista-checkbox @change="changeProjectType($event, 'LINK_COMMUNICATION')" :modelValue="negotiationFPTN.projectType?.includes('LINK_COMMUNICATION')"  class="text-sm font-medium text-gray">Link-Communication</gista-checkbox>
            </div>
            <div v-if="error['Project Type']">
              <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
            </div>
          </div>
          <InputDisabled 
            label="Offer No." 
            :value="negotiationFPTN.offerNo"
          />
          <InputDisabled 
            label="Offer Date" 
            :value="negotiationFPTN.offerDate"
          />
          <InputDisabled 
            label="Date Created" 
            :value="createdAt"
          />
          <ValidationProvider
            rules="max:64"
            name="Reference Document"
            v-slot="{errors}"
            >
            <InputGista
              type="text"
              size="large"
              label="Reference Document"
              placeholder="value"
              v-model="negotiationFPTN.referenceDoc"
              :error="!!errors?.[0] || !!error['Reference Document']"
              @input="setErrorReference"
              :mandatory="true"
              >
              <template #message>
                <p>{{errors?.[0] || (error['Reference Document'] ? MESSAGE_EMPTY_FIELD : '')}}</p>
              </template>
            </InputGista>
          </ValidationProvider>
        </div>
        <div class="mb-6">
          <div class="flex flex-wrap justify-between gap-3 mb-6">
            <div class="flex items-center">
              <span class="mr-4 icon-list"></span>
              <h5 class="text-lg font-medium text-gray-darkest">Current Procurement Cost</h5>
            </div>
            <button class="flex items-center" :class="`${disableMaximizeTable? 'cursor-not-allowed': 'cursor-pointer hover:text-gray-dark active:text-gray-darkest'} ${quotationDetailContainerWidth < 640 ? '' : 'pl-4'}`"
                @click="clickToggleMaximizeTable"
                :disabled="disableMaximizeTable"
                >
              <span class="mr-3 text-lg icon-maximize text-gray"></span>
              <p class="text-sm font-semibold text-gray" >Maximize table</p>
            </button>
          </div>
          <MaximizeTable v-if="isOpenMaximizeTable" :isOpen="isOpenMaximizeTable" @clickToggle="clickToggleMaximizeTable"/>
          <NegotiationTableFptn/>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 mb-6"
          :class="{
            'grid-cols-1': quotationDetailContainerWidth < 768,
            'grid-cols-2': quotationDetailContainerWidth >= 768
          }">
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray" :class="errorMaxLength['Payment Term']? 'text-error' : 'text-gray'">
                Payment Term
              </label>
            </div>
            <div class="">
              <Tiptap @char="(e) => characterCount(e, 'Payment Term')" v-model="negotiationFPTN.paymentTerm" :error="!!errorMaxLength['Payment Term']">
                <template #message>
                  <p>{{ errorMaxLength['Payment Term'] }}</p>
                </template>
              </Tiptap>
            </div>
          </div>
          <InputDisabled
            label="VAT" 
            :value="taxText"
            :class="quotationDetailContainerWidth < 768? 'col-span-2' :'col-span-1'"
          />
          <div :class="quotationDetailContainerWidth < 768? 'col-span-2' :'col-span-1'">
            <ValidationProvider
              rules="max:4500"
              name="Project Term"
              v-slot="{errors}">
              <InputGista 
                type="text"
                size="large"
                label="Project Term"
                placeholder="value"
                v-model="negotiationFPTN.jangkaWaktuProyek"
                :error="!!errors?.[0]"
                >
                <template #message>
                  <p>{{errors?.[0]}}</p>
                </template>
              </InputGista>
            </ValidationProvider>
          </div>
          <div class="col-span-2" v-for="n,i in fileQuotation" :key="i">
            <div>
              <p class="mb-1 text-sm font-medium text-gray">File Quotation</p>
            </div>
            <div class="flex items-center justify-between border rounded-lg border-primary h-14">
              <div class="px-4 py-2">
                <div class="text-base font-medium text-gray">{{ n.fileName }}</div>
              </div>
              <div class="flex items-center px-4">
                <p class="text-xl cursor-pointer icon-eye text-primary hover:text-primary-darkest" @click="openFile(n.fileName, n.documentNo)"></p>
              </div>
            </div>
          </div>
          <div class="col-span-2" v-for="n,i in attachmentFiltered" :key="i+'a'">
            <div>
              <p class="mb-1 text-sm font-medium text-gray">Attachment #{{i+1}}</p>
            </div>
            <div class="flex items-center justify-between p-2 border rounded-lg border-primary h-14">
              <div class="text-base text-gray">{{ n.fileName }}</div>
              <span class="cursor-pointer icon-trash-2 text-error" @click="clickRemoveFileAttachment(n)"></span>
            </div>
          </div>
          <div class="col-span-2">
            <ValidationProvider
              ref="lampiranFptn"
            >
              <div class="flex flex-wrap items-center">
                <div class="flex" v-if="maxAttachment">
                  <span class="mr-2 icon-file-plus text-secondary"></span>
                  <input type="file"
                      id="upload-file-attachment"
                      ref="fileAttachment"
                      hidden
                      @change="addAttachment($event, 'lampiranFptn')" />
                  <label for="upload-file-attachment"
                    class="mr-4 text-base font-medium underline cursor-pointer text-secondary">
                    Add Attachment
                  </label>
                </div>
                <p class="mr-3.5" :class="{ 'text-error': errorFileType, 'text-gray': !errorFileType }">Max Attachment: 10 </p>
                <p :class="{ 'text-error': errorFileType, 'text-gray': !errorFileType }">Supported file: .pdf, max file size: 10 MB</p>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray" :class="errorMaxLength['Note Internal BCA']? 'text-error' : 'text-gray'">
                Note Internal BCA
              </label>
            </div>
            <div class="">
              <Tiptap @char="(e) => characterCount(e, 'Note Internal BCA')" v-model="negotiationFPTN.internalNote" :error="!!errorMaxLength['Note Internal BCA']">
                <template #message>
                  {{ errorMaxLength['Note Internal BCA'] }}
                </template>
              </Tiptap>
            </div>
          </div>
          <AutocompleteNewSolutip
            label="ITP Verificator (S5-S4)"
            placeholder="(Search)"
            :options="approverNegotiation"
            @updateInput="inputSearchItpVerificator($event)"
            @updateAutoComplete="changeItpVerificator($event)"
            @blur="blurItpVerificator"
            @focus="focusItpVerificator"
            v-model="searchItpVerificator"
            textField="name"
            textField2="jobTitle"
            :class="quotationDetailContainerWidth < 768? 'col-span-2' :'col-span-1'"
            iconLeft="icon-search text-xl text-gray-light"
            :isLoading="isLoadingVerificator"
            :error="error['ITP Verificator']"
            :mandatory="true"
          >
          <template v-if="error['ITP Verificator']" #message>
            <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
          </template>
          </AutocompleteNewSolutip>
          <AutocompleteNewSolutip
            label="Approval ITP (S3-S1)"
            placeholder="(Search)"
            :options="approverNegotiation"
            @updateInput="inputSearchApprovalItp($event)"
            @updateAutoComplete="changeApprovalItp($event)"
            @blur="blurApprovalItp"
            @focus="focusApprovalItp"
            v-model="searchApprovalItp"
            textField="name"
            textField2="jobTitle"
            :class="quotationDetailContainerWidth < 768? 'col-span-2' :'col-span-1'"
            iconLeft="icon-search text-xl text-gray-light"
            :isLoading="isLoadingVerificator"
            :error="error['Approval ITP']"
            :mandatory="true"
          >
          <template v-if="error['Approval ITP']" #message>
            <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
          </template>
          </AutocompleteNewSolutip>
          <AutocompleteNewSolutip
            v-if="workflowGsit"
            label="Representative GSIT (S3-S2)"
            placeholder="(Search)"
            :options="approverNegotiation"
            @updateInput="inputSearchRepresentatifGsit($event)"
            @updateAutoComplete="changeRepresentatifGsit($event)"
            @blur="blurRepresentatifGsit"
            @focus="focusRepresentatifGsit"
            v-model="searchRepresentatifGsit"
            textField="name"
            textField2="jobTitle"
            :class="quotationDetailContainerWidth < 768? 'col-span-2' :'col-span-1'"
            iconLeft="icon-search text-xl text-gray-light"
            :isLoading="isLoadingVerificator"
          >
          </AutocompleteNewSolutip>
          <AutocompleteNewSolutip
            v-if="workflowGsit"
            label="Approval GSIT (S1)"
            placeholder="(Search)"
            :options="approverNegotiation"
            @updateInput="inputSearchApprovalGsit($event)"
            @updateAutoComplete="changeApprovalGsit($event)"
            @blur="blurApprovalGsit"
            @focus="focusApprovalGsit"
            v-model="searchApprovalGsit"
            textField="name"
            textField2="jobTitle"
            :class="quotationDetailContainerWidth < 768? 'col-span-2' :'col-span-1'"
            iconLeft="icon-search text-xl text-gray-light"
            :isLoading="isLoadingVerificator"
            :error="error['Approval GSIT']"
            :mandatory="workflowGsit"
          >
          <template v-if="error['Approval GSIT']" #message>
            <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
          </template>
          </AutocompleteNewSolutip>
          <AutocompleteNewSolutip
            label="Representative UKKP (S3-S2)"
            placeholder="(Search)"
            :options="approverNegotiation"
            @updateInput="inputSearchRepresentatifUkkp($event)"
            @updateAutoComplete="changeRepresentatifUkkp($event)"
            @blur="blurRepresentatifUkkp"
            @focus="focusRepresentatifUkkp"
            v-model="searchRepresentatifUkkp"
            textField="name"
            textField2="jobTitle"
            :class="quotationDetailContainerWidth < 768? 'col-span-2' :'col-span-1'"
            iconLeft="icon-search text-xl text-gray-light"
            :isLoading="isLoadingVerificator"
          >
          </AutocompleteNewSolutip>
          <AutocompleteNewSolutip
            label="Approval UKKP (S1)"
            placeholder="(Search)"
            :options="approverNegotiation"
            @updateInput="inputSearchApprovalUkkp($event)"
            @updateAutoComplete="changeApprovalUkkp($event)"
            @blur="blurApprovalUkkp"
            @focus="focusApprovalUkkp"
            v-model="searchApprovalUkkp"
            textField="name"
            textField2="jobTitle"
            :class="quotationDetailContainerWidth < 768? 'col-span-2' :'col-span-1'"
            iconLeft="icon-search text-xl text-gray-light"
            :isLoading="isLoadingVerificator"
            :error="error['Approval UKKP']"
            :mandatory="!workflowGsit"
          >
          <template v-if="error['Approval UKKP']" #message>
              <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
          </template>
          </AutocompleteNewSolutip>        
        </div>
        <div>
          <div class="flex flex-wrap justify-between w-full gap-6">
            <FptnSaveAsDraft :customClass="quotationDetailContainerWidth < 640 ? 'w-full' : ''" :isOpenFptnSaveAsDraft="isOpenFptnSaveAsDraft" @clickToggle="changeIsOpenSaveAsDraft($event)" @click="clickSaveAsDraftFptn"/>
            <div class="flex w-full gap-6" :class="quotationDetailContainerWidth < 640? 'flex-col': 'flex-row w-max'">
              <ButtonGista
                @click="clickPreviewFptn"
                type="secondary" 
                color="primary"
                class="w-full"
              >Preview FPTN
              </ButtonGista>
              <SubmitFPTN :customClass="quotationDetailContainerWidth < 640 ? 'w-full' : ''" :isOpenFPTN="isOpenFPTN" @clickToggleSubmit="clickToggleSubmit($event)" @click="clickSubmitFptn" />
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import MaximizeTable from "@/core/components/rfq/negotiation/MaximizeTable.vue";
  import Tiptap from '@/core/components/custom/Tiptap.vue'
  import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
  import NegotiationTableFptn from "@/core/components/rfq/negotiation/NegotiationTableFptn.vue";
  import FptnSaveAsDraft from "@/core/components/modal/negotiation/FptnSaveAsDraft.vue";
  import SubmitFPTN from "@/core/components/modal/negotiation/SubmitFPTN.vue";
  import { MESSAGE_EMPTY_FIELD, MESSAGE_POPUP_VALIDATION_SUBMIT } from '@/core/constant/errorMessage.js'
  import InputDisabled from '@/core/components/custom/InputDisabled.vue'
  import { MESSAGE_SUCCESS_SUBMIT_FPTN } from '@/core/constant/successMessage'
  import { PROJECT_TYPE_RFQ } from '@/core/constant/negotiation.js'


  export default {
    name: 'FPTN',
    data() {
      return {
        form: {
          costType: '',
          file: {},
          offerDate: '',
          offerNo: '',
          totalPrice: '',
        },
        loadingFile: false,
        showSendEmailModal: false,
        isOpenFPTN: false,
        searchItpVerificator: '',
        searchApprovalItp: '',
        searchRepresentatifGsit: '',
        searchApprovalGsit: '',
        searchRepresentatifUkkp: '',
        searchApprovalUkkp: '',
        isOpenFptnSaveAsDraft: false,
        MESSAGE_EMPTY_FIELD,
        error: {
          "ITP Verificator": false,
          "Approval ITP": false,
          "Approval GSIT": false,
          "Approval UKKP": false,
          "Procurement Type": false,
          "Project Type": false,
          "Reference Document": false
        },
        isLoadingVerificator: false,
        isOpenMaximizeTable: false,
        errorMaxLength: {
          'Payment Term': '',
          'Note Internal BCA': ''
        },
        errorFileType: false
      }
    },
    computed: {
      negotiationDetail(){
        return this.$store.state.negotiationRfq.negotiationDetail
      },
      showEditFptn(){
        return this.$store.state.negotiationRfq.showEditFptn
      },
      disableMaximizeTable() {
        return this.negotiationDetail?.offers?.length === 0
      },
      negotiationFPTN: {
        get(){
          return this.$store.state.negotiationRfq.negotiationFPTN
        },
        set(value){
          this.$store.commit('negoriationRfq/SET_FPTN', value)
        }
      },
      attachmentFiltered() {
        return this.negotiationFPTN.attachments.filter(n =>(n.fileRef ==='lampiranFptn' && !n.isDeleted))
      },
      fileQuotation() {
        return this.negotiationFPTN?.attachments?.filter(i => i.fileRef === 'fileQuotation')
      },
      approverNegotiation() {
        return this.$store.state.negotiationRfq.approverNegotiation.filter(e => !this.filterForApproverNegotiation.includes(e.userName))
      },
      filterForApproverNegotiation() {
        let people = []
        if (this.searchItpVerificator) {
          people.push(this.negotiationFPTN.itpVerificatorId)
        }
        if (this.searchApprovalItp) {
          people.push(this.negotiationFPTN.directApproverItpId)
        }
        if (this.searchRepresentatifGsit) {
          people.push(this.negotiationFPTN.representGsitId)
        }
        if (this.searchApprovalGsit) {
          people.push(this.negotiationFPTN.directApproverGsitId)
        }
        if (this.searchRepresentatifUkkp) {
          people.push(this.negotiationFPTN.representUkkpId)
        }
        if (this.searchApprovalUkkp) {
          people.push(this.negotiationFPTN.directApproverUkkpId)
        }
        return people
      },
      negotiator() {
        return this.$store.state.negotiationRfq.negotiator
      },
      workflowGsit() {
        return this.negotiationFPTN.isUnitGsit
      },
      quotationDetailContainerWidth() {
        return this.$store.state.width.quotationDetailContainerWidth
      },
      modal: {
        get() {
          return this.$store.state.modal.modal
        },
        set(value) {
          this.$store.commit('modal/SET_MODAL', value)
        }
      },
      createdAt() {
        return this.negotiationFPTN.createdAt ? new Date(this.negotiationFPTN.createdAt).toLocaleDateString('id-ID') : '(auto-generate)'
      },
      menuRfqNegotiationFptnReport() {
        return this.$store.getters['dashboard/menuRfqNegotiationFptnReport']?.canRead
      },
      taxText() {
        return this.negotiationFPTN?.tax?.ppn === 0 ? this.negotiationFPTN?.tax?.taxType : `${this.negotiationFPTN?.tax?.taxType} - ${this.negotiationFPTN?.tax?.ppn}%`  
      },
      maxAttachment() {
        return this.attachmentFiltered.length < 10
      },
      projectLogRfq() {
        return this.$store.state.projectLog.projectLogRfq
      },
    },
    methods: {
      checkErrorLength(){
        return Object.values(this.errorMaxLength).every(e => !e)
      },
      messageErrorLength(){
        const filterError = Object.keys(this.errorMaxLength).filter(e => this.errorMaxLength[e])
        return filterError.join(', ')
      },
      characterCount(e, name) {
        if(e > 4500) this.errorMaxLength[name] = `Kolom ${name} Maksimal 4500 Karakter`
        else this.errorMaxLength[name] = ''
      },
      changeIsOpenSaveAsDraft(val){
        this.isOpenFptnSaveAsDraft = val
      },
      clickToggleSubmit(val) {
        this.isOpenFPTN = val
      },
      async inputSearchItpVerificator(e) {
        this.isLoadingVerificator = true
        this.searchItpVerificator = e
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', param: 'ITP_VERIFICATOR' })
        this.isLoadingVerificator = false
      },
      changeItpVerificator(e) {
        this.negotiationFPTN.itpVerificatorId = e.userName
        this.negotiationFPTN.itpVerificatorName = e.name
        this.searchItpVerificator = e.name
        this.error["ITP Verificator"] = false
      },
      blurItpVerificator() {
        if(this.searchItpVerificator !== '') this.searchItpVerificator = this.negotiationFPTN.itpVerificatorName
        else {
          this.negotiationFPTN.itpVerificatorName = ''
          this.negotiationFPTN.itpVerificatorId = ''
        }
      },
      async focusItpVerificator() {
        this.isLoadingVerificator = true
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.searchItpVerificator, project: 'RFQ', param: 'ITP_VERIFICATOR' })
        this.isLoadingVerificator = false
      },

      async inputSearchApprovalItp(e) {
        this.isLoadingVerificator = true
        this.searchApprovalItp = e
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', param: 'SPV_ITP_APPROVAL' })
        this.isLoadingVerificator = false
      },
      changeApprovalItp(e) {
        this.negotiationFPTN.directApproverItpId = e.userName
        this.negotiationFPTN.directApproverItpName = e.name
        this.searchApprovalItp = e.name
        this.error['Approval ITP'] = false
      },
      blurApprovalItp() {
        if(this.searchApprovalItp !== '') this.searchApprovalItp = this.negotiationFPTN.directApproverItpName
        else {
          this.negotiationFPTN.directApproverItpName = ''
          this.negotiationFPTN.directApproverItpId = ''
        }
      },
      async focusApprovalItp() {
        this.isLoadingVerificator = true
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.searchApprovalItp, project: 'RFQ', param: 'SPV_ITP_APPROVAL' })
        this.isLoadingVerificator = false
      },

      async inputSearchRepresentatifGsit(e) {
        this.isLoadingVerificator = true
        this.searchRepresentatifGsit = e
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', param: 'REPRESENTATIVE_GSIT' })
        this.isLoadingVerificator = false
      },
      changeRepresentatifGsit(e) {
        this.negotiationFPTN.representGsitId = e.userName
        this.negotiationFPTN.representGsitName = e.name
        this.searchRepresentatifGsit = e.name
      },
      blurRepresentatifGsit() {
        if(this.searchRepresentatifGsit !== '') this.searchRepresentatifGsit = this.negotiationFPTN.representGsitName
        else {
          this.negotiationFPTN.representGsitName = ''
          this.negotiationFPTN.representGsitId = ''
        }
      },
      async focusRepresentatifGsit() {
        this.isLoadingVerificator = true
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.searchRepresentatifGsit, project: 'RFQ', param: 'REPRESENTATIVE_GSIT' })
        this.isLoadingVerificator = false
      },
      async inputSearchApprovalGsit(e) {
        this.isLoadingVerificator = true
        this.searchApprovalGsit = e
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', param: 'SPV_GSIT_APPROVAL' })
        this.isLoadingVerificator = false
      },
      changeApprovalGsit(e) {
        this.negotiationFPTN.directApproverGsitId = e.userName
        this.negotiationFPTN.directApproverGsitName = e.name
        this.searchApprovalGsit = e.name
        this.error['Approval GSIT'] = false
      },
      blurApprovalGsit() {
        if(this.searchApprovalGsit !== '') this.searchApprovalGsit = this.negotiationFPTN.directApproverGsitName
        else {
          this.negotiationFPTN.directApproverGsitName = ''
          this.negotiationFPTN.directApproverGsitId = ''
        }
      },
      async focusApprovalGsit() {
        this.isLoadingVerificator = true
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.searchApprovalGsit, project: 'RFQ', param: 'SPV_GSIT_APPROVAL' })
        this.isLoadingVerificator = false
      },

      async inputSearchRepresentatifUkkp(e) {
        this.isLoadingVerificator = true
        this.searchRepresentatifUkkp = e
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', param: 'REPRESENTATIVE_UKKP' })
        this.isLoadingVerificator = false
      },
      changeRepresentatifUkkp(e) {
        this.negotiationFPTN.representUkkpId = e.userName
        this.negotiationFPTN.representUkkpName = e.name
        this.searchRepresentatifUkkp = e.name
      },
      blurRepresentatifUkkp() {
        if(this.searchRepresentatifUkkp !== '') this.searchRepresentatifUkkp = this.negotiationFPTN.representUkkpName
        else {
          this.negotiationFPTN.representUkkpName = ''
          this.negotiationFPTN.representUkkpId = ''
        }
      },
      async focusRepresentatifUkkp() {
        this.isLoadingVerificator = true
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.searchRepresentatifUkkp, project: 'RFQ', param: 'REPRESENTATIVE_UKKP' })
        this.isLoadingVerificator = false
      },
      async inputSearchApprovalUkkp(e) {
        this.isLoadingVerificator = true
        this.searchApprovalUkkp = e
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: e, project: 'RFQ', param: 'SPV_UKKP_APPROVAL' })
        this.isLoadingVerificator = false
      },
      changeApprovalUkkp(e) {
        this.negotiationFPTN.directApproverUkkpId = e.userName
        this.negotiationFPTN.directApproverUkkpName = e.name
        this.searchApprovalUkkp = e.name
        this.error['Approval UKKP'] = false
      },
      blurApprovalUkkp() {
        if(this.searchApprovalUkkp !== '') this.searchApprovalUkkp = this.negotiationFPTN.directApproverUkkpName
        else {
          this.negotiationFPTN.directApproverUkkpName = ''
          this.negotiationFPTN.directApproverUkkpId = ''
        }
      },
      async focusApprovalUkkp() {
        this.isLoadingVerificator = true
        await this.$store.dispatch('negotiationRfq/getUserApproverNegotiation', { name: this.searchApprovalUkkp, project: 'RFQ', param: 'SPV_UKKP_APPROVAL' })
        this.isLoadingVerificator = false
      },
      openFile(filename, documentNo) {
        this.$store.dispatch('file/viewAttachment', { filename, documentNo })
      },
      clickBackToVendorDetail() {
        this.$store.commit('negotiationRfq/SET_SHOW_FPTN', false)
      },
      changeProcurementType(e, type) {
      
        if (e) {
          this.negotiationFPTN.procurementType = [ ...this.negotiationFPTN.procurementType, type ]
        } else {
          this.negotiationFPTN.procurementType = this.negotiationFPTN.procurementType.filter(item => item !== type)
        }
        this.error["Procurement Type"] = false
      },
      changeProjectType(e, type) {
        if (e) {
          this.negotiationFPTN.projectType = [ ...this.negotiationFPTN.projectType, type ]
        } else {
          this.negotiationFPTN.projectType = this.negotiationFPTN.projectType.filter(item => item !== type)
        }
        this.error["Project Type"] = false
      },
      
      async clickRemoveFileAttachment(file){
        try {
          const {fileName, documentNo} = file
          this.modal.modalLoading = true
				  const fileAttachment = this.negotiationFPTN.attachments.find(e => e.filePath === file.filePath)
          if (fileAttachment?.attachmentId > 0) {
            this.negotiationFPTN.attachments = this.negotiationFPTN.attachments.map(e => (e.filePath === fileAttachment.filePath? ({ ...e, isDeleted: true }): e))
          } else {
            const response = await this.$store.dispatch('file/deleteFile', documentNo || fileName)
            if (response?.status < 300 && response?.status >= 200) {
              this.negotiationFPTN.attachments = this.negotiationFPTN.attachments.filter(e => e.filePath !== file.filePath)
            } else {
              this.$store.dispatch('modal/throwError', { response })
            }
          }
          this.modal.modalLoading = false
        } catch (error) {
          this.modal.modalLoading = false
          this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
        }
      },
      async addAttachment(e, fileRef) {
        const file = e.target?.files[0]
        const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
        if(checkFileValidation){
          this.errorFileType = true
        } else {
          try {
            this.modal.modalLoading = true
            this.errorFileType = false
            const encrypted = await this.$store.dispatch('file/hashMD5',file)
            const formData = new FormData()
            formData.append('file', file)
            formData.append('fileRef', fileRef)
            //checksum encrypted md5
            formData.append('checksum', encrypted)
            formData.append('projectType', PROJECT_TYPE_RFQ)
            const response = await this.$store.dispatch('file/uploadFile', formData)
            if (response?.status < 300 && response?.status >= 200) {
              const data = response.data.result
              const fileAfterUpload = {
                fileName: data.fileName,
                filePath: data.filePath,
                documentNo: data.documentNo,
                fileRef,
                isDeleted: false,
                attachmentId: 0,
              }
              this.negotiationFPTN.attachments = [ ...this.negotiationFPTN.attachments, fileAfterUpload ]
              this.modal.modalLoading = false
            } else {
              this.$store.dispatch('modal/throwError', { response })
            }
          } catch (error) {
            this.modal.modalLoading = false
            this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
          }
        }
        if (this.$refs['fileAttachment']) {
          this.$refs['fileAttachment'].value = ''
        }
      },
      setErrorReference(){
        this.error['Reference Document'] = false
      },
      checkErrorFlow() {
        if(!this.searchApprovalGsit && this.workflowGsit) {
          this.error['Approval GSIT'] = true
        }
        if (!this.workflowGsit && !this.searchApprovalUkkp) {
          this.error['Approval UKKP'] = true
        }
      },
      checkErrorValidate() {
        this.checkErrorFlow()
        if (!this.searchItpVerificator) {
          this.error['ITP Verificator'] = true
        }
        if (!this.negotiationFPTN.directApproverItpId) {
          this.error['Approval ITP'] = true
        }
        if(!this.negotiationFPTN.referenceDoc){
          this.error['Reference Document'] = true
        }
        const {procurementType, projectType} = this.negotiationFPTN
        if (!procurementType || procurementType?.length === 0 || (procurementType?.length === 1 && procurementType?.[0] === '')) {
          this.error['Procurement Type'] = true
        }
        if (!projectType || projectType?.length === 0 || (projectType?.length === 1 && projectType?.[0] === '')) {
          this.error['Project Type'] = true
        }
        return Object.values(this.error).every(e => !e)
      },
      messageErrorValidate() {
        const errorVeeValidate = this.$refs.formDealBan.errors
        const filterError = Object.keys(errorVeeValidate).filter(e => errorVeeValidate[e].length > 0)
        const filterErrorCustom = Object.keys(this.error).filter(e => this.error[e] === true) 
        return [ ...filterErrorCustom, ...filterError ].join(', ')
      },
      payloadFptn() {
        return {
            rfqFptnId: this.negotiationFPTN.rfqFptnId,
            rfqNegotiationId:  this.negotiationFPTN.rfqNegotiationId,
            projectId:  this.negotiationFPTN.projectId,
            requestNo: this.negotiationFPTN.requestNo,
            referenceDoc:  this.negotiationFPTN.referenceDoc,
            vendorName: this.negotiationFPTN.vendorName,
            projectName: this.negotiationFPTN.projectName,
            procurementType: this.negotiationFPTN.procurementType,
            projectType:  this.negotiationFPTN.projectType,
            offerNo:  this.negotiationFPTN.offerNo,
            offerDate: this.negotiationFPTN.offerDate,
            createdAt: this.negotiationFPTN.createdAt,
            paymentTerm:  this.negotiationFPTN.paymentTerm,
            jangkaWaktuProyek: this.negotiationFPTN.jangkaWaktuProyek,
            isWaiverBg:  this.negotiationFPTN.isWaiverBg,
            internalNote:  this.negotiationFPTN.internalNote,
            itpVerificatorId:  this.negotiationFPTN.itpVerificatorId,
            itpVerificatorName:  this.negotiationFPTN.itpVerificatorName,
            representGsitId:  this.negotiationFPTN.representGsitId,
            representUkkpId:  this.negotiationFPTN.representUkkpId,
            directApproverItpId:  this.negotiationFPTN.directApproverItpId,
            directApproverGsitId:  this.negotiationFPTN.directApproverGsitId,
            directApproverUkkpId:  this.negotiationFPTN.directApproverUkkpId,
            isDeleted:  this.negotiationFPTN.isDeleted,
            fptnStatus:  this.negotiationFPTN.fptnStatus,
            attachments: this.negotiationFPTN.attachments,
          }
      },
      async clickSaveAsDraftFptn(){
        try {
          this.modal.modalLoading = true
          const successValidate = await this.$refs.formDealBan.validate()
          const MESSAGE_ERROR_VALIDATE = this.messageErrorValidate()
          const checkErrorLength = this.checkErrorLength()
          const MESSAGE_ERROR_LENGTH = this.messageErrorLength()
          if (!successValidate || !checkErrorLength) {
            this.modal.modalLoading = false
            const message = MESSAGE_ERROR_VALIDATE && MESSAGE_ERROR_LENGTH ? `, ${MESSAGE_ERROR_LENGTH}` : MESSAGE_ERROR_LENGTH
            this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE + message }
            this.changeIsOpenSaveAsDraft(false)
            return
          }
          const payload = this.payloadFptn()
          const response = await this.$store.dispatch('negotiationRfq/postSaveAsDraftFPTN', payload)
          if (response?.status < 300 && response?.status >= 200) {
            this.modal.modalLoading = false
            this.changeIsOpenSaveAsDraft(false)
            setTimeout(() =>{
              this.$store.commit('negotiationRfq/SET_SHOW_FPTN', false)
            },300)
            this.$store.dispatch('negotiationRfq/getNegotiationList',{rfqId:this.$route.params.quotationId})
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }
        } catch (error) {
          this.modal.modalLoading = false
          this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
        }
      },
      
      async clickPreviewFptn(){
        try {
          this.modal.modalLoading = true
          const successValidate = await this.$refs.formDealBan.validate()
          const MESSAGE_ERROR_VALIDATE = this.messageErrorValidate()
          const checkErrorLength = this.checkErrorLength()
          const MESSAGE_ERROR_LENGTH = this.messageErrorLength()
          if (!successValidate || !checkErrorLength) {
            this.modal.modalLoading = false
            const message = MESSAGE_ERROR_VALIDATE && MESSAGE_ERROR_LENGTH ? `, ${MESSAGE_ERROR_LENGTH}` : MESSAGE_ERROR_LENGTH
            this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE + message }
            return
          }
          const payload = this.payloadFptn()
          const response = await this.$store.dispatch('negotiationRfq/postFptnPreview', payload)
          if (response?.status < 300 && response?.status >= 200) {
            this.modal.modalLoading = false
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }
        } catch (error) {
          this.modal.modalLoading = false
          this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
        }
      },
      async clickSubmitFptn(){
        try {
          this.modal.modalLoading = true
          const successValidate = await this.$refs.formDealBan.validate()
          const checkErrorValidate = this.checkErrorValidate()
          const MESSAGE_ERROR_VALIDATE = this.messageErrorValidate()
          const checkErrorLength = this.checkErrorLength()
          const MESSAGE_ERROR_LENGTH = this.messageErrorLength()
          if (!successValidate || !checkErrorValidate || !checkErrorLength) {
            this.modal.modalLoading = false
            const message = MESSAGE_ERROR_VALIDATE && MESSAGE_ERROR_LENGTH ? `, ${MESSAGE_ERROR_LENGTH}` : MESSAGE_ERROR_LENGTH
            this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE + message }
            this.clickToggleSubmit(false)
            return
          }
          const payload = this.payloadFptn()
          const response = await this.$store.dispatch('negotiationRfq/postNegotiationFPTN', payload)
          if (response?.status < 300 && response?.status >= 200) {
            this.modal.modalLoading = false
            this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_SUBMIT_FPTN }
            this.isOpenModalSendEmail = false
            this.clickToggleSubmit(false)
            this.$store.commit('projectLog/SET_RFQ_LOG_ID', this.projectLogRfq?.rfqLogs?.at(-1)?.rfqLogId)
            this.$emit("refetch")
            setTimeout(()=>{
              this.$store.commit('negotiationRfq/SET_SHOW_FPTN', false)
            },300)
            this.$store.dispatch('negotiationRfq/getNegotiationList',{rfqId:this.$route.params.quotationId})
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }
        } catch (error) {
          this.clickToggleSubmit(false)
          this.modal.modalLoading = false
          this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
        }
      },
      async clickViewFPTN() {
        this.modal.modalLoading = true
        await this.$store.dispatch('negotiationRfq/getFptnReport', { negotiationId: this.negotiationDetail.offers[this.negotiationDetail.offers.length -1].negotiationId })
        this.modal.modalLoading = false
      },
      clickToggleMaximizeTable() {
        this.isOpenMaximizeTable = !this.isOpenMaximizeTable
      },
    
    },
    async mounted() {
      this.$store.dispatch('negotiationRfq/getNegotiationMasterTax')
      const responseOfferGet =  await this.$store.dispatch('negotiationRfq/getNegotiationFPTN',{negotiationId:this.negotiationDetail.offers[this.negotiationDetail.offers.length -1].negotiationId})
      const taxText = responseOfferGet.data.result?.tax?.ppn === 0 ? responseOfferGet.data.result?.tax?.taxType : `${responseOfferGet.data.result?.tax?.taxType} - ${responseOfferGet.data.result?.tax?.ppn}%`  
      this.form = {
        taxId: responseOfferGet.data.result?.tax?.taxId,
        taxText: taxText,
      }
      this.searchItpVerificator = this.negotiationFPTN.itpVerificatorName || ''
      this.searchApprovalItp = this.negotiationFPTN.directApproverItpName || ''
      this.searchRepresentatifGsit = this.negotiationFPTN.representGsitName || ''
      this.searchApprovalGsit = this.negotiationFPTN.directApproverGsitName || ''
      this.searchRepresentatifUkkp = this.negotiationFPTN.representUkkpName || ''
      this.searchApprovalUkkp = this.negotiationFPTN.directApproverUkkpName || ''
    },
    destroyed() {
      this.$store.commit('negotiationRfq/SET_SHOW_FPTN', false)
      if(this.showEditFptn) this.$store.commit('negotiationRfq/SET_SHOW_EDIT_FPTN', false)
    },
    components: {
      MaximizeTable,
      Tiptap,
      AutocompleteNewSolutip,
      NegotiationTableFptn,
      FptnSaveAsDraft,
      SubmitFPTN,
      InputDisabled
    }
  }
</script>