<template>
  <div>
    <ButtonGista :class="quotationDetailContainerWidth < 640 ? 'w-full' : 'w-max'" type="secondary" color="primary" @click="changeIsOpen(true)">Save As Draft</ButtonGista>
    <ModalConfirm 
      title="Simpan sebagai Draft" 
      message="Anda dapat melanjutkan BAN di detail negosiasi" 
      icon="saved" textButton="Save As Draft" 
      :isOpen="isOpenBanSaveAsDraft" 
      @changeIsOpen="changeIsOpen($event)" 
      @click="clickSaveAsDraft">
    </ModalConfirm>
  </div>
</template>

<script>
  import ModalConfirm from '@/core/components/modal/Confirm'

  export default {
    name: 'DealSaveAsDraft',
    props: ['isOpenBanSaveAsDraft'],
    computed: {
      quotationDetailContainerWidth() {
        return this.$store.state.width.quotationDetailContainerWidth
      },
    },
    methods: {
      changeIsOpen(val) {
        this.$emit('clickToggle',val)
      },
      clickSaveAsDraft() {
        this.$emit('click')
      }
    },
    components: {
      ModalConfirm
    }
  }
</script>