<template>
	<div>
		<div class="flex flex-wrap w-full gap-3.5 md:justify-between mb-6">
			<div class="flex flex-wrap gap-3.5 w-full lg:w-max">
				<select-dashboard class="flex-auto w-full sm:w-28 lg:flex-none"
					label="Type:"
					:placeholder="filterProject.selectedType"
					@change="changeTypeWithResetPagination"
					:options="['All', 'RFP', 'RFQ']">
				</select-dashboard>
				<date-picker-custom class="flex-auto w-full sm:w-72 lg:flex-none"
					@input="updateDateWithResetPagination($event)"
					mode="date"
					:max-date="maxDate"
					placeholder="Select Date"
					:value="filterProject.formDate"
					size="large"
					is-range>
				</date-picker-custom>
				<select-dashboard
					class="flex-auto w-full sm:w-80 lg:flex-none"
					label="Status:"
					:placeholder="filterProject.selectedStatus"
					@change="changeStatusWithResetPagination"
					:options="statusOptions">
					<template #selected>
						{{filterProject.selectedStatus}}
					</template>
				</select-dashboard>
			</div>
			<div class="flex flex-wrap w-full sm:flex-nowrap lg:w-max sm:grid sm:gap-3 gap-3.5 sm:grid-cols-2">
				<select-dashboard class="relative flex-auto w-full"
					label="Sort:"
					:placeholder="filterProject.selectedSort"
					@change="changeSortWithResetPagination"
					:options="['Project ID: Newest', 'Project ID: Oldest', 'Date Created: Newest', 'Date Created: Oldest']">
				</select-dashboard>
				<InputGista type=text
					size="large"
					placeholder="Search"
					v-model="filterProject.search"
					@input="searchWithResetPagination"
					class="flex flex-col flex-auto w-full">
					<template #icon-left>
						<span class="text-xl icon-search text-primary"></span>
					</template>
				</InputGista>
			</div>
		</div>
		<div class="flex flex-col">
			<div v-if="projectLogStatus?.content?.length > 0 && !isLoading">
				<Card v-for="(project, i) in projectLogStatus?.content"
					:key="i"
					class="flex flex-col items-center justify-between gap-3.5 w-full mb-6 border lg:flex-row border-gray-lightest" padding="p-3.5 sm:px-6 sm:py-4">
					<div class="flex flex-col gap-3.5 w-full lg:w-3/4 ">
						<div class="flex flex-wrap gap-3.5">
							<div class="flex flex-wrap gap-3.5 w-full">
								<div class="flex-none pr-4 border-r sm:w-36 border-gray-lightest">
									<label class="text-sm">Project ID</label>
									<h5 class="text-lg text-primary-darkest">{{ project?.projectId ?? '-' }}</h5>
								</div>
								<div class="flex-none pr-4 border-0 border-r sm:w-60 border-gray-lightest">
									<label class="text-sm">No. {{project?.projectRequestType === 'RFP'?'RFP':'RFQ'}}</label>
									<h5 class="text-lg text-secondary">{{ project?.noRequest ?? '-'	}}</h5>
								</div>
								<div class="flex-1 w-full">
									<label class="text-sm">{{project?.projectRequestType === 'RFP'?'Project Name': 'Request Title'}}</label>
									<h5 class="text-lg break-words">{{ project?.projectRequestType === 'RFP'? project?.projectName : project?.projectTitle }}</h5>
								</div>
							</div>
						</div>
						<div :class="`${windowWidth > 1440? '' : 'flex-col'} flex gap-3.5`">
							<div class="flex gap-3.5 flex-wrap">
								<div class="flex gap-3.5 items-center pr-4 mb-2 border-r sm:mb-0 border-gray-lightest">
									<span class="text-xl icon-user"></span>
									<div class="">
										<label class="text-xs">Technical Coordinator</label>
										<h5 class="text-sm">{{ project?.technicalCoordinatorName }}</h5>
									</div>
								</div>
								<div class="flex gap-3.5 items-center pr-4 mb-2 border-0 border-r sm:mb-0 border-gray-lightest">
									<span class="text-xl icon-calendar"></span>
									<div class="">
										<label class="text-xs">Date Created</label>
										<h5 class="text-sm">{{ project?.createdAt }}</h5>
									</div>
								</div>
							</div>
							<div class="flex gap-3.5 items-center">
								<span class="text-xl icon-clock"></span>
								<label class="text-sm">Current Status:</label>
								<div class="px-4 py-2 rounded-full" :class="colorStatus(project?.statusProject)">
									<p class="text-xs text-center text-white">{{project?.isInProgress? 'On Process' : project?.currentStatus}}</p>
								</div>
							</div>
						</div>
					</div>
					<div class="flex justify-center w-full lg:justify-end lg:w-1/4">
						<ButtonGista :isDark="false"
							color="primary"
							type="secondary"
							customClass="w-full lg:w-max"
							@click="seeDetail(project.projectRequestId, project.projectRequestType)"
							>
							See details
						</ButtonGista>
					</div>
				</Card>
			</div>
			<div v-if="projectLogStatus?.content?.length === 0 && !isLoading"
				class="flex flex-col items-center justify-center h-80">
				<img src="@/core/assets/icons/message/noitems.svg"
					alt="error"
					class="w-20" />
				<div class="mt-3">
					<p class="text-gray">Tidak Ada Data</p>
				</div>
			</div>
			<ProjectLoading v-if="isLoading"/>
		</div>
		<div v-if="projectLogStatus?.content?.length > 0"
			class="flex justify-center">
			<PaginationGista :current="filterProject.currentPage"
				:total="filterProject.totalRecords"
				:per-page="+filterProject.pageCount"
				@page-changed="handlePageChange($event)"
				@paging-change="handlePagingChange($event)"></PaginationGista>
		</div>
		<Spp :isOpen="isOpenModal" @toggleModal="toggleModal" :detailProject="detailProject" />
	</div>
</template>

<script>
import Card from '@/core/components/custom/Card.vue'
import DatePickerCustom from '@/core/components/custom/DatePicker.vue';
import SelectDashboard from '@/core/components/custom/SelectDashboard.vue'
import ProjectLoading from '@/core/components/dashboard/ProjectLoading.vue';
import { statusOutstandingMap } from '@/core/constant/filterStatus'
import { PATH_ROOT, PROJECT_DETAIL, QUOTATION_DETAIL } from '@/core/constant/routeName'
import { SOLUTIP_OPR_LOG, SOLUTIP_OPR_KP } from '@/core/constant/applicationRoleName.js'
import { formatDate } from '@/core/components/helpers/format.js'
import Spp from '@/core/components/dashboard/Spp.vue';
import { SPP_PROCESS } from '@/core/constant/statusProject'

export default {
	name: "Outstanding",
	props: {
		windowWidth: Number,
	},
	data() {
		return {
			isLoading: false,
			timeout: null,
			PATH_ROOT,
			PROJECT_DETAIL,
			QUOTATION_DETAIL,
			isOpenModal: false,
			detailProject: {}
		}
	},
	computed: {
		maxDate() {
			return new Date()
		},
		projectLogStatus: {
			get() {
				return this.$store.state.projectLog.projectLogStatus
			},
			set(value) {
				this.$store.commit('projectLog/SET_PROJECT_LOG_STATUS', value)
			}
		},
		statusOptions() {
			let result = []
			result.push(statusOutstandingMap.allStatus)
			if (this.filterProject.selectedType === 'RFP') {
				if (!this.roleOprKp) {
					result.push(statusOutstandingMap.waitingForAppprovalGsit)
				}
				result.push(statusOutstandingMap.waitingForApprovalUkkp)
				result.push(statusOutstandingMap.waitingForItpReviewRfp)
				result.push(statusOutstandingMap.waitingForApprovalItp)
				result.push(statusOutstandingMap.praqualificationProcess)
				result.push(statusOutstandingMap.waitingVendorProposal)
				result.push(statusOutstandingMap.userReviewProposal)
				result.push(statusOutstandingMap.scoringProcess)
				result.push(statusOutstandingMap.negotiationProcess)
				result.push(statusOutstandingMap.approvalProcess)
				result.push(statusOutstandingMap.sktLoiRfp)
				result.push(statusOutstandingMap.rejected)
				result.push(statusOutstandingMap.sppProcess)
			}
			if (this.filterProject.selectedType === 'RFQ') {
				if (!this.roleOprKp) {
					result.push(statusOutstandingMap.waitingForApprovalGsitRfq)
				}
				result.push(statusOutstandingMap.waitingForApprovalUkkpRfq)
				result.push(statusOutstandingMap.waitingForItpReviewRfq)
				result.push(statusOutstandingMap.waitingForApprovalItp)
				result.push(statusOutstandingMap.negotiationProcess)
				result.push(statusOutstandingMap.approvalProcess)
				result.push(statusOutstandingMap.sktLoiRfq)
				result.push(statusOutstandingMap.rejected)
				result.push(statusOutstandingMap.sppProcess)

			}
			if (this.filterProject.selectedType === 'All') {
				if (!this.roleOprKp) {
					result.push(statusOutstandingMap.waitingForApprovalGsitRfx)
				}
				result.push(statusOutstandingMap.waitingForApprovalUkkpRfx)
				result.push(statusOutstandingMap.waitingForItpReview)
				result.push(statusOutstandingMap.waitingForApprovalItp)
				result.push(statusOutstandingMap.praqualificationProcess)
				result.push(statusOutstandingMap.waitingVendorProposal)
				result.push(statusOutstandingMap.userReviewProposal)
				result.push(statusOutstandingMap.scoringProcess)
				result.push(statusOutstandingMap.negotiationProcess)
				result.push(statusOutstandingMap.approvalProcess)
				result.push(statusOutstandingMap.sktLoi)
				result.push(statusOutstandingMap.rejected)
				result.push(statusOutstandingMap.sppProcess)

			}
			return result
		},
		filterProject: {
			get() {
				return this.$store.state.dashboard.filterProject
			},
			set(val) {
				this.$store.commit('dashboard/SET_FILTER_PROJECT', val)
			}
		},
		activeButton: {
			get() {
				return this.$store.state.projectLog.activeButton
			},
			set(value) {
				this.$store.commit('projectLog/SET_ACTIVE_BUTTON', value)
			}
		},
		paramProjectId: {
			get() {
				return this.$store.state.projectDetail.paramProjectId
			},
			set(value) {
				this.$store.commit('projectDetail/SET_PARAM_PROJECTID', value)
			}
		},
		menu() {
			return this.$store.state.dashboard.menu
		},
		roleOprKp() {
			const roleName = this.menu[0]?.applicationRoleName
			return roleName === SOLUTIP_OPR_KP
		},
		isOprLog() {
			return this.menu[0]?.applicationRoleName === SOLUTIP_OPR_LOG
		}

	},
	watch: {
		projectLogStatus: {
			handler() {
				this.updatePagination()
			},
			deep: true
		},
	},
	methods: {
		formatDate,
		async seeDetail(id, type) {
			const res = await this.$store.dispatch('projectLog/getCheckAccess', { projectRequestId: id, projectRequestType: type, home: true })
			if (res?.data.code === 400) {
				this.detailProject = JSON.parse(res?.data.message)
				return this.toggleModal()
			}
			const path = type === 'RFP' ? PROJECT_DETAIL : QUOTATION_DETAIL
			this.$router.push(`/${PATH_ROOT}/${path}/${id}`).catch(() => ({}))
		},
		toggleModal() {
			this.isOpenModal = !this.isOpenModal
		},
		updateChange() {
			this.routerPushQuery()
			this.getProjectLogStatus()
		},
		async routerPushQuery() {
			const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
			this.$router.push({query}).catch(() => ({}))
		},
		async handlePageChange(e) {
			this.filterProject.currentPage = e
			this.routerPushQuery()
			this.getProjectLogStatus()
		},
		async handlePagingChange(e) {
			this.filterProject.pageCount = e
			this.routerPushQuery()
			this.getProjectLogStatus()
		},
		async getProjectLogStatus() {
			this.isLoading = true
			const res = await this.$store.dispatch('projectLog/getProjectLogStatus', { projectRequestType: this.filterProject.type, projectStatus: 'outstanding', status: this.filterProject.status, dateFrom: this.filterProject.formDate.start, dateTo: this.filterProject.formDate.end, anySearch: this.filterProject.search, page: this.filterProject.currentPage, sort: this.filterProject.sort, size: this.filterProject.pageCount })
			if (res?.status === 200) {
				this.isLoading = false
			}
		},
		updateDate(e) {
			this.filterProject.currentPage = this.$route.query.page
			this.filterProject.formDate = e
		},
		changeType(e) {
			this.filterProject.selectedType = e
			if (e === 'RFP') {
				this.filterProject.type = 'rfp'
			} else if (e === 'RFQ') {
				this.filterProject.type = 'rfq'
			} else {
				this.filterProject.type = ''
			}
		},
		changeStatus(e) {
			this.filterProject = { ...this.filterProject, status: this.statusOptions[this.statusOptions.findIndex(el => el.text === e)].value, selectedStatus: e }
		},
		changeSort(e) {
			if (e === 'Project ID: Newest') {
				this.filterProject.sort = 'projectId,desc'
				this.filterProject.selectedSort = 'Project ID: Newest'
			} else if (e === 'Project ID: Oldest') {
				this.filterProject.sort = 'projectId,asc'
				this.filterProject.selectedSort = 'Project ID: Oldest'
			} else if (e === 'Date Created: Newest') {
				this.filterProject.sort = 'createdAt,desc'
				this.filterProject.selectedSort = 'Date Created: Newest'
			} else if (e === 'Date Created: Oldest') {
				this.filterProject.sort = 'createdAt,asc'
				this.filterProject.selectedSort = 'Date Created: Oldest'
			}
		},
		searchBounce() {
			if (this.timeout) {
				clearTimeout(this.timeout)
			}
			this.timeout = setTimeout(() => {
				this.updateChange()
			}, 500)
		},
		changeTypeWithResetPagination(e) {
			this.changeStatus(statusOutstandingMap.allStatus.text)
			this.changeType(e)
			this.resetPagination()
			this.updateChange()
		},
		updateDateWithResetPagination(e) {
			this.updateDate(e)
			this.resetPagination()
			this.updateChange()
		},
		changeStatusWithResetPagination(e) {
			this.changeStatus(e.text)
			this.resetPagination()
			this.updateChange()
		},
		searchWithResetPagination() {
			this.searchBounce()
			this.resetPagination()
		},
		changeSortWithResetPagination(e) {
			this.changeSort(e)
			this.resetPagination()
			this.updateChange()
		},
		resetPagination() {
			this.filterProject.currentPage = 1
			this.filterProject.pageCount = 10
		},
		updatePagination() {
			this.filterProject.currentPage = this.projectLogStatus?.pageable?.pageNumber + 1
			this.filterProject.totalRecords = this.projectLogStatus?.totalElements
			this.filterProject.pageCount = this.projectLogStatus?.size
		},
		updateFilter() {
			if (this.$route.query.page) this.filterProject.currentPage = +this.$route.query.page
			if (this.$route.query.size) this.filterProject.pageCount = +this.$route.query.size
			if (this.$route.query.status) this.changeStatus(this.$route.query.status)
			if (this.$route.query.start || this.$route.query.end) this.updateDate({ start: this.$route.query.start, end: this.$route.query.end })
			if (this.$route.query.sort) this.changeSort(this.$route.query.sort)
			if (this.$route.query.search) this.filterProject.search = this.$route.query.search
			if (this.$route.query.type) this.changeType(this.$route.query.type)
		},
		colorStatus(statusProject) {
			if (statusProject === SPP_PROCESS) {
				return 'bg-error'
			} else {
				return 'bg-warning-dark'
			}
		}
	},
	async mounted() {
		this.updateFilter()
		this.getProjectLogStatus()
		this.updatePagination()
	},
	components: {
		Card,
		DatePickerCustom,
		SelectDashboard,
		ProjectLoading,
		Spp
	}
}
</script>