<template>
  <div class="w-full">
    <div class="relative flex flex-wrap items-center w-full gap-6 my-6"  >
      <span class="flex-1 text-2xl cursor-pointer icon-arrow-left text-gray" @click="() => isOpenCancel = true"></span>
      <ModalConfirm 
        title="Perubahan Batalkan Pembuatan Persetujuan" 
        message="Informasi yang diisi akan hilang, apakah Anda yakin?" 
        icon="rejected" 
        textButton="Yes" 
        :isOpen="isOpenCancel" 
        @changeIsOpen="() => isOpenCancel = false" 
        @click="clickCancel"
        colorButton="error"></ModalConfirm>
      <h4 class="flex flex-wrap justify-center flex-1 flex-auto text-lg font-medium text-gray-darkest">Create Procurement Approval</h4>
      <button @click="expandCollapseAll" class="flex justify-end flex-1 underline cursor-pointer text-tertiary hover:text-tertiary-dark" >{{messageCollapseAll}}</button>
    </div>
    <TemplateFormProcurementApproval :collapse="collapse" @changeCollapse="changeCollapse" :showRecommendation="true"/>
    <div class="pt-6 mt-6 border-t-2 border-gray-lightest ">
      <div class="flex" :class="projectDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
        <div class="flex-auto">
          <ButtonGista type="secondary" :class="projectDetailContainerWidth > 640? '' : 'w-full'" color="primary" @click="() => isOpenSaveAsDraft = true">Save As Draft</ButtonGista>
          <ModalConfirm 
            title="Simpan sebagai Draft" 
            message="Anda dapat melanjutkan draft di tab ‘Approval’" 
            icon="saved" textButton="Save As Draft" 
            :isOpen="isOpenSaveAsDraft" 
            @changeIsOpen="() => isOpenSaveAsDraft = false" 
            @click="saveAsDraft">
          </ModalConfirm>
        </div>
        <div class="flex-none gap-6" :class="projectDetailContainerWidth > 640 ? 'grid grid-cols-2' : 'flex flex-col'">
          <ButtonGista type="secondary" color="primary" @click="clickPreview">Preview</ButtonGista>
          <ButtonGista v-if="!isSubmitted" type="primary" class="w-full" color="success" @click="() => isOpenSubmit = true" >Create</ButtonGista>
          <ModalConfirm 
            title="Buat Persetujuan" 
            message="Pastikan seluruh informasi sudah benar" 
            icon="submitted" 
            textButton="Create" 
            :isOpen="isOpenSubmit" 
            :isBackButton="true"
            @changeIsOpen="() => isOpenSubmit = false"
            :hideClose="false"
            @click="submitApproval"
            colorButton="success"></ModalConfirm>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MESSAGE_SUCCESS_CREATE_APPROVAL } from '@/core/constant/successMessage'
import { MESSAGE_POPUP_VALIDATION_SUBMIT, MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'
import ModalConfirm from '@/core/components/modal/Confirm'
import { PATH_ROOT } from '@/core/constant/routeName'
import TemplateFormProcurementApproval from '@/core/components/rfp/procurementApproval/TemplateFormProcurementApproval.vue'
import { TERPILIH } from '@/core/constant/procurementApproval.js'

export default {
  name: "CreateProcurementApproval",
  data() {
    return {
      messageCollapseAll: 'Collapse All',
      collapse: {
        information: true,
        negoResults: true,
        vendorCandidateScoring: true,
        projectApproval: true,
        costBudget: true,
        recommendation: true,
        checker: true,
        approver: true,
        attachments: true,
      },
      isOpenSaveAsDraft: false,
      isOpenSubmit: false,
      isOpenCancel: false,
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    errorMaxLength: {
      get() {
        return this.$store.state.procurementApproval.errorMaxLength
      },
      set(val) {
        this.$store.commit('procurementApproval/SET_ERROR_MAX_LENGTH', val)
      }
    },
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApproval.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApproval/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
    isSubmitted() {
      return this.dataProcurementApproval.isSubmitted
    }
  },
  methods:{
    //function collapse/extend
    changeCollapse(key) {
      this.collapse[key] = !this.collapse[key]
      if (Object.values(this.collapse).every(el => el)) this.messageCollapseAll = 'Collapse All'
      else if (Object.values(this.collapse).every(el => !el)) this.messageCollapseAll = 'Extend All'
    },
    expandCollapseAll() {
      for (const key in this.collapse) {
        if (this.messageCollapseAll === 'Extend All') {
          this.collapse[key] = true
        } else {
          this.collapse[key] = false
        }
      }
      if (this.messageCollapseAll === 'Collapse All') this.messageCollapseAll = 'Extend All'
      else this.messageCollapseAll = 'Collapse All'
    },
    async saveAsDraft() {
      try {
        this.modal.modalLoading = true
        const checked = await this.validationDraft()
        if(!checked) return this.modal.modalLoading = false;
        const response = await this.$store.dispatch('procurementApproval/postApproval', { isSubmit: false, })
        if (response?.status < 300) {
          this.$emit('setShowButtonContinueDraft', true)
          await this.$store.dispatch('procurementApproval/getApproval', { rfpId: this.$route.params.projectId })
        }
        this.modal.modalLoading = false
      } catch (e) {
        this.modal.modalLoading = false
      }
    },
    async setErrorStatusVendor () {
      const msg = 'Wajib memilih 1 Vendor'
      const vendors = this.dataProcurementApproval.candidateScoringList
      const selected = vendors.some(e => {
        if(e.localPartnerList) {
          return e.localPartnerList.some(local => local.candidateScoringDetails.status === TERPILIH) || e.candidateScoringDetails.status === TERPILIH 
        }
        return e.candidateScoringDetails.status === TERPILIH
      } )
      if(!selected) {
        for(const e of vendors) {
          e.error = msg
          if(e.localPartnerList) {
            for(const local of e.localPartnerList) {
              local.error = msg
            }
          }
        }
      }
      return !selected
    },
    async submitApproval() {
      try {
        this.modal.modalLoading = true
        const errorInformation = await this.checkErrorInformationSubmit()
        const checkErrorStatusVendor = await this.setErrorStatusVendor()
        const MESSAGE_ERROR_SELECT_ONE = checkErrorStatusVendor? ['Status Vendor'] : []
        const checkErrorNegotiationResult = await this.setErrorNegotiationResultSubmit()
        const errorVendorCandidateScoring = await this.setErrorVendorCandidateScoringSubmit()
        const checkErrorRecommendation = await this.$store.dispatch('procurementApproval/checkErrorRecommendation')
        const MESSAGE_ERROR_RECOMMENDATION = await this.$store.dispatch('procurementApproval/messageErrorRecommendation')
        const checkErrorCostBudgetInformation = await this.$store.dispatch('procurementApproval/checkErrorCostBudgetInformation', { isSubmit: true })
        const MESSAGE_ERROR_COST_BUDGET_INFORMATION = !checkErrorCostBudgetInformation? ['Cost & Budget Information'] : []
        if (errorInformation.length || checkErrorStatusVendor || checkErrorNegotiationResult.length || errorVendorCandidateScoring.length || !checkErrorRecommendation  || !checkErrorCostBudgetInformation) {
          this.modal.modalLoading = false
          const MESSAGE_ERROR_INFORMATION = errorInformation
          const MESSAGE_ERROR_NEGOTIATION_RESULT = checkErrorNegotiationResult
          const MESSAGE_ERROR_VENDOR_CANDIDATE_SCORING = errorVendorCandidateScoring
            const message = [ ...MESSAGE_ERROR_INFORMATION, ...MESSAGE_ERROR_NEGOTIATION_RESULT, ...MESSAGE_ERROR_SELECT_ONE, ...MESSAGE_ERROR_VENDOR_CANDIDATE_SCORING, ...MESSAGE_ERROR_COST_BUDGET_INFORMATION, ...MESSAGE_ERROR_RECOMMENDATION].join(', ')
            this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + message }
            this.isOpenSubmit = false
            return
				}
        const response = await this.$store.dispatch('procurementApproval/postApproval', { isSubmit: true })
        if (response?.status < 300) {
          this.$emit('refetchApprovalLog')
          this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_CREATE_APPROVAL }
        }
        this.modal.modalLoading = false
      } catch (e) {
			  this.modal.modalLoading = false
      }
    },
    checkErrorInformationSubmit() {
      let error = []
      if (this.dataProcurementApproval.introductionBackground?.length === 0 || this.dataProcurementApproval.introductionBackground === null) {
        this.errorMaxLength['Introduction / Background & Needs'] = MESSAGE_EMPTY_FIELD
      }
      if (this.dataProcurementApproval.projectGoals?.length === 0 || this.dataProcurementApproval.projectGoals === null) {
        this.errorMaxLength['Project Goals'] = MESSAGE_EMPTY_FIELD
      }
      if (this.errorMaxLength['Introduction / Background & Needs']) {
        error.push('Introduction / Background & Needs')
      }
      if (this.errorMaxLength['Project Goals']) {
        error.push('Project Goals')
      }
      return error
    },
    checkErrorSaveAsDraft() {
      let error = []
      if (this.errorMaxLength['Introduction / Background & Needs'] && this.errorMaxLength['Introduction / Background & Needs'] !== MESSAGE_EMPTY_FIELD) {
        error.push('Introduction / Background & Needs')
      }
      if (this.errorMaxLength['Project Goals'] && this.errorMaxLength['Project Goals'] !== MESSAGE_EMPTY_FIELD) {
        error.push('Project Goals')
      }
      return error
    },
    setErr(data, error) {
      const noteInternalBca = 'Note Internal BCA'
      const exchangeRate = 'Exchange Rate'
      const selected = data.negotiationResultDetails?.resultItemList?.some(e => e.isSelected)
      if ((data.negotiationResultDetails.exchangeRate === 0 || !data.negotiationResultDetails.exchangeRate) && selected && data.negotiationResultDetails.currency?.toLowerCase() !== 'idr') data.errorMaxLength[exchangeRate] = 'Minimal 1'
      else if(data.errorMaxLength[exchangeRate] !== 'Maksimal 10 digit') data.errorMaxLength[exchangeRate] = ''
      if(data.errorMaxLength[noteInternalBca] && !error.some(e => e === noteInternalBca)) error.push(noteInternalBca)
      if(data.errorMaxLength[exchangeRate] && !error.some(e => e === exchangeRate)) error.push(exchangeRate)
    },
    setErrorNegotiationResultSubmit() {
      let error = []
      for (const n of this.dataProcurementApproval.negotiationResultList) {
        if (n.localPartnerList === null) {
          this.setErr(n, error)
        } else {
          for (const l of n.localPartnerList) {
            this.setErr(l, error)
          }
        }
      }
      return error.sort()
    },
    checkErrorNegotiationResultSaveAsDraft() {
      let error = []
      const noteInternalBca = 'Note Internal BCA'
      const exchangeRate = 'Exchange Rate'
      function setErr (data) {
        if(data.errorMaxLength[exchangeRate] && ![MESSAGE_EMPTY_FIELD, 'Minimal 1'].includes(data.errorMaxLength[exchangeRate]) && !error.some(e => e === exchangeRate)) error.push(exchangeRate)
        if(data.errorMaxLength[noteInternalBca] && data.errorMaxLength[noteInternalBca] !== MESSAGE_EMPTY_FIELD && !error.some(e => e === noteInternalBca)) error.push(noteInternalBca)
      }
      for (const n of this.dataProcurementApproval.negotiationResultList) {
        if (n.localPartnerList === null) {
          setErr(n)
        } else {
          for (const l of n.localPartnerList) {
            setErr(l)
          }
        }
      }
      return error.sort()
    },
    setErrorVendorCandidateScoringSubmit() {
      let error = []
      if (this.dataProcurementApproval.vendorSelectionReason === null || this.dataProcurementApproval.vendorCandidateScoring?.length === 0) {
        this.errorMaxLength['Reason for Vendor Selection'] = MESSAGE_EMPTY_FIELD
        error.push('Reason for Vendor Selection')
      }
      return error
    },
    checkErrorVendorCandidateScoringSaveAsDraft() {
      let error = []
      if (this.errorMaxLength['Reason for Vendor Selection'] && this.errorMaxLength['Reason for Vendor Selection'] !== MESSAGE_EMPTY_FIELD) {
        error.push('Reason for Vendor Selection')
      }
      return error
    },
    async validationDraft() {
      const errorInformation = await this.checkErrorSaveAsDraft()
      const errorNegotiationResult = await this.checkErrorNegotiationResultSaveAsDraft()
      const errorVendorCandidateScoring = await this.checkErrorVendorCandidateScoringSaveAsDraft()
      const checkErrorCostBudgetInformation = await this.$store.dispatch('procurementApproval/checkErrorCostBudgetInformation', { isSubmit: false })
      const MESSAGE_ERROR_COST_BUDGET_INFORMATION = !checkErrorCostBudgetInformation? ['Cost & Budget Information'] : []
      if (errorInformation.length || errorNegotiationResult.length || !checkErrorCostBudgetInformation) {
        this.modal.modalLoading = false
        const MESSAGE_ERROR_INFORMATION = errorInformation
        const MESSAGE_ERROR_NEGOTIATION_RESULT = errorNegotiationResult
        const MESSAGE_ERROR_VENDOR_CANDIDATE_SCORING = errorVendorCandidateScoring
        const message = [ ...MESSAGE_ERROR_INFORMATION, ...MESSAGE_ERROR_NEGOTIATION_RESULT, ...MESSAGE_ERROR_VENDOR_CANDIDATE_SCORING, ...MESSAGE_ERROR_COST_BUDGET_INFORMATION ].join(', ')
        this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + message }
        this.isOpenSaveAsDraft = false
        return false
      } else {
        return true
      }
    },
    async clickPreview() {
      this.modal.modalLoading = true;
      const checked = await this.validationDraft()
      if(!checked) return this.modal.modalLoading = false;
      await this.$store.dispatch('procurementApproval/postApprovalPreview', { isSubmit: false })
      this.modal.modalLoading = false;
    },
    async backToHome() {
      const query = await this.$store.dispatch('dashboard/setQueryBacktoHome')
			this.$router.push({ path: `/${PATH_ROOT}`, query }).catch(() => ({}))
    },
    async clickCancel() {
      if (!this.dataProcurementApproval.id) {
        this.backToHome()
        return
      }
      const response = await this.$store.dispatch('procurementApproval/cancelProcurementApproval', { approvalId: this.dataProcurementApproval.id })
      if (response?.status < 300) {
        this.backToHome()
      }
    }
  },
  async mounted() {
    const res = await this.$store.dispatch('procurementApproval/getApproval', { rfpId: this.$route.params.projectId })
    if (res?.status === 200) {
      if (this.dataProcurementApproval.id > 0) {
        this.$store.dispatch('procurementApproval/getApprovalLog', { approvalId: this.dataProcurementApproval.id })
      }
    }
  },
  components:{
    ModalConfirm,
    TemplateFormProcurementApproval
  }
}
</script>

