<template>
	<div>
		<ButtonGista @click="toggle"
			type="secondary"
			color="secondary"
			:disabled="proposalInformation?.isCanceled"
			:class="projectDetailContainerWidth < 640 ? 'w-full' : 'w-40'">
			Send Email
		</ButtonGista>
		<GistaModal :value="isOpen"
			width="2/3"
			scrollable
			@input="closeModal"
			customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl">
			<ValidationObserver ref="sendEmail">
				<div v-if="qualification?.isNewVendor" class="px-6 py-6">
					<div class="flex justify-center mb-6">
						<h4 class="text-3xl font-semibold">Send Email</h4>
					</div>
					<div class="grid w-full grid-cols-1 gap-6 mb-4 lg:grid-cols-2">
						<InputGista type="text"
							size="large"
							label="No. RFI"
							placeholder="(auto-generate)"
							disabled
							v-model="formSendEmail.noRfi" />
						<InputGista type="text"
							size="large"
							label="Date"
							disabled
							v-model="formSendEmail.date" />
						<InputGista type="text"
							size="large"
							label="PIC RFP"
							disabled
							v-model="formSendEmail.staffItpName" />
						<InputGista type="text"
							size="large"
							label="Email PIC RFP"
							disabled
							v-model="formSendEmail.staffItpEmail" />
						<ValidationProvider name="Phone"
							rules="required|maxDigit:15"
							v-slot="{ errors }">
							<InputSolutip type="text"
								size="large"
								placeholder="02125563000"
								label="Telepon PIC RFP"
								v-model="formSendEmail.phone"
								@keypress="validationPhone($event, formSendEmail.phone)"
								:mandatory="true"
								:error="!!errors[0]" >
								<template #message>
									<p>{{ errors[0] }}</p>
							</template>
							</InputSolutip>
						</ValidationProvider>
						<ValidationProvider name="Ext"
							rules="required|maxDigit:7"
							v-slot="{ errors }">
							<InputSolutip type="text"
								size="large"
								placeholder="1012345"
								label="Ext"
								:mandatory="true"
								v-model="formSendEmail.ext"
								@keypress="validationNumber"
								:error="!!errors[0]" >
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</InputSolutip>
						</ValidationProvider>
						<InputGista type="text"
							size="large"
							label="Vendor Name"
							:disabled="true"
							v-model="formSendEmail.vendorName" />
						<InputGista type="text"
							size="large"
							label="Vendor Email"
							:disabled="true"
							v-model="formSendEmail.vendorEmail" />
					</div>
					<div class="w-full my-6">
						<div class="mb-4 text-sm font-medium text-gray">
							Documents Language
						</div>
						<div class="flex">
							<RadioGista :modelValue="formSendEmail.isEnglish" :nativeValue="false" @change="changeLanguage" class="mr-3 text-gray">BAHASA INDONESIA</RadioGista>
							<RadioGista :modelValue="formSendEmail.isEnglish" :nativeValue="true" @change="changeLanguage" class="text-gray">ENGLISH</RadioGista>
						</div>
					</div>
					<div class="w-full mb-4">
						<h6 class="mb-4 text-sm font-medium text-gray" :class="emptyDoc ? 'text-error' : ''">Documents Template <span class="text-error">*</span> </h6>
						<div class="flex flex-wrap">
							<GistaCheckbox v-if="!isLocalVendor" @change="changeCheckBox($event, 'isSendItp01')" :modelValue="formSendEmail.isSendItp01" class="mr-6 text-gray">ITP 01</GistaCheckbox>
							<GistaCheckbox v-if="!isLocalVendor" @change="changeCheckBox($event, 'isSendItp02')" :modelValue="formSendEmail.isSendItp02" class="mr-6 text-gray">ITP 02</GistaCheckbox>
							<GistaCheckbox v-if="!isLocalVendor" @change="changeCheckBox($event, 'isSendItp03')" :modelValue="formSendEmail.isSendItp03" class="mr-6 text-gray">ITP 03</GistaCheckbox>
							<GistaCheckbox @change="changeCheckBox($event, 'isSendChecklist')" :modelValue="formSendEmail.isSendChecklist" class="mr-6 text-gray">CHECKLIST DOKUMENT</GistaCheckbox>
							<GistaCheckbox @change="changeCheckBox($event, 'isSendPendaftaran')" :modelValue="formSendEmail.isSendPendaftaran" class="mr-6 text-gray">PENDAFTARAN PIHAK EKSTERNAL</GistaCheckbox>
							<GistaCheckbox @change="changeCheckBox($event, 'isSendPernyataan')" :modelValue="formSendEmail.isSendPernyataan" class="mr-6 text-gray">PERNYATAAN KEPATUHAN REKANAN BCA</GistaCheckbox>
							<GistaCheckbox v-if="!isLocalVendor" @change="changeCheckBox($event, 'isSendDueDilligence')" :modelValue="formSendEmail.isSendDueDilligence" class="text-gray">DUE DILIGENCE</GistaCheckbox>
						</div>
						<div v-if="emptyDoc">
							<p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
						</div>
					</div>
					<div class="flex justify-end">
	
						<ButtonGista @click="sendEmail"
							class="w-full sm:w-60"
							color="secondary">
							Send Email</ButtonGista>
	
					</div>
				</div>

				<div v-else class="px-6 py-6">
					<div class="flex justify-center mb-6">
						<h4 class="text-3xl font-semibold">Send Email</h4>
					</div>
					<div class="grid w-full grid-cols-1 gap-6 mb-4 lg:grid-cols-2">
						<InputGista type="text"
							size="large"
							label="PIC RFP"
							disabled
							v-model="formSendEmail.staffItpName" />
						<InputGista type="text"
							size="large"
							label="Email PIC RFP"
							disabled
							v-model="formSendEmail.staffItpEmail" />						
						<ValidationProvider name="Phone"
							rules="required|maxDigit:15"
							v-slot="{ errors }">
							<InputSolutip type="text"
								size="large"
								placeholder="02125563000"
								label="Telepon PIC RFP"
								:mandatory="true"
								v-model="formSendEmail.phone"
								@keypress="validationPhone($event, formSendEmail.phone)"
								:error="!!errors[0]" >
								<template #message>
									<p>{{ errors[0] }}</p>
							</template>
							</InputSolutip>
						</ValidationProvider>
						<ValidationProvider name="Ext"
							rules="required|maxDigit:7"
							v-slot="{ errors }">
							<InputSolutip type="text"
								size="large"
								placeholder="1012345"
								label="Ext"
								:mandatory="true"
								v-model="formSendEmail.ext"
								@keypress="validationNumber"
								:error="!!errors[0]" >
								<template #message>
									<p>{{ errors[0] }}</p>
								</template>
							</InputSolutip>
						</ValidationProvider>
						<InputGista type="text"
							label="Vendor Name"
							disabled
							v-model="formSendEmail.vendorName" />
						<InputGista type="text"
							label="Vendor Email"
							disabled
							v-model="formSendEmail.vendorEmail" />
						<InputGista type="text"
							label="Reference (for ITP03 & Due Diligence)"
							v-model="formSendEmail.reference"
							disabled
							class="col-span-2" />
					</div>
					<div class="w-full mt-4 mb-6">
						<div class="mb-4 text-sm font-medium text-gray">
							Documents Language
						</div>
						<div class="flex">
							<RadioGista :modelValue="formSendEmail.isEnglish" :nativeValue="false" @change="changeLanguage" class="mr-3 text-gray">BAHASA INDONESIA</RadioGista>
							<RadioGista :modelValue="formSendEmail.isEnglish" :nativeValue="true" @change="changeLanguage" class="text-gray">ENGLISH</RadioGista>
						</div>
					</div>
					<div class="w-full mb-4">
						<h6 class="mb-4 text-sm font-medium text-gray" :class="emptyDoc ? 'text-error' : ''">Documents Template <span class="text-error">*</span></h6>
						<div class="flex flex-wrap">
							<GistaCheckbox v-if="!isLocalVendor" @change="changeCheckBox($event, 'isSendItp01')" :modelValue="formSendEmail.isSendItp01" class="mr-6 text-gray">ITP 01</GistaCheckbox>
							<GistaCheckbox v-if="!isLocalVendor" @change="changeCheckBox($event, 'isSendItp02')" :modelValue="formSendEmail.isSendItp02" class="mr-6 text-gray">ITP 02</GistaCheckbox>
							<GistaCheckbox v-if="!isLocalVendor" @change="changeCheckBox($event, 'isSendItp03')" :modelValue="formSendEmail.isSendItp03" class="mr-6 text-gray">ITP 03</GistaCheckbox>
							<GistaCheckbox @change="changeCheckBox($event, 'isSendChecklist')" :modelValue="formSendEmail.isSendChecklist" class="mr-6 text-gray">CHECKLIST DOKUMENT</GistaCheckbox>
							<GistaCheckbox @change="changeCheckBox($event, 'isSendPendaftaran')" :modelValue="formSendEmail.isSendPendaftaran" class="mr-6 text-gray">PENDAFTARAN PIHAK EKSTERNAL</GistaCheckbox>
							<GistaCheckbox @change="changeCheckBox($event, 'isSendPernyataan')" :modelValue="formSendEmail.isSendPernyataan" class="mr-6 text-gray">PERNYATAAN KEPATUHAN REKANAN BCA</GistaCheckbox>
							<GistaCheckbox v-if="!isLocalVendor" @change="changeCheckBox($event, 'isSendDueDilligence')" :modelValue="formSendEmail.isSendDueDilligence" class="text-gray">DUE DILIGENCE</GistaCheckbox>
						</div>
						<div v-if="emptyDoc">
							<p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
						</div>
					</div>
	
					<div class="flex justify-end">
	
						<ButtonGista @click="sendEmail"
							class="w-full sm:w-60"
							color="secondary">
							Send Email</ButtonGista>
	
					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import { MESSAGE_SUCCESS_SEND_EMAIL } from '@/core/constant/successMessage'
import InputSolutip from '@/core/components/custom/Input'
import { formatDate } from '@/core/components/helpers/format.js'
import { MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'

export default {
	name: 'SendEmail',
	props: {
		qualification: Object,
		modalSendEmail: Boolean,
		isLocalVendor: Boolean
	},
	data() {
		return {
			isOpen: false,
			newVendor: true,
			MESSAGE_EMPTY_FIELD,
			emptyDoc: false,
			formSendEmail: {
				noRfi: '',
				date: '',
				vendorId: '',
				vendorName: '',
				vendorEmail: '',
				isNewVendor: false,
				staffItpId: '',
				staffItpName: '',
				staffItpEmail: '',
				phone: '',
				ext: '',
				isSendItp01: true,
				isSendItp02: true,
				isSendItp03: true,
				isSendChecklist: true,
				isSendPendaftaran: true,
				isSendPernyataan: true,
				isSendDueDilligence: true,
				isEnglish: false,
				notes: "",
				reference: "",
				noRfp: "",
				projectName: ""
    	},
		}
	},
	computed: {
		proposalInformation() {
      return this.$store.state.projectDetail.proposalInformation
    },
		vendor() {
			return this.$store.state.rfi.vendor
		},
		userProfile() {
			return this.$store.state.dashboard.userProfile
		},
		modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    projectDetailContainerWidth() {
			return this.$store.state.width.projectDetailContainerWidth
		},
	},
	methods: {
		formatDate,
		toggle() {
			this.isOpen = !this.isOpen
		},
		closeModal() {
			this.isOpen = !this.isOpen
			this.formSendEmail = {
				...this.formSendEmail,
				phone: '',
				ext: '',
				notes: '',
			}
			this.emptyDoc = false
		},
		changeCheckBox(e, key) {
			this.formSendEmail[key] = e
			if(this.emptyDoc) this.emptyDoc = false
		},
		validationNumber(e) {
			let keyCode = e.keyCode;
			if (keyCode < 48 || keyCode > 57) {
				e.preventDefault();
			}
		},
		validationPhone(e, phone) {
			let keyCode = e.keyCode;			
				
			if ((phone.length === 0 && keyCode !== 43 && (keyCode < 48 || keyCode > 57)) || 
				(phone.length !== 0 && (keyCode < 48 || keyCode > 57))) {
        		e.preventDefault();
    		}
		},
		async checkEmptyDoc() {
			const list = [
				'isSendItp01',
				'isSendItp02',
				'isSendItp03',
				'isSendChecklist',
				'isSendPendaftaran',
				'isSendPernyataan',
				'isSendDueDilligence',
			]
			const arr = list.map((el) => this.formSendEmail[el])
			const isNotEmpty = arr.some((el) => el)
			if(isNotEmpty) return false
			this.emptyDoc = true
			return true
		},
		async sendEmail() {
			try {
				//show loading
				
				const successValidate = await this.$refs.sendEmail.validate()
				const isEmpty = await this.checkEmptyDoc()
				if(!successValidate || isEmpty) return
				this.modal.modalLoading = true
				const data = this.formSendEmail
				const response = await this.$store.dispatch('qualification/postQualificationsSendDoc', data)
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false
					this.isOpen = false
					this.formSendEmail = {
						...this.formSendEmail,
						phone: '',
						ext: '',
						notes: '',
					}
					//show modal success send email qualification
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_SEND_EMAIL }
					this.$store.dispatch('qualification/getQualifications', this.$route.params.projectId)
					this.getVendor()
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				//loading out when error
				this.modal.modalLoading = false
				//show modal error send email qualification
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
		changeLanguage() {
			this.formSendEmail.isEnglish = !this.formSendEmail.isEnglish
		},
		async getVendor() {
			await this.$store.dispatch('rfi/getVendor', { vendorId: this.qualification.vendorId })
			const isLocalVendor = this.isLocalVendor
			this.formSendEmail = {
				noRfi: this.vendor[0].noRfi,
				date: this.formatDate(new Date()),
				vendorId: this.qualification.vendorId,
				vendorName: this.qualification.vendorName,
				vendorEmail: this.qualification.vendorEmail,
				isNewVendor: this.vendor[0].isNewVendor,
				staffItpId: this.userProfile.username,
				staffItpName: this.userProfile.name,
				staffItpEmail: this.userProfile.email,
				phone: "",
				ext: '',
				isSendItp01: !isLocalVendor,
				isSendItp02: !isLocalVendor,
				isSendItp03: !isLocalVendor,
				isSendChecklist: true,
				isSendPendaftaran: true,
				isSendPernyataan: true,
				isSendDueDilligence: !isLocalVendor,
				isEnglish: false,
				notes: "",
				reference: `${this.proposalInformation.noRfp} - ${this.proposalInformation.projectName}`,
				noRfp: this.proposalInformation.noRfp,
				projectName: this.proposalInformation.projectName,
				vendorCandidateId: +this.qualification.vendorCandidateId
			}
		}

	},
	async mounted() {
		this.getVendor()
	},
	components: {
		InputSolutip,
	}
}

</script>

<style scoped>
.max-height-custom {
	max-height: 40rem;
}
</style>