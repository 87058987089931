<template>
	<div class="w-full sm:w-max">
		<ButtonGista @click="toggle"
			type="primary"
			color="secondary"
			customClass="sm:w-max w-full sm:mt-0 mt-3">
			Create RFI
		</ButtonGista>
		<GistaModal :value="isOpen"
			width="2/3"
			@input="closeModal"
			scrollable
			customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-7xl">
			<ValidationObserver v-slot="{ handleSubmit }">
				<div class="w-full">
					<div class="p-6">
						<h3 class="flex justify-center mb-6 text-lg font-semibold">Send RFI</h3>
						<div class="grid w-full grid-cols-1 gap-6 mb-6 lg:grid-cols-2">
							<InputGista type="text"
								size="large"
								label="No. RFI"
								placeholder="(auto-generate)"
								disabled
								:value="formRfi.noRfi" />
							<InputGista type="text"
								size="large"
								label="Date"
								disabled
								:value="formRfi.date" />
							<InputGista type="text"
								size="large"
								label="Staff ITP"
								disabled
								:value="formRfi.staffItpName" />
							<InputGista type="text"
								size="large"
								label="Email"
								disabled
								:value="formRfi.staffItpEmail" />
							<ValidationProvider name="Phone"
								rules="required|maxDigit:15"
								v-slot="{ errors }">
								<InputSolutip type="text"
									label="Telepon"
									placeholder="02125563000"
									size="large"
									v-model="formRfi.phone"
									@keypress="validationPhone($event, formRfi.phone)"
									:error="!!errors[0]"
									mandatory>
									<template #message>
										<p>{{ errors[0] }}</p>
									</template>
								</InputSolutip>
							</ValidationProvider>
							<ValidationProvider name="Ext"
								rules="required|maxDigit:7"
								v-slot="{ errors }">
								<InputSolutip type="text"
									placeholder="1012345"
									label="Ext"
									size="large"
									v-model="formRfi.ext"
									@keypress="validationNumber"
									:error="!!errors[0]"
									mandatory>
									<template #message>
										<p>{{ errors[0] }}</p>
									</template>
								</InputSolutip>
							</ValidationProvider>
							<ValidationProvider name="Vendor Name"
								rules="required|max:100"
								v-slot="{ errors }">
								<InputSolutip type="text"
									placeholder="Vendor Name"
									label="Vendor Name"
									size="large"
									v-model="formRfi.vendorName"
									:error="!!errors[0]"
									mandatory>
									<template #message>
										<p>{{ errors[0] }}</p>
									</template>
								</InputSolutip>
							</ValidationProvider>
							<ValidationProvider name="Vendor Email"
								rules="required|email"
								v-slot="{ errors }">
								<InputGista type="text"
									placeholder="mail@example.com"
									size="large"
									label="Vendor Email"
									:error="!!errors[0]"
									mandatory
									v-model="formRfi.vendorEmail">
									<template #message>
										<p>{{ errors[0] }}</p>
									</template>
								</InputGista>
							</ValidationProvider>
						</div>
						<div class="w-full mb-6">
							<div class="mb-4 text-sm font-medium text-gray">
								Documents Language
							</div>
							<div class="flex">
								<RadioGista :modelValue="formRfi.isEnglish"
									:nativeValue="false"
									@change="changeLanguage"
									class="mr-3 text-gray">BAHASA INDONESIA</RadioGista>
								<RadioGista :modelValue="formRfi.isEnglish"
									:nativeValue="true"
									@change="changeLanguage"
									class="text-gray">ENGLISH</RadioGista>
							</div>
						</div>
						<div class="flex flex-wrap w-full mb-4">
							<GistaCheckbox @change="changeCheckBox($event, 'isSendItp01')"
								:modelValue="formRfi.isSendItp01"
								class="mr-3 text-gray">ITP 01</GistaCheckbox>
							<GistaCheckbox @change="changeCheckBox($event, 'isSendItp02')"
								:modelValue="formRfi.isSendItp02"
								class="mr-3 text-gray">ITP 02</GistaCheckbox>
							<GistaCheckbox @change="changeCheckBox($event, 'isSendItp03')"
								:modelValue="formRfi.isSendItp03"
								class="mr-3 text-gray">ITP 03</GistaCheckbox>
							<GistaCheckbox @change="changeCheckBox($event, 'isSendChecklist')"
								:modelValue="formRfi.isSendChecklist"
								class="mr-3 text-gray">CHECKLIST DOCUMENT</GistaCheckbox>
							<GistaCheckbox @change="changeCheckBox($event, 'isSendPendaftaran')"
								:modelValue="formRfi.isSendPendaftaran"
								class="mr-3 text-gray">PENDAFTARAN PIHAK EKSTERNAL</GistaCheckbox>
							<GistaCheckbox @change="changeCheckBox($event, 'isSendPernyataan')"
								:modelValue="formRfi.isSendPernyataan"
								class="mr-3 text-gray">PERNYATAAN KEPATUHAN REKANAN BCA</GistaCheckbox>
							<GistaCheckbox @change="changeCheckBox($event, 'isSendDueDilligence')"
								:modelValue="formRfi.isSendDueDilligence"
								class="mr-3 text-gray">DUE DILIGENCE</GistaCheckbox>
						</div>
						<div class="w-full mb-4">
							<ValidationProvider name="Notes"
								rules="max:300"
								v-slot="{ errors }">
								<TextareaGista label="Notes (optional)"
									v-model="formRfi.notes"
									:error="!!errors[0]">
									<template #message>
										<p>{{ errors[0] }}</p>
									</template>
								</TextareaGista>
							</ValidationProvider>
						</div>
						<div class="flex justify-end">
							<ButtonGista type="primary"
								color="secondary"
								customClass="w-full md:w-60"
								@click="handleSubmit(clickSendRfi)">
								Send RFI</ButtonGista>
						</div>
					</div>
				</div>
			</ValidationObserver>
		</GistaModal>
	</div>
</template>
<script>
import InputSolutip from '@/core/components/custom/Input'
import { MESSAGE_SUCCESS_SEND_RFI } from '@/core/constant/successMessage'
import { formatDate } from '@/core/components/helpers/format.js'

export default {
	name: "CreateRfi",
	data() {
		return {
			isOpen: false,
			timeout: null,
			disabledVendorEmail: true,
		}
	},
	computed: {
		formRfi: {
			get() {
				return this.$store.state.rfi.formRfi;
			},
			set(value) {
				this.$store.commit('rfi/SET_FORM_RFI', value)
			},
		},
		modal: {
			get() {
				return this.$store.state.modal.modal
			},
			set(value) {
				this.$store.commit('modal/SET_MODAL', value)
			}
		},
		vendor() {
			return this.$store.state.rfi.vendor
		},
		userProfile() {
			return this.$store.state.dashboard.userProfile
		}
	},
	methods: {
		formatDate,
		toggle() {
			this.isOpen = !this.isOpen
		},
		closeModal() {
			this.isOpen = !this.isOpen
			this.formRfi = {
				noRfi: "",
				date: this.formatDate(new Date()),
				vendorId: "",
				vendorName: "",
				vendorEmail: "",
				isNewVendor: false,
				staffItpId: this.userProfile.username,
				staffItpName: this.userProfile.name,
				staffItpEmail: this.userProfile.email,
				phone: "",
				ext: "",
				isSendItp01: true,
				isSendItp02: true,
				isSendItp03: true,
				isSendChecklist: true,
				isSendPendaftaran: true,
				isSendPernyataan: true,
				isSendDueDilligence: true,
				isEnglish: false,
				notes: "",
				reference: "",
				noRfp: "",
				projectName: ""
			}
		},
		validationPhone(e, phone) {
			let keyCode = e.keyCode;
			if (keyCode < 48 || keyCode > 57) {
				e.preventDefault();
			}
			if (phone.length === 0 && keyCode !== 48) {
				e.preventDefault();
			}
		},
		validationNumber(e) {
			let keyCode = e.keyCode;
			if (keyCode < 48 || keyCode > 57) {
				e.preventDefault();
			}
		},
		changeLanguage() {
			this.formRfi.isEnglish = !this.formRfi.isEnglish
		},
		changeCheckBox(e, key) {
			this.formRfi[key] = e
		},
		async clickSendRfi() {
			try {
				//show loading
				this.modal.modalLoading = true;
				const response = await this.$store.dispatch('rfi/postSendRfi')
				if (response?.status < 300 && response?.status >= 200) {
					//loading out
					this.modal.modalLoading = false;
					this.closeModal()
					this.modal.modalSuccessSaved = { value: true, title: MESSAGE_SUCCESS_SEND_RFI }
				} else {
					this.$store.dispatch('modal/throwError', { response })
				}
			} catch (error) {
				//loading out when error
				this.modal.modalLoading = false;
				//show error send rfi
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},

	},
	mounted() {
		this.formRfi.date = this.formatDate(new Date())
		this.formRfi.staffItpId = this.userProfile.username
		this.formRfi.staffItpEmail = this.userProfile.email
		this.formRfi.staffItpName = this.userProfile.name
	},
	components: {
		InputSolutip
	}
}
</script>