<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex justify-between my-6">
        <div>
          <span class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"
            @click="clickBackToVendorDetail"></span>
        </div>
        <div>
            <h4 class="text-lg font-medium text-gray-darkest">Kirim Revisi Penawaran Harga - {{ formReviseOffer.negotiationStatus }}</h4>
        </div>
        <div></div>
      </div>
      <ValidationObserver ref="formReviseOffer">
        <div>
          <div class="flex items-center mb-6">
            <span class="mr-4 icon-list"></span>
            <h5 class="text-lg font-medium text-gray-darkest">Detail BAN</h5>
          </div>
          <div class="grid gap-x-6 gap-y-3.5 mb-3.5"
            :class="{
              'grid-cols-1': quotationDetailContainerWidth < 768,
              'grid-cols-2': quotationDetailContainerWidth >= 768
            }">
            <InputDisabled 
              label="Project ID" 
              :value="formReviseOffer.projectId"
            />
            <InputDisabled 
              label="Request No."
              :value="formReviseOffer.requestNo"
            />
            <InputDisabled 
              label="Vendor Name"
              :value="formReviseOffer.vendorName"
            />
            <InputDisabled 
              label="Project Name"
              :value="formReviseOffer.projectName"
            />
            <InputDisabled 
              label="Request By"
              :value="formReviseOffer.requestByName?.toLowerCase()"
              class="capitalize"
            />
            <InputDisabled 
              label="Created By"
              :value="formReviseOffer.createdByName?.toLowerCase()"
              class="capitalize"
            />
            <InputDisabled 
              v-if="formReviseOffer.isUnitGsit === true"
              label="PIC GSIT"
              :value="formReviseOffer.picGsit?.toLowerCase()"
              class="capitalize"
            />
            <InputDisabled 
              v-if="formReviseOffer.isUnitGsit !==true"
              label="PIC UKKP"
              :value="formReviseOffer.picUkkp?.toLowerCase()"
              class="capitalize"
            />
            <InputDisabled 
              label="Offer No."
              :value="formReviseOffer.offerNo"
            />
            <InputDisabled 
              label="Offer Date."
              :value="formReviseOffer.offerDate"
            />
            <InputDisabled 
              label="Date Created"
              :value="formReviseOffer.createdDate ?? '(auto-generate)'"
            />
          </div>
          <div class="mb-6">
            <div class="mb-1">
              <p class="text-sm font-medium" :class="error['Project Type']? 'text-error' : 'text-gray'">Project Type <span class="text-error">*</span></p>
            </div>
            <div class="flex flex-wrap">
              <gista-checkbox @change="changeProjectType($event, 'HARDWARE')" :modelValue="formReviseOffer.projectType?.includes('HARDWARE')" class="mr-4 text-sm font-medium text-gray">Hardware</gista-checkbox>
              <gista-checkbox @change="changeProjectType($event, 'SOFTWARE')" :modelValue="formReviseOffer.projectType?.includes('SOFTWARE')"  class="mr-4 text-sm font-medium text-gray">Software</gista-checkbox>
              <gista-checkbox @change="changeProjectType($event, 'SERVICE')" :modelValue="formReviseOffer.projectType?.includes('SERVICE')"  class="mr-4 text-sm font-medium text-gray">Service</gista-checkbox>
              <gista-checkbox @change="changeProjectType($event, 'MAINTENANCE')" :modelValue="formReviseOffer.projectType?.includes('MAINTENANCE')"  class="mr-4 text-sm font-medium text-gray">Maintenance</gista-checkbox>
              <gista-checkbox @change="changeProjectType($event, 'LINK_COMMUNICATION')" :modelValue="formReviseOffer.projectType?.includes('LINK_COMMUNICATION')"  class="text-sm font-medium text-gray">Link-Communication</gista-checkbox>
            </div>
            <div v-if="error['Project Type']">
              <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
            </div>
          </div>
          <div class="grid gap-x-6 gap-y-3.5 mb-3.5"
            :class="{
              'grid-cols-1': quotationDetailContainerWidth < 768,
              'grid-cols-2': quotationDetailContainerWidth >= 768
            }">
            <div>
              <div class="flex">
                <label class="block mb-1 text-sm font-medium" :class="error['Meeting']? 'text-error' : 'text-gray'">Meeting<span class="text-error">*</span></label>
              </div>
              <GroupRadioButton class="h-11"
                :options="['Online', 'Offline']"
                mandatory
                name="meeting"
                :selected="formReviseOffer.meeting"
                @change="changeMeeting($event)" />
              <div v-if="error['Meeting']">
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </div>
            </div>
            <div>
              <ValidationProvider name="Meeting Date"
                rules="required"
                v-slot="{ errors }">
                <div class="flex">
                  <label class="block mb-1 text-sm font-medium text-gray" :class="errors?.[0]? 'text-error' : 'text-gray'">Date<span class="text-error">*</span></label>
                </div>
                <Datepicker2
                  mode="date"
                  placeholder="dd/mm/yyyy"
                  size="large"
                  v-model="formReviseOffer.meetingDate"
                  :max-date="new Date()"
                  data-vv-as="Masa selesai"
                  :error="!!errors[0]"
                >
                  <template>
                    <p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
                  </template>
                </Datepicker2>
              </ValidationProvider>
            </div>
            <div>
              <SelectSolutip label="Time (Start)"
                size="large"
                placeholder="HH:MM"
                :options="timeStart"
                mandatory
                @change="changeTimeStart"
                :error="error['Time Start']">
                <template v-if="formReviseOffer.timeStart" #selected>
                  <p class="text-gray">{{formReviseOffer.timeStart}}</p>
                </template>
              </SelectSolutip>
              <div v-if="error['Time Start']">
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </div>
            </div>
            <div>
              <SelectSolutip label="Time (End)"
                size="large"
                placeholder="HH:MM"
                :options="timeEnd"
                mandatory
                @change="changeTimeEnd"
                :error="error['Time End']">
                <template v-if="formReviseOffer.timeEnd" #selected>
                  <p class="text-gray">{{formReviseOffer.timeEnd}}</p>
                </template>
              </SelectSolutip>
              <div v-if="error['Time End']">
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </div>
            </div>
            <ValidationProvider v-if="!meetingOnline"
              name="Location"
              :rules="`${!meetingOnline ? 'required' : ''}`"
              v-slot="{ errors }"
              >
              <InputGista 
                type="text"
                size="large"
                label="Location"
                v-model="formReviseOffer.location"
                @input="(e) => handleCheckLength(e, 'Location')"
                :mandatory="!meetingOnline"
                :error="!!errors?.[0] || !!errorMaxLength['Location']"
                >
                <template #message>
                  <p>{{errors?.[0] || errorMaxLength['Location']}}</p>
                </template>
              </InputGista>
            </ValidationProvider>
            <ValidationProvider 
              v-if="meetingOnline"
              name="Via Online"
              :rules="`${meetingOnline ? 'required' : ''}`"
              v-slot="{ errors }"
              >
              <InputGista 
                type="text"
                size="large"
                label="Via Online"
                v-model="formReviseOffer.viaOnline"
                @input="(e) => handleCheckLength(e, 'Via Online')"
                :error="!!errors?.[0] || !!errorMaxLength['Via Online']"
                :mandatory="meetingOnline"
                >
                <template #message>
                  <p>{{errors?.[0] || errorMaxLength['Via Online']}}</p>
                </template>
              </InputGista>
            </ValidationProvider>
            <div>
              <AutocompleteNewSolutip
                label="Negotiator 1"
                placeholder="(Search)"
                :options="negotiator"
                @updateInput="inputSearchNegotiator($event, 'searchNegotiator1')"
                @updateAutoComplete="changeNegotiator1($event)"
                @blur="blurNegotiator1"
                @focus="focusNegotiator('searchNegotiator1')"
                v-model="searchNegotiator1"
                textField="fullName"
                textField2="jobTitle"
                iconLeft="icon-search text-xl text-gray-light"
                :isLoading="isLoadingVerificator"
                mandatory
                :error="error['Negotiator 1']"
              >
              </AutocompleteNewSolutip>
              <div v-if="error['Negotiator 1']">
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </div>
            </div>
            <div>
              <AutocompleteNewSolutip
                label="Negotiator 2"
                placeholder="(Search)"
                :options="negotiator"
                @updateInput="inputSearchNegotiator($event, 'searchNegotiator2')"
                @updateAutoComplete="changeNegotiator2($event)"
                @blur="blurNegotiator2"
                @focus="focusNegotiator('searchNegotiator2')"
                v-model="searchNegotiator2"
                textField="fullName"
                textField2="jobTitle"
                iconLeft="icon-search text-xl text-gray-light"
                :isLoading="isLoadingVerificator"
              >
              </AutocompleteNewSolutip>
            </div>
          </div>
          <div class="grid gap-y-3.5 mb-6"
          :class="{
            'grid-cols-1': quotationDetailContainerWidth < 768,
            'grid-cols-2': quotationDetailContainerWidth >= 768
          }">  
            <div class="col-span-2">
              <AutocompleteNewSolutip
                label="Negotiation Participant"
                placeholder="(Search)"
                :options="negotiator"
                @updateInput="inputSearchNegotiatorParticipant($event)"
                @updateAutoComplete="changeNegotiationParticipant($event)"
                @blur="blurNegotiationParticipant"
                @focus="focusNegotiatorParticipant"
                v-model="searchNegotiationParticipant"
                textField="fullName"
                textField2="jobTitle"
                iconLeft="icon-search text-xl text-gray-light"
                :isLoading="isLoadingVerificator"
                :error="error['Negotiator Participant']"
                mandatory
              >
              </AutocompleteNewSolutip>
              <div v-if="error['Negotiator Participant']">
                <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
              </div>
            </div>
            <div class="flex flex-wrap col-span-2">
              <div v-for="(n,i) in formReviseOffer.negotiationParticipant" :key="i" class="border border-gray-lightest rounded-lg p-2 mr-3.5 mb-3.5 flex items-center">
                <div class="mr-4 text-sm font-medium capitalize text-gray">{{ n.fullName?.toLowerCase() }}</div>
                <span class="cursor-pointer icon-x-circle text-error" @click="clickRemoveNegotationParticipant(i)"></span>
              </div> 
    
    
    
            </div>
            <div class="w-full col-span-2 border border-dashed border-gray-lightest"></div>
            <div class="col-span-2">
              <ValidationProvider name="Term of Payment"
                rules="required"
                v-slot="{ errors }">
                <div class="flex items-center">
                  <label class="block mb-1 text-sm text-gray" :class="(errors?.[0] || errorMaxLength['Term of Payment']) ? 'text-error' : 'text-gray'">
                    Term of Payment <span class="text-error">*</span>
                  </label>
                </div>
                <div class="">
                  <Tiptap @char="(e) => characterCount(e, 'Term of Payment')" v-model="formReviseOffer.termOfPayment" :error="!!errors[0] || !!errorMaxLength['Term of Payment']">
                    <template #message>
                      <p>{{ errors[0] || errorMaxLength['Term of Payment'] }}</p>
                    </template>
                  </Tiptap>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-span-2">
              <ValidationProvider name="Negotiation Note"
                rules="required"
                v-slot="{ errors }">
                <div class="flex items-center">
                  <label class="block mb-1 text-sm text-gray" :class="(errors?.[0] || errorMaxLength['Negotiation Note/ BCA Request'])? 'text-error' : 'text-gray'">
                    Negotiation Note/ BCA Request <span class="text-error">*</span>
                  </label>
                </div>
                <div class="">
                  <Tiptap @char="(e) => characterCount(e, 'Negotiation Note/ BCA Request')" v-model="formReviseOffer.negotiationNote"  :error="!!errors[0] || !!errorMaxLength['Negotiation Note/ BCA Request']">
                    <template #message>
                      <p>{{ errors[0] || errorMaxLength['Negotiation Note/ BCA Request'] }}</p>
                    </template>
                  </Tiptap>
                </div>
              </ValidationProvider>
            </div>
            <div class="col-span-2">
              <div class="flex items-center">
                <label class="block mb-1 text-sm text-gray" :class="errorMaxLength['Note Internal BCA']? 'text-error' : 'text-gray'">
                  Note Internal BCA
                </label>
              </div>
              <div class="">
                <Tiptap @char="(e) => characterCount(e, 'Note Internal BCA')" v-model="formReviseOffer.internalNote" :error="!!errorMaxLength['Note Internal BCA']">
                  <template #message>
                    {{ errorMaxLength['Note Internal BCA'] }}
                  </template>
                </Tiptap>
                
              </div>
            </div>
            <div class="col-span-2">
              <div class="">
                <ValidationProvider
                  ref="proofOfNegotationMeeting"
                >
                  <p class="mb-1 text-sm text-gray" :class="(errorFileType.proofOfMeeting || error['Proof Of Meeting'])? 'text-error' : 'text-gray'">Proof of Negotiation Meeting <span class="text-error">*</span></p>
                  <div class="flex items-center justify-between w-full border rounded-lg"
                    :class="`${errorFileType.proofOfMeeting  || error['Proof Of Meeting'] ? 'border-error' : 'border-primary'}`">
                    <input type="file"
                      id="upload-file-proofOfNegotiationMeeting"
                      ref="fileProof"
                      hidden
                      @change="chooseFile($event, 'proofOfNegotiationMeeting')" />
                    <div class="flex items-center justify-between" style="width: calc(100% - 7rem);">
                      <span class="px-4 text-sm text-gray" :class="{'trucate': quotationDetailContainerWidth > 640}">{{ formReviseOffer?.proofOfMeetingFileName }}</span>
                      <span v-if="formReviseOffer?.proofOfMeetingFileName" class="pr-4 text-xl cursor-pointer icon-trash-2 text-error hover:text-error-dark" @click="clickRemoveFileProofOfMeeting"></span>
                    </div>
                    <label v-if="!loadingProofOfMeetingUpload" for="upload-file-proofOfNegotiationMeeting"
                      class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
                      Pilih File
                    </label>
                    <label v-else
                      class="flex items-center justify-center p-2 text-white rounded-r-lg cursor-pointer bg-primary hover:bg-primary-dark w-28 h-14">
                      <div class="w-6 h-6 ease-linear border-4 border-t-4 rounded-full loader border-primary"></div>
                    </label>
                  </div>
                  <div>
                    <p class="mt-1 text-xs text-tertiary"
                      :class="{ 'text-error': errorFileType.proofOfMeeting || error['Proof Of Meeting'] }">
                      Supported file: .pdf .jpg .jpeg .png, max file size: 10 MB
                    </p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="col-span-2" v-for="n,i in fileQuotation" :key="i">
              <div>
                <p class="mb-1 text-sm font-medium text-gray">File Quotation</p>
              </div>
              <div class="flex items-center justify-between border rounded-lg border-primary h-14">
                <div class="px-4 py-2">
                  <div class="text-base font-medium text-gray">{{ n.fileName }}</div>
                </div>
                <div class="flex items-center px-4">
                  <p class="text-xl cursor-pointer icon-eye text-primary hover:text-primary-darkest" @click="openFile(n.fileName, n.documentNo)"></p>
                </div>
              </div>
            </div>
            <div class="col-span-2" v-for="n,i in attachmentFiltered" :key="i+'a'">
              <div>
                <p class="mb-1 text-sm font-medium text-gray">Attachment #{{i+1}}</p>
              </div>
              <div class="flex items-center justify-between p-2 border rounded-lg border-primary h-14">
                <div class="text-base text-gray">{{ n.fileName }}</div>
                <span class="cursor-pointer icon-trash-2 text-error" @click="clickRemoveFileAttachment(n)"></span>
              </div>
            </div>
            <div class="col-span-2">
              <ValidationProvider
                ref="lampiranBan"
              >
                <div class="flex flex-wrap items-center">
                  <div class="flex" v-if="maxAttachment">
                    <span class="mr-2 icon-file-plus text-secondary"></span>
                    <input type="file"
                        id="upload-file-attachment"
                        ref="fileAttachment"
                        hidden
                        @change="addAttachment($event, 'lampiranBan')" />
                    <label for="upload-file-attachment"
                      class="mr-4 text-base font-medium underline cursor-pointer text-secondary">
                      Add Attachment
                    </label>
                  </div>
                  <p class="mr-3.5" :class="{ 'text-error': errorFileType.attachment, 'text-gray': !errorFileType.attachment }">Max Attachment: 10 </p>
                  <p :class="{ 'text-error': errorFileType.attachment, 'text-gray': !errorFileType.attachment }">Supported file: .pdf, max file size: 10 MB</p>
                </div>
              </ValidationProvider>
            </div>
            <div  class="w-full col-span-2 grid gap-x-6 gap-y-3.5 mb-3.5"
            :class="{
              'grid-cols-1': quotationDetailContainerWidth < 768,
              'grid-cols-2': quotationDetailContainerWidth >= 768
            }">
              <div class="w-full">
                <div class="mb-1">
                  <p class="text-sm font-medium text-gray">Cost Type</p>
                </div>
                <div class="flex h-12">
                  <radio-gista disabled :modelValue="negotiationOfferItems.costType === 'FIX_COST'? 'FIX_COST': ''" nativeValue="FIX_COST" @change="changeInputCostType" class="pr-4 text-gray">Fixed</radio-gista>
                  <radio-gista disabled :modelValue="negotiationOfferItems.costType === 'RATE_COST'? 'RATE_COST': ''" nativeValue="RATE_COST" @change="changeInputCostType" class="pr-4 text-gray">Rate</radio-gista>
                  <radio-gista disabled :modelValue="negotiationOfferItems.costType === 'MIX_COST'? 'MIX_COST': ''" nativeValue="MIX_COST" @change="changeInputCostType" class="text-gray">Mixed</radio-gista>
                </div>
              </div>
              <div class="w-full">
                <div class="mb-1">
                  <p class="text-sm font-medium text-gray">VAT</p>
                </div>
                <div>
                  <InputDisabled 
                    :value="taxText"
                  />
                  
                </div>
              </div>
    
            </div>
          </div>
          <div class="mb-6">
            <div class="flex flex-wrap justify-between gap-3 mb-6">
              <div class="flex items-center">
                <span class="mr-4 icon-list"></span>
                <h5 class="text-lg font-medium text-gray-darkest">Negotiation Detail</h5>
              </div>
              <button class="flex items-center" :class="`${disableMaximizeTable? 'cursor-not-allowed': 'cursor-pointer hover:text-gray-dark active:text-gray-darkest'} ${quotationDetailContainerWidth < 640 ? '' : 'pl-4'}`"
                  @click="clickToggleMaximizeTable"
                  :disabled="disableMaximizeTable"
                  >
                <span class="mr-3 text-lg icon-maximize text-gray"></span>
                <p class="text-sm font-semibold text-gray" >Maximize table</p>
              </button>
            </div>
            
            <MaximizeTable :negotiationId="negotiationDetail.offers[negotiationDetail.offers.length -1].negotiationId" v-if="menuRfqNegotiationOfferGet?.canRead && isOpenMaximizeTable" :isOpen="isOpenMaximizeTable" @clickToggle="clickToggleMaximizeTable"/>
            <NegotiationTableOffer v-if="menuRfqNegotiationOfferGet?.canRead"/>
          </div>
         
          <div>
            <div class="flex justify-between gap-6" :class="quotationDetailContainerWidth < 640? 'flex-col': 'flex-row'">
              <BanSaveAsDraft
                :isOpenBanSaveAsDraft="isOpenBanSaveAsDraft" 
                :disabled="loadingProofOfMeetingUpload"
                @clickToggle="changeIsOpenSaveAsDraft($event)"
                @click="clickSaveAsDraft"
              />
              <SendEmailReviseOfferRfq 
                :isOpenModalSendEmail="isOpenModalSendEmail"
                :disabled="loadingProofOfMeetingUpload"
                @clickToggle="toggleClickSendEmail($event)"
                @click="clickSendEmail" 
              />
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
    <BanLoading v-else/>
  </div>
</template>

<script>
import GroupRadioButton from "@/core/components/custom/GroupRadioButton.vue";
import SendEmailReviseOfferRfq from "@/core/components/modal/negotiation/SendEmailReviseOfferRfq.vue";
import MaximizeTable from "@/core/components/rfq/negotiation/MaximizeTable.vue";
import Tiptap from '@/core/components/custom/Tiptap.vue'
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import SelectSolutip from "@/core/components/custom/Select.vue";
import NegotiationTableOffer from "@/core/components/rfq/negotiation/NegotiationTableOffer.vue";
import BanSaveAsDraft from "@/core/components/modal/negotiation/BanSaveAsDraft.vue";
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import Datepicker2 from '@/core/components/custom/DatePicker2.vue'
import { MESSAGE_EMPTY_FIELD, MESSAGE_POPUP_VALIDATION_SUBMIT } from '@/core/constant/errorMessage.js'
import BanLoading from '@/core/components/rfq/loading/BanLoading.vue'
import { PROJECT_TYPE_RFQ } from "@/core/constant/negotiation";
export default {
  name: 'ReviseOffer',
  data() {
    return {
      isOpenModalSendEmail: false,
      isOpenBanSaveAsDraft: false,
      time: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
      timeStart: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
      timeEnd: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
      searchNegotiator1: '',
      searchNegotiator2: '',
      searchNegotationParticipant: '',
      isLoading: false,
      attachments: [''],
      searchNegotiationParticipant: '',
      isLoadingVerificator: false,
      loadingProofOfMeetingUpload: false,
      onlineText: 'Online',
      offlineText: 'Offline',
      MESSAGE_EMPTY_FIELD,
      isOpenMaximizeTable: false,
      errorMaxLength: {
        'Negotiation Note': '',
        'Term of Payment': '',
        'Note Internal BCA': '',
        'Via Online': '',
        'Location': ''
      },
      error: {
        "Project Type": false,
        "Meeting": false,
        "Time Start": false,
        "Time End": false,
        "Negotiator 1": false,
        "NegotiatorParticipant": false,
        "Proof Of Meeting": false,
      },
      errorFileType: {
        proofOfMeeting: false,
        attachment: false
      }
    }
  },
  computed: {
    formReviseOffer: {
      get() {
        return this.$store.state.negotiationRfq.formReviseOffer
      },
      set(val) {
        this.$store.commit('negotiationRfq/SET_NEGOTIATION_REVISE_OFFER_FORM', val)
      }
    },
    attachmentFiltered(){
        return this.formReviseOffer.attachments.filter(n =>(n.fileRef ==='lampiranBan' && !n.isDeleted))
      },
    negotiationDetail(){
      return this.$store.state.negotiationRfq.negotiationDetail
    },
    disableMaximizeTable() {
      return this.negotiationDetail?.offers?.length === 0
    },
    negotiationOfferItems() {
		 return this.$store.state.negotiationRfq.negotiationOfferItems
		},
    taxText(){
      return this.negotiationOfferItems.tax?.ppn === 0 ? this.negotiationOfferItems.tax?.taxType : `${this.negotiationOfferItems.tax?.taxType} - ${this.negotiationOfferItems.tax?.ppn}%`  
    },
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
    negotiator(){
      return this.$store.state.negotiationRfq.negotiator.filter(e => !this.filterForNegotiator.includes(e.userDomain))
    },
    filterForNegotiator() {
      let people = []
      if (this.searchNegotiator1) {
        people.push(this.formReviseOffer.userIdNegotiator1)
      }
      if (this.searchNegotiator2) {
        people.push(this.formReviseOffer.userIdNegotiator2)
      }
      const negotiationParticipantUserDomain = this.formReviseOffer.negotiationParticipant.map(e => e.userDomain)
      people = [ ...people, ...negotiationParticipantUserDomain ]
      return people
    },
    fileQuotation() {
      return this.formReviseOffer.attachments?.filter(i => i.fileRef === 'fileQuotation')
    },
    meetingOnline() {
      return this.formReviseOffer.meeting === this.onlineText
    },
    menuRfqNegotiationOfferGet() {
			return this.$store.getters['dashboard/menuRfqNegotiationOfferGet']
    },
    maxAttachment() {
      return this.attachmentFiltered.length < 10
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    masterTaxOptions() {
      return this.$store.state.negotiation.masterTax.map(e => {
        if (e.ppn === 0) {
          return { text: e.taxType, taxId: e.taxId }
        }
        return { text: `${e.taxType} - ${e.ppn}%`, taxId: e.taxId }
      })
    },
    projectLogRfq() {
      return this.$store.state.projectLog.projectLogRfq
    },
    selectedIdxOffer: {
      get() {
        return this.$store.state.negotiationRfq.selectedIdxOffer
      },
      set(value) {
        this.$store.commit('negotiationRfq/SET_SELECTED_IDX_OFFER', value)
      }
    },
  },
  methods: {
    checkErrorLength(){
      return Object.values(this.errorMaxLength).every(e => !e)
    },
    messageErrorLength(){
      const filterError = Object.keys(this.errorMaxLength).filter(e => this.errorMaxLength[e])
      return filterError.join(', ')
    },
    handleCheckLength(e, name) {
      if(name === 'Via Online' && e?.length > 176) this.errorMaxLength[name] = `Kolom ${name} Maksimal 176 Karakter`
      else if(name === 'Location' && e?.length > 128) this.errorMaxLength[name] = `Kolom ${name} Maksimal 128 Karakter`
      else this.errorMaxLength[name] = ''
    },
    characterCount(e, name) {
      if(e > 4500) this.errorMaxLength[name] = `Kolom ${name} Maksimal 4500 Karakter`
      else this.errorMaxLength[name] = ''
    },
    changeIsOpenSaveAsDraft(val) {
      this.isOpenBanSaveAsDraft = val
    },
    toggleClickSendEmail(val) {
      this.isOpenModalSendEmail = val
    },
    clickBackToVendorDetail() {
      this.$store.commit('negotiationRfq/SET_SHOW_REVISE_OFFER', false)
    },
    changeMeeting(e) {
      this.formReviseOffer.meeting = e
      this.error["Meeting"] = false
      if (this.meetingOnline) {
        this.handleCheckLength('', 'Location')
        this.formReviseOffer.location = ''
      } else {
        this.handleCheckLength('', 'Via Online')
        this.formReviseOffer.viaOnline = ''
      }
    },
    openFile(filename, documentNo) {
      this.$store.dispatch('file/viewAttachment', { filename, documentNo })
    },
    async chooseFile(e, fileRef) {
      const file = e.target?.files?.[0]
			const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf','image/jpeg','image/png'] })
      if (checkFileValidation) {
        if(!this.formReviseOffer.proofOfMeetingFilePath) this.errorFileType.proofOfMeeting = true
      } else {
        try {
          this.loadingProofOfMeetingUpload = true
          this.errorFileType.proofOfMeeting = false
          const encrypted = await this.$store.dispatch('file/hashMD5',file)
          const formData = new FormData()
					formData.append('file', file)
					formData.append('fileRef', fileRef)
					//checksum encrypted md5
					formData.append('checksum', encrypted)
          formData.append('projectType', PROJECT_TYPE_RFQ)
					const response = await this.$store.dispatch('file/uploadFile', formData)
          if (response?.status < 300 && response?.status >= 200) {
            if (this.formReviseOffer.proofOfMeetingFilePath && this.formReviseOffer.proofOfMeetingDocumentNo) {
              this.formReviseOffer.deletedProofOfNegotiationDocumentNoList = [ ...this.formReviseOffer.deletedProofOfNegotiationDocumentNoList, this.formReviseOffer.proofOfMeetingDocumentNo]
            }
            const data = response.data.result
            this.formReviseOffer.proofOfMeetingDocumentNo = data.documentNo
            this.formReviseOffer.proofOfMeetingFileName = data.fileName
            this.formReviseOffer.proofOfMeetingFilePath = data.filePath
            this.error["Proof Of Meeting"] = false
            this.loadingProofOfMeetingUpload = false
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }

        } catch (error) {
          this.loadingProofOfMeetingUpload = false
          this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
        }
        
      }
      if (this.$refs['fileProof']) {
				this.$refs['fileProof'].value = ''
			}
    },
    clickRemoveNegotationParticipant(i) {
      this.formReviseOffer.negotiationParticipant = this.formReviseOffer.negotiationParticipant.filter((_,idx) => idx !== i)
    },
    async clickRemoveFileProofOfMeeting() {
			try {
				this.modal.modalLoading = true
        if (this.formReviseOffer.proofOfMeetingDocumentNo) {
          this.formReviseOffer.deletedProofOfNegotiationDocumentNoList = [ ...this.formReviseOffer.deletedProofOfNegotiationDocumentNoList, this.formReviseOffer.proofOfMeetingDocumentNo ]
        } else {
          const response = await this.$store.dispatch('file/deleteFile', this.formReviseOffer.proofOfMeetingDocumentNo || this.formReviseOffer.proofOfMeetingFileName)
          if (response?.status >= 300) {
            this.$store.dispatch('modal/throwError', { response })
          }
        }
				this.modal.modalLoading = false
        this.error["Proof Of Meeting"] = false
        if (this.$refs['fileProof']) {
          this.$refs['fileProof'].value = ''
        }
        this.formReviseOffer.proofOfMeetingDocumentNo = null
        this.formReviseOffer.proofOfMeetingFileName = null
        this.formReviseOffer.proofOfMeetingFilePath = null
			} catch (error) {
				this.modal.modalLoading = false
				this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
			}
		},
    async clickRemoveFileAttachment(file){
      try {
        const {fileName, documentNo} = file
        this.modal.modalLoading = true
				const fileAttachment = this.formReviseOffer.attachments.find(e => e.filePath === file.filePath)
        if (fileAttachment?.attachmentId > 0 ) {
          this.formReviseOffer.attachments = this.formReviseOffer.attachments.map(e => (e.filePath === fileAttachment.filePath? ({ ...e, isDeleted: true }): e))
        } else {
          const response = await this.$store.dispatch('file/deleteFile', documentNo || fileName)
          if (response?.status < 300 && response?.status >= 200) {
            this.formReviseOffer.attachments = this.formReviseOffer.attachments.filter(e => e.filePath !== file.filePath)
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }
        }
        this.modal.modalLoading = false
      } catch (error) {
        this.modal.modalLoading = false
        this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
    },
    async addAttachment(e, fileRef) {
      try {
        const file = e.target?.files[0]
			  const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
        if (checkFileValidation) {
          this.errorFileType.attachment = true
        } else {
          this.modal.modalLoading = true
          this.errorFileType.attachment = false
          const encrypted = await this.$store.dispatch('file/hashMD5',file)
          const formData = new FormData()
          formData.append('file', file)
          formData.append('fileRef', fileRef)
          //checksum encrypted md5
          formData.append('checksum', encrypted)
          formData.append('projectType', PROJECT_TYPE_RFQ)
          const response = await this.$store.dispatch('file/uploadFile', formData)

          if (response?.status < 300 && response?.status >= 200) {
            const data = response.data.result
            const fileAfterUpload = {
              fileName: data.fileName,
              filePath: data.filePath,
              documentNo: data.documentNo,
              fileRef: fileRef,
              isDeleted: false,
              attachmentId: 0,
            }
            this.formReviseOffer.attachments = [ ...this.formReviseOffer.attachments, fileAfterUpload ]
            this.modal.modalLoading = false
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }
          
        }
      } catch (error) {
        this.modal.modalLoading = false
        this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
      }
      if (this.$refs['fileAttachment']) {
        this.$refs['fileAttachment'].value = ''
      }
    },
    changeTimeStart(e) {
      this.formReviseOffer.timeStart = e
      this.error["Time Start"] = false
      const indexTime = this.time.indexOf(e)
      this.timeEnd = this.time.slice(indexTime+1)
    },
    changeTimeEnd(e) {
      this.formReviseOffer.timeEnd = e
      this.error["Time End"] = false
      const indexTime = this.time.indexOf(e)
      this.timeStart = this.time.slice(0, indexTime)
    },
    changeProjectType(e, type) {
      if (e) {
        this.formReviseOffer.projectType = [ ...this.formReviseOffer.projectType, type ]
      } else {
        this.formReviseOffer.projectType = this.formReviseOffer.projectType.filter(item => item !== type)
      }
      this.error["Project Type"] = false
    },
    async inputSearchNegotiator(e, stateName) {
      this.isLoadingVerificator = true
      this[stateName] = e
      await this.$store.dispatch('negotiationRfq/getNegotiator', { name: e, isNegotiationParticipant: false })
      this.isLoadingVerificator = false
    },
    async inputSearchNegotiatorParticipant(e) {
      this.isLoadingVerificator = true
      this.searchNegotiationParticipant = e
      await this.$store.dispatch('negotiationRfq/getNegotiator', { name: e, isNegotiationParticipant: true })
      this.isLoadingVerificator = false
    },
    changeNegotiator1(e) {
      if (this.formReviseOffer.userIdNegotiator2 !== e.userDomain && !this.formReviseOffer.negotiationParticipant.map(el => el.userDomain).includes(e.userDomain)) {
        this.searchNegotiator1 = e.fullName
        this.formReviseOffer.userIdNegotiator1 = e.userDomain
        this.formReviseOffer.nameNegotiator1 = e.fullName
      }
      this.error["Negotiator 1"] = false
    },
    blurNegotiator1() {
      if(this.searchNegotiator1 !== '') this.searchNegotiator1 = this.formReviseOffer.nameNegotiator1
      else {
        this.formReviseOffer.userIdNegotiator1 = ''
        this.formReviseOffer.nameNegotiator1 = ''
      }
    },
    async focusNegotiator(stateName) {
      this.isLoadingVerificator = true
      await this.$store.dispatch('negotiationRfq/getNegotiator', { name: this[stateName], isNegotiationParticipant: false })
      this.isLoadingVerificator = false
    },
    async focusNegotiatorParticipant() {
      this.isLoadingVerificator = true
      await this.$store.dispatch('negotiationRfq/getNegotiator', { name: this.searchNegotiationParticipant, isNegotiationParticipant: true })
      this.isLoadingVerificator = false
    },
    changeNegotiator2(e) {
      if (this.formReviseOffer.userIdNegotiator1 !== e.userDomain && !this.formReviseOffer.negotiationParticipant.map(el => el.userDomain).includes(e.userDomain)) {
        this.searchNegotiator2 = e.fullName
        this.formReviseOffer.userIdNegotiator2 = e.userDomain
        this.formReviseOffer.nameNegotiator2 = e.fullName
      }
    },
    blurNegotiator2() {
      if(this.searchNegotiator2 !== '') this.searchNegotiator2 = this.formReviseOffer.nameNegotiator2
      else {
        this.formReviseOffer.userIdNegotiator2 = ''
        this.formReviseOffer.nameNegotiator2 = ''
      }
    },
    changeNegotiationParticipant(e) {
      if (!this.formReviseOffer.negotiationParticipant.map(el => el.userDomain).includes(e.userDomain) && this.formReviseOffer.userIdNegotiator1 !== e.userDomain && this.formReviseOffer.userIdNegotiator2 !== e.userDomain) {
        this.formReviseOffer.negotiationParticipant = [ ...this.formReviseOffer.negotiationParticipant, e ]
      }
      this.error["Negotiator Participant"] = false

    },
    blurNegotiationParticipant() {
      this.searchNegotiationParticipant = ''
    },
    changeInputCostType(e) {
      this.formReviseOffer.costType = e
    },
    payloadReviseOffer() {
      return {
         
         rfqNegotiationId: this.formReviseOffer.rfqNegotiationId,
         summaryRfqNegotiationId: this.formReviseOffer.summaryRfqNegotiationId,
         projectId: this.formReviseOffer.projectId,
         requestNo: this.formReviseOffer.requestNo,
         vendorName: this.formReviseOffer.vendorName,
         projectName: this.formReviseOffer.projectName,
         budgetSpent: this.formReviseOffer.budgetSpent,
         requestById: this.formReviseOffer.requestById,
         requestByName: this.formReviseOffer.requestByName,
         offerNo: this.formReviseOffer.offerNo,
         offerDate: this.formReviseOffer.offerDate,
         picGsit: this.formReviseOffer.picGsit,
         picUkkp: this.formReviseOffer.picUkkp,
         createdByName: this.formReviseOffer.createdByName,
         projectType: this.formReviseOffer.projectType,
         meeting: this.formReviseOffer.meeting,
         meetingDate: this.formReviseOffer.meetingDate,
         timeStart: this.formReviseOffer.timeStart,
         timeEnd: this.formReviseOffer.timeEnd,
         location: this.formReviseOffer.location,
         viaOnline: this.formReviseOffer.viaOnline,

         userIdNegotiator1: this.formReviseOffer.userIdNegotiator1,
         nameNegotiator1:  this.formReviseOffer.nameNegotiator1,
         userIdNegotiator2: this.formReviseOffer.userIdNegotiator2,
         nameNegotiator2:  this.formReviseOffer.nameNegotiator2,

         negotiationParticipant: this.formReviseOffer.negotiationParticipant?.map(e =>e.userDomain),
         termOfPayment: this.formReviseOffer.termOfPayment,
         isWaiverBg: this.formReviseOffer.isWaiverBg,
         negotiationNote: this.formReviseOffer.negotiationNote,
         internalNote: this.formReviseOffer.internalNote,
         proofOfMeetingDocumentNo: this.formReviseOffer.proofOfMeetingDocumentNo,
         proofOfMeetingFileName: this.formReviseOffer.proofOfMeetingFileName,
         proofOfMeetingFilePath: this.formReviseOffer.proofOfMeetingFilePath,
         deletedProofOfNegotiationDocumentNoList: this.formReviseOffer.deletedProofOfNegotiationDocumentNoList,
         itpVerificatorId: this.formReviseOffer.itpVerificatorId,
         itpVerificatorName: this.formReviseOffer.itpVerificatorName,
         attachments: this.formReviseOffer.attachments,
       }
    },
    async clickSaveAsDraft(){
      try {
        this.modal.modalLoading = true
        const checkErrorLength = this.checkErrorLength()
        const MESSAGE_ERROR_VALIDATE = this.messageErrorLength()
        if (!checkErrorLength) {
          this.modal.modalLoading = false
          this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE }
          this.changeIsOpenSaveAsDraft(false)
          return
        }
        const dataPayload = this.payloadReviseOffer()
        const response = await this.$store.dispatch('negotiationRfq/postSaveAsDraftOfferRevision', dataPayload)
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
          this.changeIsOpenSaveAsDraft(false)
          setTimeout(()=>{
            this.$store.commit('negotiationRfq/SET_SHOW_REVISE_OFFER', false)
          },300)
          this.$store.dispatch('negotiationRfq/getNegotiationList',{rfqId:this.$route.params.quotationId})
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }
      } catch (error) {
        this.modal.modalLoading = false
        this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
      }
    },
    checkErrorValidate() {
      if (this.formReviseOffer.projectType.length === 0) {
        this.error["Project Type"] = true
      }
      if (!this.formReviseOffer.meeting) {
        this.error["Meeting"] = true
      }
      if (!this.formReviseOffer.timeStart) {
        this.error["Time Start"] = true
      }
      if (!this.formReviseOffer.timeEnd) {
        this.error["Time End"] = true
      }
      if (!this.formReviseOffer.userIdNegotiator1) {
        this.error["Negotiator 1"] = true
      }
      if (this.formReviseOffer.negotiationParticipant.length === 0) {
        this.error["Negotiator Participant"] = true
      }
      if (!this.formReviseOffer.proofOfMeetingFileName) {
        this.error["Proof Of Meeting"] = true
      }
      return Object.values(this.error).every(e => e === false)
    },
    messageErrorValidate() {
      const errorVeeValidate = this.$refs.formReviseOffer.errors
      const filterError = Object.keys(errorVeeValidate).filter(e => errorVeeValidate[e].length > 0)
      const filterErrorCustom = Object.keys(this.error).filter(e => this.error[e] === true)
      return [ ...filterErrorCustom, ...filterError ].join(', ')
    },
    async clickSendEmail(){
      try {
        this.modal.modalLoading = true
        const successValidate = await this.$refs.formReviseOffer.validate()
        const checkErrorValidate = this.checkErrorValidate()
        const MESSAGE_ERROR_VALIDATE = this.messageErrorValidate()
        const checkErrorLength = this.checkErrorLength()
        const MESSAGE_ERROR_LENGTH = this.messageErrorLength()
        if (!successValidate || !checkErrorValidate || !checkErrorLength) {
          this.modal.modalLoading = false
          const message = MESSAGE_ERROR_VALIDATE && MESSAGE_ERROR_LENGTH ? `, ${MESSAGE_ERROR_LENGTH}` : MESSAGE_ERROR_LENGTH
          this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + MESSAGE_ERROR_VALIDATE + message }
          this.toggleClickSendEmail(false)
          return
        }
        const dataPayload = this.payloadReviseOffer()
        let response
        if(this.selectedIdxOffer !== '') {
          response = await this.$store.dispatch('negotiationRfq/postSaveAsDraftOfferRevision', {...dataPayload, isEditData: true})
        } else response = await this.$store.dispatch('negotiationRfq/postNegotiationOfferRevision', dataPayload)
        if (response?.status < 300 && response?.status >= 200) {
          this.modal.modalLoading = false
          if(this.selectedIdxOffer === '') this.modal.modalSuccessSubmitted = {value:true, title:"Email Revisi Penawaran Harga telah dikirim"}
          this.isOpenModalSendEmail = false
          this.toggleClickSendEmail(false)
          this.$store.commit('projectLog/SET_RFQ_LOG_ID', this.projectLogRfq?.rfqLogs?.at(-1)?.rfqLogId)
          this.$emit("refetch")
          setTimeout(()=>{
            this.$store.commit('negotiationRfq/SET_SHOW_REVISE_OFFER', false)
          },300)
        } else {
          this.$store.dispatch('modal/throwError', { response })
        }

      } catch (error) {
        this.modal.modalLoading = false
        this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
      }
    },
    clickToggleMaximizeTable() {
      this.isOpenMaximizeTable = !this.isOpenMaximizeTable
    },


  },
  async mounted() {
    this.isLoading = true
    const selectedIdx = this.selectedIdxOffer !== '' ? this.selectedIdxOffer : this.negotiationDetail.offers.length - 1
    const r1 =  this.$store.dispatch('negotiationRfq/getNegotiationOfferRevision', {negotiationId:this.negotiationDetail.offers[selectedIdx].negotiationId})
    const r2 = this.$store.dispatch('negotiationRfq/getNegotiationOfferItems', {negotiationId:this.negotiationDetail.offers[selectedIdx].negotiationId})
    const [res1, res2] = await Promise.all([r1, r2])
    if ([res1?.status, res2?.status].every(e => e === 200)) {
      this.isLoading = false
      this.searchNegotiator1 = this.formReviseOffer?.nameNegotiator1
      this.searchNegotiator2 = this.formReviseOffer?.nameNegotiator2
      this.formReviseOffer.meeting = this.formReviseOffer.meeting || this.onlineText
    }
  },
  destroyed() {
    this.$store.commit('negotiationRfq/SET_SHOW_REVISE_OFFER', false)
    this.selectedIdxOffer = ''
  },
  components: {
    GroupRadioButton,
    SendEmailReviseOfferRfq,
    MaximizeTable,
    Tiptap,
    AutocompleteNewSolutip,
    SelectSolutip,
    NegotiationTableOffer,
    BanSaveAsDraft,
    InputDisabled,
    Datepicker2,
    BanLoading
  }
}
</script>