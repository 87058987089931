<template>
  <div>
    <ButtonGista 
      type="primary"
      color="error" 
      @click="changeIsOpen(true)" 
      :customClass="quotationDetailContainerWidth < 640? 'w-full' : 'w-max'"
      :disabled="disabled"
      >
      Continue Draft
    </ButtonGista>
    <ModalConfirm 
      title="Lanjutkan Draft?" 
      :message="`Draft terakhir: ${ negotiationDetail.lastBanFptnReviseOfferModifiedDate }`" 
      icon="saved" textButton="Continue Draft" 
      :isOpen="isOpenBanContinueDraft" 
      @changeIsOpen="changeIsOpen($event)" 
      @click="clickContinueDraft"
      colorButton="success">
    </ModalConfirm>
  </div>
</template>

<script>
import ModalConfirm from '@/core/components/modal/Confirm'

  export default {
    name: 'BanContinueDraft',
    props: ['isOpenBanContinueDraft', 'disabled'],

    computed: {
			negotiationDetail(){
				return this.$store.state.negotiationRfq.negotiationDetail
			},
      quotationDetailContainerWidth() {
				return this.$store.state.width.quotationDetailContainerWidth
			},
    },
    methods: {
      changeIsOpen(val) {
        this.$emit('clickToggle', val)
      },
      clickContinueDraft() {
        this.$emit('click')
        this.changeIsOpen(false)
      }
    },
    components: {
      ModalConfirm
    }

  }
</script>